import { useContext } from "react";
import {
  Label,
  List,
  Loader,
  Segment,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";
import { parseFHIRPreference } from "../../../utils/FHIRUtils";
import Labs from "./labs";
import Observations from "./observations";
import Consent from "./consent";
import AuthContext from "contexts/AuthContext";
import useSWR from "swr";
import FHIRFetcher from "data/FHIRFetcher";
import moment from "moment";
import Vitals from "./vitals";
import MaternityHealth from "./maternity";
import { get } from "lodash";

export const profileCodes = [
  {
    name: "Qualification Status",
    code: "QUALIFICATION-STATUS",
    icon: "star" as SemanticICONS,
    color: "green" as SemanticCOLORS,
  },
  {
    name: "Allergies",
    code: "ALLERGIES",
    icon: "bug" as SemanticICONS,
    color: "red" as SemanticCOLORS,
  },
  {
    name: "Food Avoidances",
    code: "FOOD-AVOIDANCE",
    icon: "food" as SemanticICONS,
    color: "red" as SemanticCOLORS,
  },
  {
    name: "Cuisine Avoidances",
    code: "CUISINE-AVOIDANCE",
    icon: "stop circle" as SemanticICONS,
    color: "purple" as SemanticCOLORS,
  },
  {
    name: "Food Preferences",
    code: "FOOD-PREFERENCES",
    icon: "like" as SemanticICONS,
    color: "pink" as SemanticCOLORS,
  },
  {
    name: "Delivery Preferences",
    code: "DELIVERY-PREFERENCES",
    icon: "truck" as SemanticICONS,
    color: "blue" as SemanticCOLORS,
  },
  {
    name: "Communication Preferences",
    code: "COMMUNICATION-PREFERENCES",
    icon: "american sign language interpreting" as SemanticICONS,
    color: "teal" as SemanticCOLORS,
  },
  {
    name: "Referral Type",
    code: "REFERRAL-TYPE",
    icon: "group" as SemanticICONS,
    color: "orange" as SemanticCOLORS,
  },
  {
    name: "Educational Material Preferences",
    code: "EDUCATIONAL-MATERIAL-PREFERENCES",
    icon: "book" as SemanticICONS,
    color: "blue" as SemanticCOLORS,
  },
  {
    name: "Other Preferences",
    code: "OTHER-PREFERENCES",
    icon: "setting" as SemanticICONS,
    color: "yellow" as SemanticCOLORS,
  },
  {
    name: "Resources",
    code: "RESOURCES",
    icon: "star" as SemanticICONS,
    color: "green" as SemanticCOLORS,
  },
  {
    name: "Diagnosis",
    code: "DIAGNOSIS",
    icon: "stethoscope" as SemanticICONS,
    color: "black" as SemanticCOLORS,
  },
  {
    name: "Medications",
    code: "MEDICATION-PREFERENCES",
    icon: "pills" as SemanticICONS,
    color: "purple" as SemanticCOLORS,
  },
];

export const labCodes = [
  {
    name: "Laboratory",
    code: "1742-6,1751-7,1920-8,1975-2,2028-9,2075-0,2085-9,2089-1,2093-3,2160-0,2345-7,2571-8,2823-3,2885-2,2951-2,3094-0,8462-4,8480-6,15074-8,17861-6,59261-8,BLOOD_GLUCOSE_PERCENT_TIME_IN_RANGE,BLOOD_SUGAR_READING,EGFR,GLUCOSE,UACR",
    icon: "flask" as SemanticICONS,
    color: "black" as SemanticCOLORS,
  },
];

export const vitalCodes = [
  {
    name: "Vitals",
    code: "8462-4,8480-6,29463-7,39156-5,55284-4,WEIGHT,8302-2", //Must not be space seperated, only comma
    icon: "user doctor" as SemanticICONS,
    color: "green" as SemanticCOLORS,
  },
];

export const maternityHealthCodes = [
  {
    name: "Maternity Health",
    code: "maternity-health,MATERNITY-HEALTH", //ingestion maternity health observations are in caps
    icon: "hourglass" as SemanticICONS,
    color: "pink" as SemanticCOLORS,
  },
];

export const consentCodes = [
  {
    name: "Consent",
    code: "CONSENT",
    icon: "clipboard check" as SemanticICONS,
    color: "black" as SemanticCOLORS,
  },
];

function Profile(props: any) {
  let authInfo: any = useContext(AuthContext);

  const activeObservations = (val: string) => {
    let obsData = getDataByCode(val);
    let filteredList: [] | undefined = obsData.filter((entry) =>
      val
        .toLowerCase()
        .split(",")
        .includes(get(entry, "resource.code.coding[0].code")?.toLowerCase())
    );
    return filteredList.length > 0 ? true : false;
  };

  const getDataByCode = (code: string) => {
    //Filters observation data to matching profile codes
    if (props.profileData?.entry) {
      let filteredData = props.profileData.entry.filter((entry: any) =>
        code
          .toLowerCase()
          .split(",")
          .includes(parseFHIRPreference(entry.resource).code.toLowerCase())
      );
      if (code === "EDUCATIONAL-MATERIAL-PREFERENCES") {
        // filter patient observations to remove dupes
        let newFilteredData = [];
        for (let i = 0; i < filteredData.length; i++) {
          let bool = true;
          for (let j = 0; j < newFilteredData.length; j++) {
            if (
              filteredData[i].resource.valueString.trim() ===
              newFilteredData[j].resource.valueString.trim()
            ) {
              bool = false;
              break;
            }
          }
          if (bool) newFilteredData.push(filteredData[i]);
        }
        filteredData = newFilteredData;
      }

      //only show last 3 survey responses for "FOOD-PREFERENCES" & "COMMUNICATION-PREFERENCES" & "REFERRAL-TYPE" sections in the sidebar, show last 5 survey responses in the other sections, leave the profile page the way it is
      if (props.sidebar) {
        let lastSetValueId = [];
        let lastDataResponse = [];
        let indexOfQuestionnaire = "";
        let currentQuestionnaireResponseID = "";
        let dataLimit;
        let lastDataEffectiveStartDate = "";
        if (code === "REFERRAL-TYPE") {
          filteredData = filteredData?.slice(0, 3);
        } else if (code === "QUALIFICATION-STATUS") {
          filteredData = filteredData?.slice(0, 5);
        } else {
          dataLimit =
            code === "FOOD-PREFERENCES" || code === "COMMUNICATION-PREFERENCES"
              ? 3
              : 5;
          for (let i = 0; i < filteredData?.length; i++) {
            //if the data is from a questionnaireResponse, we will group the data with the same questionnaireResponseId together
            if (filteredData[i]?.resource?.["derivedFrom"]?.length > 0) {
              indexOfQuestionnaire = "";
              currentQuestionnaireResponseID = "";
              indexOfQuestionnaire = filteredData[i]?.resource?.[
                "derivedFrom"
              ].findIndex((obj) => (obj.display = "Questionnaire"));
              currentQuestionnaireResponseID =
                filteredData[i]?.resource?.["derivedFrom"]?.[
                  indexOfQuestionnaire
                ]?.["reference"]?.split("/")?.[1];
              if (
                lastSetValueId.length === dataLimit &&
                !lastSetValueId.includes(currentQuestionnaireResponseID)
              ) {
                filteredData = lastDataResponse;
                break;
              }
              if (!lastSetValueId?.includes(currentQuestionnaireResponseID)) {
                lastSetValueId.push(currentQuestionnaireResponseID);
              }
              lastDataResponse.push(filteredData[i]);
            } else {
              //below code happen when the data is not from a questionnaireResponse
              //if the last element in lastSetValueId is Questionnaire-Response data, check if the lastSetValueId limit (3 or 5) has been reached
              //if so, we will break the function. otherwise, it will update lastDataEffectiveStartDate, add the NON-QUESTIONNAIRE+id and dataResponse data

              //if the last element in lastSetValueId is NON-QUESTIONNAIRE response data
              //if the last element is NON-QUESTIONNAIRE response data, get the lastDataEffectiveStartDate.
              //-we need to check the time diff if within 1 min, if time diff within 1min, we can treat as the same survey => add the response, if timedif not within 1min, we  need to check if datalimit has been reached(3 or 5), if reached the data limit, break, if do not reach data limit, we will treat as diff survey=> update lastDataEffectiveStartDate, add the NON-QUESTIONNAIRE+id and dataResponse data
              //if the last element is QUESTIONNAIRE response data.  we need to check if datalimit has been reached(3 or 5), if reached the data limit, break, if do not reach data limit, we will treat as diff survey=> update lastDataEffectiveStartDate, add the NON-QUESTIONNAIRE+id and dataResponse data
              if (
                !lastSetValueId[lastSetValueId.length - 1]?.startsWith(
                  "NOT-QUESTIONNAIRE"
                )
              ) {
                if (lastSetValueId?.length === dataLimit) {
                  break;
                } else {
                  lastDataEffectiveStartDate =
                    filteredData[i]?.resource?.effectivePeriod?.start;
                  lastSetValueId.push(
                    `NOT-QUESTIONNAIRE+${filteredData[i]?.resource?.id}`
                  );
                  lastDataResponse.push(filteredData[i]);
                }
              } else {
                let currentObservationTime = moment(
                  filteredData[i]?.resource?.effectivePeriod?.start
                );
                let timeDiff = moment(lastDataEffectiveStartDate).diff(
                  currentObservationTime,
                  "minutes"
                );
                if (timeDiff <= 1) {
                  lastDataEffectiveStartDate =
                    filteredData[i]?.resource?.effectivePeriod?.start;
                  lastDataResponse.push(filteredData[i]);
                } else {
                  if (lastSetValueId?.length === dataLimit) {
                    filteredData = lastDataResponse;
                    break;
                  } else {
                    lastDataEffectiveStartDate =
                      filteredData[i]?.resource?.effectivePeriod?.start;
                    lastSetValueId.push(
                      `NOT-QUESTIONNAIRE+${filteredData[i]?.resource?.id}`
                    );
                    lastDataResponse.push(filteredData[i]);
                  }
                }
              }
            }
          }
        }
      }
      return filteredData;
    } else {
      return [];
    }
  };

  const getConsentData = () => {
    let filteredData = [];
    if (props?.consentData?.entry) {
      filteredData = props?.consentData?.entry?.filter((entry: any) =>
        consentCodes[0].code
          .toLowerCase()
          .split(",")
          .includes(parseFHIRPreference(entry.resource).code.toLowerCase())
      );
      if (props.sidebar) {
        filteredData = filteredData?.slice(0, 5);
      }
      return filteredData;
    } else {
      return [];
    }
  };
  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  };
  const { data: consentData } = useSWR(
    [
      `/Consent/?patient=${props.patientId}&identifier=web-survey-proxy,Member App&_sort=-date`,
      authInfo.token,
    ],
    FHIRFetcher,
    options
  );

  return (
    <div
      style={{
        width: "100%",
        float: "left",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      {(!props.profileData || props.profileLoading || !consentData) && (
        <Loader active inline="centered" content="Loading Profile Data..." />
      )}
      {/* {consentData && (
        <List key="consent">
          <List.Item style={{ textAlign: "left" }}>
            <Segment>
              <List.Content style={{ display: "inline-block" }}>
                <h4>
                  <List.Icon
                    name={consentCodes[0].icon}
                    color={consentCodes[0].color}
                  />
                  {consentCodes[0].name}: &nbsp;
                </h4>
                <Label color={consentData?.entry?.[0] ? "teal" : "grey"} basic>
                  <div style={{ display: "flex" }}>
                    <div>
                      <div>
                        <b>
                          {consentData?.entry?.[0]
                            ? "Digitally T&C and PP accepted"
                            : "Digitally no T&C and PP accepted"}
                        </b>
                      </div>
                      <div>{consentData?.entry?.[0]?.resource?.dateTime}</div>
                    </div>
                  </div>
                </Label>
                <Consent
                  style={{ margin: "5px" }}
                  code={consentCodes[0].code}
                  patientId={props.patientId}
                  data={getConsentData()}
                  mutate={props.profileMutate}
                  sidebar={props.sidebar}
                  org={props.org}
                  consentDataMutate={props.consentDataMutate}
                />
              </List.Content>
            </Segment>
          </List.Item>
        </List>
      )} */}

      {props.profileData &&
        maternityHealthCodes.map((m) => activeObservations(m.code))[0] &&
        maternityHealthCodes.map((profile) => (
          <List key={profile.code}>
            <List.Item style={{ textAlign: "left" }}>
              <Segment>
                <List.Content style={{ display: "inline-block" }}>
                  <h4>
                    <List.Icon name={profile.icon} color={profile.color} />
                    {profile.name}: &nbsp;
                  </h4>
                  <MaternityHealth
                    style={{ margin: "5px" }}
                    maternityData={getDataByCode(profile.code)}
                    patientId={props.patientId}
                    sidebar={props.sidebar}
                    mutate={props.profileMutate}
                  />
                </List.Content>
              </Segment>
            </List.Item>
          </List>
        ))}

      {props.profileData &&
        profileCodes.map((profile) => (
          <List key={profile.code}>
            <List.Item style={{ textAlign: "left" }}>
              <Segment>
                <List.Content style={{ display: "inline-block" }}>
                  <h4>
                    <List.Icon name={profile.icon} color={profile.color} />
                    {profile.name}: &nbsp;
                  </h4>
                  <Observations
                    style={{ margin: "5px" }}
                    code={profile.code}
                    patientId={props.patientId}
                    data={getDataByCode(profile.code)}
                    mutate={props.profileMutate}
                    sidebar={props.sidebar}
                    org={props.org}
                  />
                </List.Content>
              </Segment>
            </List.Item>
          </List>
        ))}
      {props.profileData &&
        labCodes.map((profile) => (
          <List key={profile.code}>
            <List.Item style={{ textAlign: "left" }}>
              <Segment>
                <List.Content style={{ display: "inline-block" }}>
                  <h4>
                    <List.Icon name={profile.icon} color={profile.color} />
                    {profile.name}: &nbsp;
                  </h4>
                  <Labs
                    style={{ margin: "5px" }}
                    code={profile.code}
                    patientId={props.patientId}
                    org={props.org}
                    data={getDataByCode(profile.code)}
                    mutate={props.profileMutate}
                    sidebar={props.sidebar}
                    setActiveTab={props.setActiveTab}
                    updateQRID={props.updateQRID}
                  />
                </List.Content>
              </Segment>
            </List.Item>
          </List>
        ))}
      {props.profileData &&
        vitalCodes.map((profile) => (
          <List key={profile.code}>
            <List.Item style={{ textAlign: "left" }}>
              <Segment>
                <List.Content style={{ display: "inline-block" }}>
                  <h4>
                    <List.Icon name={profile.icon} color={profile.color} />
                    {profile.name}: &nbsp;
                  </h4>
                  <Vitals
                    style={{ margin: "5px" }}
                    vitalsData={getDataByCode(profile.code)}
                    patientId={props.patientId}
                    sidebar={props.sidebar}
                    mutate={props.profileMutate}
                  />
                </List.Content>
              </Segment>
            </List.Item>
          </List>
        ))}
    </div>
  );
}

export default Profile;
