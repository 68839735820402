import { Patient } from "fhir/r4";
import { Card, Image, Label, Popup } from "semantic-ui-react";
import * as FHIRUtils from "../../../utils/FHIRUtils"
import * as Helpers from "../../../utils/Helpers"
import { formatPhoneNumber } from "../../../utils/utils";
import MemberQuickInfo from "./quickinfo";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";

function MemberCard(props: any) {

    let authInfo: any = useContext(AuthContext);

    let fhirPatient: Patient = props.patient
    let flatPatient = FHIRUtils.parseFHIRPatient(fhirPatient)
    let imageName = "neutral.png"
    if (flatPatient.gender) {
        imageName = Helpers.getImage(flatPatient.gender === "male")
    }

    return (
        <Popup flowing hoverable
            trigger={
                <Card link={true} onClick={() => props.onPatientSelect(fhirPatient)}  fluid stretched color="orange">
                    <Card.Content>
                        <Image floated='left' size='mini' src={'/images/' + imageName} />
                        <Card.Header >{flatPatient.firstName} {flatPatient.lastName}</Card.Header>
                        <Card.Meta>
                            {flatPatient.mobilePhone[0] && flatPatient.mobilePhone[0] !== "" &&
                                <>
                                    {formatPhoneNumber(flatPatient.mobilePhone)}
                                </>
                            }
                            {(!flatPatient.mobilePhone[0] || flatPatient.mobilePhone[0] === "") && flatPatient.homePhone[0] && flatPatient.homePhone[0] !== "" &&
                                <>
                                    {formatPhoneNumber(flatPatient.homePhone)}</>}
                            <>.&nbsp;&nbsp;{flatPatient.email}</>

                        </Card.Meta>
                        <Card.Description>
                            <Label>{`${flatPatient.genderPrefix}  ${flatPatient.age}`}</Label>
                            <Label>{flatPatient.dob}</Label>
                            <Label>{flatPatient.memberId}</Label>
                            {/* <Label>{flatPatient.city},&nbsp;{flatPatient.state}</Label> */}
                        </Card.Description>
                    </Card.Content>
                </Card>
            }
        >
            <Popup.Content>
                <MemberQuickInfo patient={fhirPatient} />
            </Popup.Content>
        </Popup>
    )
};

export default MemberCard;
