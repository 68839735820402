import _ from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { Checkbox, Icon, Loader, Modal, Popup, Table, Comment } from "semantic-ui-react";
import useSWR from "swr";
import AuthContext from "../../../../contexts/AuthContext";
import { statusColors } from "./constants";
import * as Helpers from "../../../../utils/Helpers"

function OrdersTable(props: any){
    
    const [showFeedback, setShowFeedback] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [ordersFeedback, setOrdersFeedback] = useState({});
    
    return (            
        <div className="table-div">
            <Table sortable celled singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={props.column === 'orderDate' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'orderDate' })}>
                            Ordered
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width={1}
                            sorted={props.column === 'orderName' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'orderName' })}>
                            Order Detail
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width={1}
                            sorted={props.column === 'flavorName' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'flavorName' })}>
                            Flavor
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={props.column === 'status' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'status' })}>
                            Status
                        </Table.HeaderCell>
                    
                        <Table.HeaderCell
                            sorted={props.column === 'estimatedDeliveryDate' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'estimatedDeliveryDate' })}
                            >
                            Expected By
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={props.column === 'carrier' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'carrier' })}>
                            Carrier
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={props.column === 'trackingNumber' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'trackingNumber' })}>
                            Tracking #
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={props.column === 'deliveryDate' ? props.direction : null}
                            onClick={() => props.dispatch({ type: 'CHANGE_SORT', sortcolumn: 'deliveryDate' })}>
                            Delivery Date
                        </Table.HeaderCell>
                        {/* {props.patientId && <Table.HeaderCell style={{width:"55px", "marginRight":"10px"}}></Table.HeaderCell> } */}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {props.data.map(order => {
                    let orderStatus = order.status && order.status.toLowerCase()
                    let quantity = order.orderItems && order.orderItems.length>0 && _.sum(order.orderItems.map(od => {return parseInt(od.quantity)}))
                    let trackingLink =order.deliveryInfo && order.deliveryInfo.trackingCode && order.deliveryInfo.carrier && (order.deliveryInfo.carrier.toLowerCase()==='fedex'? "https://www.fedex.com/apps/fedextrack/?tracknumbers=" + order.deliveryInfo.trackingCode:(order.deliveryInfo.carrier.toLowerCase()==='ups'? "https://www.ups.com/track?tracknum="+order.deliveryInfo.trackingCode:"https://google.com/search?q=" + order.deliveryInfo.trackingCode + '+' + order.deliveryInfo.carrier))
                    let colorCode = statusColors[orderStatus]
                    let itemsContent = order.orderItems && order.orderItems.length>0 && <Table celled compact color="purple">
                        <Table.Header>
                            <Table.Row textAlign="center">
                                <Table.HeaderCell>Food Items</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body >
                        {order.orderItems.map((item, ind) => {return (
                            <Table.Row key={ind}> 
                                <Table.Cell>
                                {item.name}
                                </Table.Cell>
                            </Table.Row>)
                        })}
                        </Table.Body>
                    </Table>
                    let notesContent = order.orderNotes && 
                    <Comment.Group>
                        {order.orderNotes.map(on => {
                            return (
                            <Comment>
                                <Comment.Avatar src={'../../../../images/' + Helpers.getImage(true)}/>
                                <Comment.Content>
                                    <Comment.Author as='a'>{on.author}</Comment.Author>
                                    <Comment.Metadata>{moment.utc(on.time).local().fromNow()}</Comment.Metadata>
                                    <Comment.Text>{on.text}</Comment.Text>
                                </Comment.Content>
                            </Comment>
                            )
                        })}
                    </Comment.Group>
                    let orderName = <div style={{display: 'flex', justifyContent:'space-between'}}>
                        <div>{order.foodTypeName}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                        <div>{itemsContent && <Popup 
                        wide
                        on='click'
                        position='right center'
                        closeOnEscape
                        closeOnDocumentClick
                        content={itemsContent} 
                        trigger={<Icon link name='clipboard list' color="purple"/>}/>}</div></div>
                    return (
                        <Table.Row positive={colorCode==='yy'} warning={colorCode==='warning'} negative={colorCode==='negative'} error={colorCode==='error'} style={{backgroundColor: (colorCode==='purple' && '#F0F8FF') || (colorCode==='purple' && '#ffefd5'), color: colorCode==='zz' && '#808080'}}>
                             <Table.Cell>{order.orderDate ? moment.utc(order.orderDate).local().fromNow() : ''}</Table.Cell>
                            {/* <Table.Cell>{props.patientId && props.vendor==='All'? order.vendorId:<a onClick={!props.patientId? (e)=> {e.preventDefault();props.openSelectedPatient(order.patientId)}:null} style={{cursor:'pointer'}}>{props.patients && props.patients[order.patientId]? props.patients[order.patientId].firstName+' '+props.patients[order.patientId].lastName:<Loader size="tiny" active inline/>}</a>}</Table.Cell> */}
                            <Table.Cell>{orderName}</Table.Cell>
                            <Table.Cell>{order.flavorName}</Table.Cell>
                            <Table.Cell>{order.status ? order.status.toString().toUpperCase() : ''}</Table.Cell>
                            <Table.Cell>{order.estimatedDeliveryDate ? order.estimatedDeliveryDate: ''}</Table.Cell>
                            <Table.Cell>{order.deliveryInfo? order.deliveryInfo.carrier:''}</Table.Cell>
                            <Table.Cell>{order.deliveryInfo? <a href={trackingLink} target="_blank">{order.deliveryInfo.trackingCode}</a>:''}</Table.Cell>
                            <Table.Cell>{order.deliveryDate? moment.utc(order.deliveryDate).local().format('YYYY-MM-DD'): ''}</Table.Cell>
                              
                        </Table.Row>
                    );
                })}
        </Table.Body>
    </Table>    
</div>)
}

export default OrdersTable;
