import { FhirResource, Provenance, PlanDefinition } from "fhir/r4";
import FHIRProxyClient from "../common/fhirproxy-client";
import * as FHIRUtils from "../common/fhirutils";
import * as CarePlanBuilder from "../common/fhir-builders/careplan";
import * as ProvenanceBuilder from "../common/fhir-builders/provenance";
import * as fhirpath from "fhirpath";

//Get Intervention Subactions from PlanDef : eg. specific actions like "be-well-monthly-premium-mixed-bundle under "monthly-meal-bundle-options"
//this will be used by UI to display options for RD so they can chose one on-demand
//and the selcted option is used to create tasks for the selected intervention sub action
export function getInterventionSubActions(
  planDefinition: PlanDefinition,
  plannedInterventionActionId //eg. "monthly-meal-bundle-options"
) {
  return fhirpath.evaluate(
    planDefinition,
    `action.where(id='intervention').action.where(id='${plannedInterventionActionId}').action`
  );
  //return value eg. array of nodes with these ids:
  //  "be-well-nutrition-consult","kick-starter-pantry", "be-well-monthly-groceries" "be-well-monthly-premium-mixed-bundle","be-well-monthly-premium-meals-bundle"
}

//this occurs after an sub action of a planned intervention is selected
//eg. selecting "be-well-monthly-premium-mixed-bundle action under planned intervention "nutrition-bundles"
//can be triggered on-demand multiple times
export async function scheduleIntervention(
  orgId: string,
  selectedInterventionSubAction: any, //action node associated to selected sub action under planned Intervention. eg. "be-well-monthly-premium-mixed-bundle"
  patientId: string,
  carePlanId: string,
  careTeamId: string,
  plannedIterventionTaskId: string, //task associated to Intervention activity of PlanDef
  startDate: string,
  actorRef: { reference: string; display: string },
  updatedBy: string,
  fhirSender: FHIRProxyClient,
  referralId?: string,
) {
  try {
    let fhirBundle: FhirResource = FHIRUtils.buildEmptyBundle("transaction");
    let bundleEntries: any[] = [];
    //build sub-tasks for diffferent sub actions under planned Interevention and associate them to parent intervention Task
    let tasksBundle = CarePlanBuilder.buildSubTasks(
      patientId,
      orgId,
      carePlanId,
      careTeamId,
      plannedIterventionTaskId,
      selectedInterventionSubAction,
      startDate,
      updatedBy,
      referralId
    );
    //add these to the intervention bundle
    bundleEntries = tasksBundle.entry!;

    //create Provenance
    let provenancce: Provenance = ProvenanceBuilder.build(
      `CarePlan/${carePlanId}`,
      actorRef,
      patientId,
      "UPDATE",
      `Intervention/Scheduled-SubAction-${selectedInterventionSubAction.id}`,
      { code: orgId, display: orgId }
    );
    bundleEntries.push(FHIRUtils.buildResourceEntry(provenancce, false)); //Provenance

    //now post bundle request
    fhirBundle.entry = bundleEntries;
    //console.log(JSON.stringify(fhirBundle))
    let response = await fhirSender.send("post", "", fhirBundle);

    return response;
  } catch (error) {
    console.log("Error in assess:interveneForFoodInsecurity():", error.message);
    throw error;
  }
}
