import { Loader, Message } from 'semantic-ui-react';
import QuestionnaireResponseCard from './responses';
import useSWR from 'swr';
import FHIRFetcher from '../../../data/FHIRFetcher';
import { AuthContext } from '../../../contexts/AuthContext';
import { useContext } from 'react';
import moment from 'moment';

function Questionnaires(props: any) {
  let authInfo: any = useContext(AuthContext);
  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: (data) => {
      if (data?.entry?.length > 0) {
        //sort questionnaire response data so that newest will be on the top
        data?.entry?.sort(function (a, b) {
          return moment(b?.resource?.authored)?.valueOf() - moment(a?.resource?.authored)?.valueOf();
        });
      }
    },
  };
  //fetch Questionnaire Responses

  const {
    data: questionnaireResponseData,
    error: questionnaireResponseError,
    isValidating: questionnaireResponseLoading,
    mutate: questionnaireResponseMutate,
  } = useSWR(
    [`/QuestionnaireResponse/?patient=${props.patientId}&_count=150&status:not=entered-in-error`, authInfo.token],
    FHIRFetcher,
    options
  );

  return (
    <div style={{ minHeight: '500px' }}>
      {!props.questionnaireData && <Loader active inline="centered" content="Loading Survey Data..." />}
      {!questionnaireResponseData && !questionnaireResponseError && (
        <Loader active inline="centered" content="Loading Survey Response Data..." />
      )}
      {props.questionnaireData && questionnaireResponseData && props.questionnaireData.entry && (
        <QuestionnaireResponseCard
          patientId={props.patientId}
          patientFirstName={props.patientFirstName}
          patientPhoneNumber={props.patientMobilePhone}
          patientEmail={props.patientEmail}
          org={props.org}
          profileMutate={props.profileMutate}
          questionnaireData={
            props.foodSurveyData && props.foodSurveyData.entry
              ? {
                  entry: props.questionnaireData.entry.concat(props.foodSurveyData.entry),
                }
              : props.questionnaireData
          }
          questionnaireResponseData={questionnaireResponseData}
          questionnaireResponseMutate={questionnaireResponseMutate}
          questionnaireResponseLoading={questionnaireResponseLoading}
          QRID={props.QRID}
          tasksMutate={props.tasksMutate}
          consentDataMutate={props.consentDataMutate}
          //doNotContact={props.doNotContact}
          //careTeamData={props.careTeamData}
          //personData={props.personData}
          //refreshTasks={props?.refreshTasks}
          fhirPatient={props.fhirPatient}
          refreshPatientDetails={props.refreshPatientDetails}
        />
      )}

      {questionnaireResponseError && (
        <Message negative>
          <Message.Header>Oops!. Something went wrong.. </Message.Header>
          <p>{questionnaireResponseError.message}</p>
        </Message>
      )}
    </div>
  );
}

export default Questionnaires;
