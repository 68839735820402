import { Input, Form, TextArea } from 'semantic-ui-react';

export default function String(props) {
  //props: addAnswer, i, j, currentAnswer, enable

  const handleInput = (e) => {
    if (!e.target.value) {
      props.addAnswer(null, props.i, props.j);
    } else {
      props.addAnswer([{ valueString: e.target.value }], props.i, props.j);
    }
  };

  return !props.enable ? (
    <Input value="" disabled fluid placeholder="Enter Answer Here" />
  ) : props.currentAnswer ? (
    //limit the referral answer to 25 characters and show smaller textbox for BCSC-MARE-PD program
    props.question.linkId === 'PW-REFERRAL-01' ? (
      <Form>
        <Input
          maxLength="25"
          defaultValue={props.currentAnswer[0].valueString}
          fluid
          placeholder="Enter Answer Here"
          onChange={handleInput}
          style={{ width: '50%' }}
        />
      </Form>
    ) : (
      <Form>
        <TextArea
          defaultValue={props.currentAnswer[0].valueString}
          fluid
          placeholder="Enter Answer Here"
          onChange={handleInput}
        />
      </Form>
    )
  ) : props.question.linkId === 'PW-REFERRAL-01' ? (
    <Form>
      <Input maxLength="25" style={{ width: '50%' }} fluid placeholder="Enter Answer Here" onChange={handleInput} />
      {/* limit the referral answer to 25 characters and show smaller textbox for BCSC-MARE-PD program */}
    </Form>
  ) : (
    <Form>
      <TextArea fluid placeholder="Enter Answer Here" onChange={handleInput} />
    </Form>
  );
}
