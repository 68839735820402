import { useContext, useEffect, useState } from "react";
import React from "react";
import useSWR from "swr";
import { AuthContext } from '../../../../contexts/AuthContext'
import { Loader, Message} from "semantic-ui-react";
import _ from 'lodash';
import FHIRFetcher from "data/FHIRFetcher";
import OrdersTable from "./table";


function PatientOrders(props: any) {
    let authInfo: any = useContext(AuthContext);
    const [loading, setLoading] = React.useState(true);
    const options = {
        shouldRetryOnError: false, revalidateOnFocus: false, 
        onError: (error) => {
            setLoading(false);
            console.error('Error occurred while fetching data:', error);
        },
        onSuccess:
            (data) => {
                setLoading(false);
            }
    };
    
    const { data: ordersData, error: ordersError} = useSWR(
        [`/NutritionOrder/?patient=${props.patientId}`, authInfo.token],
        FHIRFetcher,
        options 
    );
   
    return (
        <div>
            
            <div>
            {(!ordersData &&
                !ordersError || loading) && <Loader active inline="centered" />
            }
            {ordersData && ordersData.length === 0 &&
                <Message warning header="Info" content="0 Items found!"/>
            }
            {ordersError &&
                <Message header = 'Oops!. Something went wrong..' content={ordersError.message} negative/>
            }
            {ordersData && ordersData.length > 0 &&
            <>
                <OrdersTable patientId={props.patientId} org={props.patient && props.patient.org}  data={ordersData}  />
            </>
            }
            </div>
        </div>
    );
};
export default PatientOrders;