import React, { useState, useEffect, useContext} from "react";
import {Message, Table } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import _ from "lodash";
import FHIRFetcher from "../../../data/FHIRFetcher";
import useSWR from "swr";
import * as FHIRUtils from "../../../utils/FHIRUtils"
import moment from "moment";
import AuthContext from "../../../contexts/AuthContext";


function ActionableInsights(props: any) {

  const AI_CODES: Set<string> = new Set(["AI-Time-Sensitive","AI-Non-Emergency","AI-Emergency","AI-Time-Sensitive"]);

  let authInfo: any = useContext(AuthContext);
  const [tableData, setTableData] = useState([]);
  
  const options = { shouldRetryOnError: false, revalidateOnFocus: false, revalidateIfStale: false, revalidateOnReconnect: false };

  //fetch provenance data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: aiData, error: aiError, mutate } = useSWR(
    [`/DocumentReference/?patient=${props.patientId}&status:not=entered-in-error&_sort=-_lastUpdated`,authInfo.token],
    FHIRFetcher,
    options
  );

  useEffect(()=>{
    if(aiData && aiData.entry && aiData.entry.length){
      setTableData(
          aiData.entry.map(entry => FHIRUtils.parseFHIRNotes(entry.resource))
          .filter(note => AI_CODES.has(note.code))
      )
    }
    else {
      setTableData([])
    }
  },[aiData])

return(
  <div>
     {tableData && !aiError &&
      <Table celled padded color='purple'>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>By</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableData.map(({ id, type, code, date, author,description }) => (
            <Table.Row key={id}>
              <Table.Cell>{type}</Table.Cell>
              <Table.Cell>{description}</Table.Cell>
              <Table.Cell>{moment(date).fromNow()}</Table.Cell>
              <Table.Cell>{author}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    }
    {((aiData && !aiData.entry) || (!tableData || tableData.length == 0)) &&
            <Message warning
                header="No Actionable Insights"
            />
    }
    {aiError &&
        <Message negative>
            <Message.Header>Oops!. Something went wrong.. </Message.Header>
            <p>{aiError.message}</p>
        </Message>
    }
    </div>
);
}

export default ActionableInsights;