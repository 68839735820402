import { useContext ,useEffect} from 'react'
import { Loader, Message, Card } from "semantic-ui-react";
import useSWR, { mutate } from 'swr';
import { AuthContext } from '../../../../contexts/AuthContext'
import FHIRFetcher from "../../../../data/FHIRFetcher";
import MemberCard from '../card';
import { Patient } from 'fhir/r4';

function MembersList(props: any) {

    let authInfo: any = useContext(AuthContext)

    // State variabel with array of  PatientIds..ths will be boiund to the SWR query that gets My pateimts

    const handlePatientSelect = (selectedPatient: Patient) => {
        props.onPatientSelect(selectedPatient)
    };

    const options = { shouldRetryOnError: false, revalidateOnFocus: false };

    let key = `/Patient/?organization=${props.payer}${props.searchString? '&_content:contains='+props.searchString:''}`

    //this is when a new patient is added the parent component toggles the refresh
    useEffect(() => {
        //mutate(key)
        tasksMutate()
      }, [props.refresh]);

    const { data: patientData, error: patientError, isValidating: patientsDataLoading, mutate: tasksMutate} = useSWR(
        [key, authInfo.token],
        FHIRFetcher,
        options
    );

    return (
        <div>
            <p />
            {patientsDataLoading && <Loader active inline="centered" />}
            {patientData && !patientData.entry &&
                <Message warning
                    header="No Members found!"
                    content="Please refine your search"
                />
            }
            {patientData && patientData.entry &&
                <Card.Group>
                    {patientData.entry.map((patientEntry) => {
                        return <MemberCard key={patientEntry.resource.id} patient={patientEntry.resource} onPatientSelect={handlePatientSelect} />
                    })
                    }
                </Card.Group>
            }
            {patientError &&
                <Message negative>
                    <Message.Header>Oops!. Something went wrong.. </Message.Header>
                    <p>{patientError.message}</p>
                </Message>
            }

        </div>
    )
}

export default MembersList

