import { useState, useEffect, useContext } from 'react';
import {
  Header,
  Button,
  Icon,
  Modal,
  Form,
  Input,
  Select,
  Message,
  Dimmer,
  Loader,
  Segment,
  Checkbox,
  Confirm,
} from 'semantic-ui-react';
import FHIRUpdater from '../../../data/FHIRUpdater';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import usaStates from '../../../data/States';
import * as Helpers from '../../../utils/Helpers';
import PatientCDO from '../../../models/patient';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchStateAbbr, PreparePatientPayLoad, ValidatePatient } from './utils';
import { FhirResource, Provenance } from 'fhir/r4';
import { FHIRUtils, ProvenanceBuilder } from '../../../fhir-sdoh';
import FHIRProxyClient from '../../../fhir-sdoh/common/fhirproxy-client';
import moment from 'moment';
import FHIRPoster from 'data/FHIRPoster';
import FHIRFetcher from 'data/FHIRFetcher';
import * as crypto from 'crypto';

const genderOptions = [
  { key: 'm', text: 'Male', value: 'Male' },
  { key: 'f', text: 'Female', value: 'Female' },
  { key: 'na', text: 'Unknown', value: 'Unknown' },
];

const genderConervension = {
  male: 'Male',
  female: 'Female',
  unknown: 'Unknown',
};

// UI12: modal flag 'open' passed here from PatientCard
function Patient(props: any) {
  let authInfo: any = useContext(AuthContext);
  let practitionerName = authInfo.personName;

  const [dimmerProcess, setDimmerProcess] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [displayDeliveryAddress, setDisplayDeliveryAddress] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false); //open the popup window to confirm to create the patient
  const [confirmToCreatePatient, setConfirmToCreatePatient] = useState(false);
  let open = props.open;
  let flatPatient = props.patient;
  let patientId = props.patientId;
  let setFlag = props.toggleModal;
  let operation = props.operation;

  useEffect(() => {
    if (confirmToCreatePatient) {
      //if the confirmToCreatePatient is set to true, need to update validatePatientDetails with the updated confirmToCreatePatient state
      validatePatientDetails();
    }
  }, [confirmToCreatePatient]);

  let [patientDate, setPatientDate] = useState(moment('1990-01-01').toDate());

  // Object template for patient builder to create Patient object
  let [patientDetails, setPatientDetails] = useState(PatientCDO);

  // Default values population for Country and DOB
  useEffect(() => {
    flatPatient.country =
      flatPatient.country === undefined || flatPatient.country === '' ? ['USA'] : flatPatient.country;
    flatPatient.delivery_country =
      flatPatient.delivery_country === undefined || flatPatient.delivery_country === ''
        ? ['USA']
        : flatPatient.delivery_country;
    flatPatient.org = props.organization;
    flatPatient.gender = flatPatient.gender ? genderConervension[flatPatient.gender.toLowerCase()] : '';
    let dob = (flatPatient.dob === undefined || flatPatient.dob === '')
      ? moment('1990-01-01').toDate()
      : moment(flatPatient.dob).toDate()
    setPatientDate(dob);
    flatPatient.dob = moment(dob).format('MM/DD/YYYY')

    flatPatient.state[0] = flatPatient.state[0] !== undefined && FetchStateAbbr(flatPatient.state[0]);
    flatPatient.delivery_state[0] =
      flatPatient.delivery_state[0] !== undefined && FetchStateAbbr(flatPatient.delivery_state[0]);
    setPatientDetails(flatPatient);

    if (flatPatient && operation === 'Update') {
      if (
        flatPatient.addressLine1[0] === flatPatient.delivery_addressLine1[0] &&
        flatPatient.addressLine2[0].trim() === flatPatient.delivery_addressLine2[0] &&
        flatPatient.city[0] === flatPatient.delivery_city[0] &&
        flatPatient.state[0] === flatPatient.delivery_state[0] &&
        flatPatient.zip[0] === flatPatient.delivery_zip[0]
      ) {
        setDisplayDeliveryAddress(true);
      } else {
        setDisplayDeliveryAddress(false);
      }
    }
  }, [flatPatient, operation]);

  const handleChange = (e, attributeName) => {
    //let { name, value } = e.target;
    if (e && e.target) {
      let name = e.target.name;
      let value = '';
      if (attributeName === 'dob') {
        name = attributeName;
        let rawdob = e.target.dataset.testid.split('-');
        value = rawdob[3] + '/' + rawdob[4] + '/' + rawdob[2];
      } else if (attributeName === 'gender' || attributeName === 'state' || attributeName === 'delivery_state') {
        name = attributeName;
        value = e.target.textContent;
      } else {
        let xmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/
        let jsRegex = /\w+\s*=\s*function\s*\(.*?\)\s*\{.*?\}/
        if ((e.target.value).match(xmlRegex) || (e.target.value).match(jsRegex)) {
          setValidationErrors((state) => [...state, attributeName?.name + ": could not validate, please try a different value"]);
        } else {
          value = e.target.value;
        }
      }
      let textObjs = ['org', 'dob', 'gender'];

      // Pass the data as an array for objects other than dob, gender and Organization
      setPatientDetails((prevState) => ({
        ...prevState,
        [name]: textObjs.includes(name) ? value : [value],
      }));
    }
  };

  // Validation of Form fields
  const validatePatientDetails = async () => {
    for (const property in patientDetails) {
      if (Array.isArray(patientDetails[property]) && patientDetails[property][0] !== undefined) {
        patientDetails[property][0] = patientDetails[property][0].trim();
      } else if (typeof patientDetails[property] === 'string' && patientDetails[property] !== undefined) {
        patientDetails[property] = patientDetails[property].trim();
      }
    }
    let tempConfirmToCreatePatient = confirmToCreatePatient; //used to update the confirmToCreatePatient but not update the state

    let patientPayLoad = PreparePatientPayLoad(
      patientDetails,
      operation,
      practitionerName,
      displayDeliveryAddress,
      patientId,
      props.careteamStatus,
      props?.fhirPatient //patient original data
    );

    setValidationErrors((state) => []);
    let validations = ValidatePatient(patientDetails, operation, practitionerName, displayDeliveryAddress, patientId);
    setValidationErrors(validations.errors);
    if (validations.valid) {
      //setDimmerProcess(true);
      if (operation === 'Add') {
        //check if the patient with same member id exists in the fhirstore
        let key = `/Patient/${patientPayLoad?.id ? '?_content:contains=' + patientPayLoad?.id : ''}`;
        let patientResult = await FHIRFetcher(key, authInfo.token);
        //if patient with same member id exists, show popup to confirm if it's okay to create the patient
        if (patientResult?.total > 0 && !confirmToCreatePatient) {
          //patient with the same member id exists
          setConfirmOpen(true);
        } else {
          //if the patient doesn't exist, we don't need to confirm to create the patient, set tempConfirmToCreatePatient to true
          tempConfirmToCreatePatient = true;
        }

        if (patientResult?.total > 0 && confirmToCreatePatient) {
          //update patient id to org+patient id to hash it if patient exists and confirm to create patient
          patientPayLoad.id = crypto
            .createHash('md5')
            .update(patientDetails.org.toUpperCase() + patientPayLoad.id)
            .digest('hex');
        }
      } else {
        //when we update the patient information, always set confirmToCreatePatient to true as the member already exist in the fhirstore
        tempConfirmToCreatePatient = true;
      }
    }

    if (validations.valid && authInfo.validateUSPSAddress) {
      // USPS address validation
      let addressValidationPromise = 0;
      for (let item of patientPayLoad.address) {
        // eslint-disable-next-line no-loop-func
        Helpers.validateUSPSAddress(item.line[0], item.line[1], item.city, item.state, item.postalCode).then(
          (success) => {
            addressValidationPromise++;
            if (
              (patientPayLoad.address.length === addressValidationPromise || displayDeliveryAddress) &&
              tempConfirmToCreatePatient
            ) {
              savePatient(authInfo.token, patientPayLoad);
            }
          },
          (errors) => {
            if (errors.length) {
              let type = item.type === 'physical' ? 'Delivery Address: ' : 'Mailing Address: ';
              errors.forEach((error) => {
                setValidationErrors((state) => [...state, type + error]);
              });
            }
          }
        );
        if (displayDeliveryAddress) {
          return false;
        }
      }
    } else if (validations.valid && !authInfo.validateUSPSAddress && tempConfirmToCreatePatient) {
      savePatient(authInfo.token, patientPayLoad);
    }
  };

  // FHIR Call to update patient details
  const savePatient = (token, patientPayLoad) => {
    setDimmerProcess(true);

    let proxyURL = process.env.REACT_APP_API_ENDPOINT + '/fhir/';
    let fhirProxyClient = new FHIRProxyClient(authInfo.token, proxyURL);
    let fhirBundle: FhirResource = FHIRUtils.buildEmptyBundle('transaction');
    let bundleEntries: any[] = [];

    /* //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Patient/${patientPayLoad.id!}`,
      {
        reference: 'Person/' + props.personId,
        display: "Temp Person Name", //props.personName,
      }, //eg. {Practitioner/oa2,johnsmith},
      patientPayLoad.id,
      operation === 'Update' ? 'UPDATE' : 'CREATE',
      `Patient has been ${operation === 'Update' ? 'updated' : 'created'}`,
      {
        code: patientDetails.org.toUpperCase(),
        display: patientDetails.org.toLowerCase(),
      } //eg. {Organization/HUMANA,humana}
    );
    provenance.target.splice(1, 1);
    bundleEntries.push(FHIRUtils.buildResourceEntry(provenance, false)); //Provenance */

    //now post bundle request
    fhirBundle.entry = bundleEntries;

    FHIRUpdater('/Patient/' + patientPayLoad.id, token, patientPayLoad).then(
      (response) => {
        setDimmerProcess(false);
        setFlag(false, response);
        if (props.onNewPatient) {
          props.onNewPatient();
        }
        /* fhirProxyClient.send('post', '', fhirBundle).then(
          (response) => {},
          (error) => {
            // No operation needed on success/fail case
          }
        ); */
      },
      (error) => {
        setDimmerProcess(false);
        setValidationErrors((state) => [
          ...state,
          'Unable to ' + operation.toLowerCase() + ' member details...Please contact helpdesk',
        ]);
      }
    )
  };

  // Populate validation/error messages on the form
  const setValidationMessages = () => {
    return validationErrors.map((error) => {
      return <Message.Item>{error}</Message.Item>;
    });
  };

  //pull fields to disable/display from env var.
  let fieldsToDisable, fieldsToDisplay = []
  if (process.env.REACT_APP_MEMBER_EDIT_CONFIG) {
    fieldsToDisable = JSON.parse(process.env.REACT_APP_MEMBER_EDIT_CONFIG)?.["disable"]?.[props.organization] ?? []
    fieldsToDisplay = JSON.parse(process.env.REACT_APP_MEMBER_EDIT_CONFIG)?.["display"]?.[props.organization] ?? []
  }

  return (
    <div>
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        //style={{height: '95%'}}
        open={open}
        onClose={() => setFlag(false)}
        onOpen={() => setFlag(true)}
      >
        <Header icon="edit" color="red" content={operation + ' Member Details'} />

        <Modal.Content>
          {validationErrors.length > 0 && (
            <Message error>
              <Message.Header>Error:</Message.Header>
              <Message.List>{setValidationMessages()}</Message.List>
            </Message>
          )}

          <Form className="patientForm" onSubmit={validatePatientDetails} autoComplete="off" error>
            <Form.Group>
              <Form.Field
                id="form-input-control-first-name"
                control={Input}
                label="First Name"
                width={8}
                placeholder="First Name"
                name="firstName"
                value={patientDetails.firstName[0]}
                onChange={handleChange}
                required
                readOnly={operation==='Update' && fieldsToDisable.includes("firstName")}
              />
              <Form.Field
                id="form-input-control-middle-name"
                control={Input}
                width={2}
                label="Middle Name"
                placeholder="Middle Name"
                name="middleName"
                value={patientDetails.middleName[0]}
                onChange={handleChange}
                readOnly={operation==='Update' && fieldsToDisable.includes("middleName")}
              />
              <Form.Field
                id="form-input-control-last-name"
                control={Input}
                width={6}
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                value={patientDetails.lastName[0]}
                onChange={handleChange}
                required
                readOnly={operation==='Update' && fieldsToDisable.includes("lastName")}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-memberid"
                control={Input}
                label="Member Id"
                name="memberId"
                onChange={handleChange}
                value={patientDetails.memberId[0]}
                //readOnly={operation === 'Update'}
                required
                readOnly={operation==='Update'/*  && fieldsToDisable.includes("memberId") */}
              />
              {fieldsToDisplay.includes("otherId") && <Form.Field
                id="form-input-control-otherid"
                control={Input}
                label="Other Id"
                name="otherId"
                onChange={handleChange}
                value={patientDetails?.otherId?.[0]}
                //readOnly={patientDetails.org === 'BCSC-MCARE-PD'}
              />}
              <Form.Field
                id="form-input-control-organization"
                control={Input}
                label="Organization"
                placeholder="Organization"
                name="org"
                value={patientDetails.org}
                onChange={handleChange}
                readOnly
                required
              />
              <Form.Field
                id="form-input-control-provider"
                control={Input}
                label="Provider"
                placeholder="Provider"
                name="provider"
                value={patientDetails.provider ? patientDetails.provider : ''}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                id="form-select-control-gender"
                control={Select}
                options={genderOptions}
                label={{
                  children: 'Gender',
                  htmlFor: 'form-select-control-gender',
                }}
                placeholder="Gender"
                name="gender"
                value={patientDetails.gender}
                onChange={(e) => handleChange(e, 'gender')}
                search
                searchInput={{ id: 'form-select-control-gender' }}
              />
              <Form.Field
                id="form-input-control-language"
                control={Input}
                label="Language"
                placeholder="language"
                name="language"
                value={patientDetails.language[0]}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-home-phone"
                control={Input}
                label="Home Phone"
                placeholder="Home Phone"
                name="homePhone"
                value={patientDetails.homePhone[0]}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-control-mobile-phone"
                control={Input}
                label="Mobile Phone"
                placeholder="Mobile Phone"
                name="mobilePhone"
                value={patientDetails.mobilePhone[0]}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <SemanticDatepicker
                required
                autoComplete="false"
                datePickerOnly
                name="dob"
                value={patientDate}
                clearable={false}
                format="MM/DD/YYYY"
                id="form-input-control-dob"
                label="Date Of Birth"
                onChange={(e) => handleChange(e, 'dob')}
                className="dobCalendar"
                disabled={operation==='Update' && fieldsToDisable.includes("dob")}
              />

              <Form.Field
                id="form-input-control-email"
                control={Input}
                label="Email"
                placeholder="Email"
                name="email"
                value={patientDetails.email[0]}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Field id="form-input-control-mailingaddress-label" size="large" label="Mailing Address: " />
            </Form.Group>
            <Segment>
              <Form.Group>
                <Form.Field
                  id="form-input-control-addressLine1"
                  control={Input}
                  width={8}
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  name="addressLine1"
                  required
                  value={patientDetails.addressLine1[0]}
                  onChange={handleChange}
                />

                <Form.Field
                  id="form-input-control-addressLine2"
                  control={Input}
                  width={8}
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  name="addressLine2"
                  value={patientDetails.addressLine2[0]}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-city"
                  control={Input}
                  label="City"
                  placeholder="City"
                  name="city"
                  required
                  value={patientDetails.city[0]}
                  onChange={handleChange}
                />

                <Form.Field
                  id="form-select-control-state"
                  control={Select}
                  options={usaStates.list}
                  label={{
                    children: 'State',
                    htmlFor: 'form-select-control-state',
                  }}
                  placeholder="State"
                  name="state"
                  value={patientDetails.state[0]}
                  onChange={(e) => handleChange(e, 'state')}
                  search
                  required
                  searchInput={{ id: 'form-select-control-state' }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-zip"
                  control={Input}
                  label="Zip"
                  placeholder="Zip"
                  name="zip"
                  value={patientDetails.zip[0]}
                  required
                  onChange={handleChange}
                />
                <Form.Field
                  id="form-input-control-country"
                  control={Input}
                  label="Country"
                  placeholder="Country"
                  name="country"
                  value={patientDetails.country[0]}
                  onChange={handleChange}
                  required
                  readOnly
                />
              </Form.Group>
            </Segment>

            <Form.Group>
              <Form.Field>
                <Checkbox
                  checked={displayDeliveryAddress}
                  onChange={(e) => setDisplayDeliveryAddress(!displayDeliveryAddress)}
                  label="Delivery Address same as Mailing Address"
                />
              </Form.Field>
            </Form.Group>
            {!displayDeliveryAddress && (
              <div>
                <Form.Group size="massive">
                  <Form.Field id="form-input-control-mailingaddress-label" size="massive" label="Delivery Address: " />
                </Form.Group>
                <Segment>
                  <Form.Group>
                    <Form.Field
                      id="form-input-control-delivery_addressLine1"
                      control={Input}
                      width={8}
                      label="Address Line 1"
                      placeholder="Address Line 1"
                      name="delivery_addressLine1"
                      required
                      value={patientDetails.delivery_addressLine1[0]}
                      onChange={handleChange}
                    />

                    <Form.Field
                      id="form-input-control-delivery_addressLine2"
                      control={Input}
                      width={8}
                      label="Address Line 2"
                      placeholder="Address Line 2"
                      name="delivery_addressLine2"
                      value={patientDetails.delivery_addressLine2[0]}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-delivery_city"
                      control={Input}
                      label="City"
                      placeholder="City"
                      name="delivery_city"
                      required
                      value={patientDetails.delivery_city[0]}
                      onChange={handleChange}
                    />

                    <Form.Field
                      id="form-select-control-delivery_state"
                      control={Select}
                      options={usaStates.list}
                      label={{
                        children: 'State',
                        htmlFor: 'form-select-control-state',
                      }}
                      placeholder="State"
                      name="delivery_state"
                      value={patientDetails.delivery_state[0]}
                      onChange={(e) => handleChange(e, 'delivery_state')}
                      search
                      required
                      searchInput={{ id: 'form-select-control-delivery_state' }}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-delivery_zip"
                      control={Input}
                      label="Zip"
                      placeholder="Zip"
                      name="delivery_zip"
                      value={patientDetails.delivery_zip[0]}
                      required
                      onChange={handleChange}
                    />
                    <Form.Field
                      id="form-input-control-delivery_country"
                      control={Input}
                      label="Country"
                      placeholder="Country"
                      name="delivery_country"
                      value={patientDetails.delivery_country[0]}
                      onChange={handleChange}
                      required
                      readOnly
                    />
                  </Form.Group>
                </Segment>
              </div>
            )}
            <Modal.Actions style={{ textAlign: 'center' }}>
              <Button color="red" onClick={() => setFlag(false)}>
                <Icon name="remove" /> Cancel
              </Button>
              <Button color="green" type="submit">
                <Icon name="checkmark" /> {operation}
              </Button>
            </Modal.Actions>
          </Form>
          <Dimmer active={dimmerProcess}>
            <Loader />
          </Dimmer>
          <Confirm
            content="The patient with same member id exsits, are you sure that you still want to create this member?"
            open={confirmOpen}
            closeOnDimmerClick={false}
            onCancel={() => {
              setConfirmOpen(false);
              setDimmerProcess(false);
            }}
            onConfirm={() => {
              setConfirmToCreatePatient(true);
              setConfirmOpen(false);
            }}
            style={{ marginTop: '20%' }}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default Patient;
