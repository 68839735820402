import React, { useState, useEffect, useContext } from 'react';
import {
  Message,
  Button,
  Table,
  Label,
  Loader,
  Dimmer,
  SemanticCOLORS,
  Select,
  Form,
  Dropdown,
  Icon,
  Popup,
} from 'semantic-ui-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import _ from 'lodash';
import FHIRFetcher, { MultiFetcher } from '../../../data/FHIRFetcher';
import FHIRUpdater from '../../../data/FHIRUpdater';
import useSWR from 'swr';
import * as FHIRUtils from '../../../utils/FHIRUtils';
import { FHIRUtils as FHIRSDOHUtils, Intervene, ProvenanceBuilder } from '../../../fhir-sdoh';
import moment from 'moment';
import AuthContext from '../../../contexts/AuthContext';
import { sortReducer } from '../../../utils/SortReducer';
//import { EMAIL_TEMPLATES, REFERRAL_SUBJECT_LINES, TEXT_MESSAGE_TEMPLATES_PINPOINT } from 'fhir-sdoh/common/constants';
import SendGridPoster from 'data/SendGridPoster';
import FHIRPoster from 'data/FHIRPoster';
import { FHIRProxyClient, ObservationBuilder } from 'fhir-sdoh';
import { FhirResource, Provenance } from 'fhir/r4';
//import FoodFetcher from 'data/FoodFetcher';
//import FoodUpdater from 'data/FoodUpdater';

const REFERRAL_OBSERVATION_CODE = 'REFERRAL-TYPE';

function Referrals(props: any) {
  let authInfo: any = useContext(AuthContext);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusvalue, setStatusValue] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [urls, setUrls] = useState([]);
  const [dateQuery, setDateQuery] = useState('');
  const [searchId, setSearchId] = useState('');
  const [referralTableData, setReferralTableData] = useState([]);
  const [referralType, setReferralType] = useState('');
  const [referralTypeOption, setReferalTypeOption] = useState([]);

  const referralStatusColors = {
    NEW: 'blue' as SemanticCOLORS,
    CANCELLED: 'grey' as SemanticCOLORS,
    COMPLETED: 'green' as SemanticCOLORS,
    'IN-PROGRESS': 'orange' as SemanticCOLORS,
    OUTREACH: 'yellow' as SemanticCOLORS,
    REJECTED: 'red' as SemanticCOLORS,
    INELIGIBLE: 'black' as SemanticCOLORS,
    PENDING: 'pink' as SemanticCOLORS,
    UNREACHABLE: 'purple' as SemanticCOLORS,
  };

  useEffect(() => {
    if (props.activeTab === 'REFERRALS') {
      referralDataMutate(); //this will trigger SWR to re-Fetch
      //get plan definition and interventionActionId to get referral types
      if (props.org === 'HA' || props.org === 'UHC') {
        FHIRFetcher(`/PlanDefinition?identifier=${props.org}&status=active`, authInfo.token).then((plan) => {
          let planDefinition;
          let plannedInterventionActionId;
          let index;
          if (plan && plan.entry) {
            planDefinition = plan.entry[0].resource;
            index = plan.entry[0].resource?.action?.findIndex((obj) => obj.id === 'intervention');
            if (index >= 0) {
              plannedInterventionActionId = plan.entry[0].resource?.action?.[index]?.action?.[0]?.id;
            }
            if (planDefinition && plannedInterventionActionId) {
              let interventionSubActions = Intervene.getInterventionSubActions(
                planDefinition,
                plannedInterventionActionId
              );
              if (interventionSubActions.length > 0) {
                setReferalTypeOption(
                  interventionSubActions.map((k) => {
                    return { key: k.title, value: k.title, text: k.title };
                  })
                );
              }
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab]);

  useEffect(() => {
    if (props.activeItem === 'referrals' && props.menuIndex === 5 && props.org !== 'HA' && props.org !== 'UHC') {
      props.handleMenuItemClick('all members');
    }
  }, [props.activeItem, props.org]);

  useEffect(() => {
    dispatch({ type: 'CLEAR_FILTERS', data: referralTableData });
  }, [referralTableData]);
  let daysDict = {
    TODAY: 0,
    'PAST 7 DAYS': 7,
    'PAST 14 DAYS': 14,
    'PAST 30 DAYS': 30,
    'PAST 60 DAYS': 60,
  };

  /* async function onStatusChange(referral, status) {
    let fhirReferral = buildFHIRReferral(referral.patientId, referral, status, authInfo.practitionerName, props.org);
    setLoading(true);
    let payload;
    if (props.org === 'HA') {
      payload = {
        to: referral.patientEmail,
        from: 'food@nourishedrx.com',
        patientId: referral.patientId,
        templateId: EMAIL_TEMPLATES['HA END OF REFERRAL'],
        subject: 'End of Referral',
        sender: authInfo.practitionerName,
        dynamicTemplateData: {
          memberName: referral.patientName,
        },
        text: 'auto',
      };
    }

    let proxyURL = process.env.REACT_APP_API_ENDPOINT + '/fhir/';
    let fhirProxyClient = new FHIRProxyClient(authInfo.token, proxyURL);
    // Building Provenance
    let fhirBundle: FhirResource = prepareProvenancePayload(referral, status);

    FHIRUpdater('/Observation/' + referral.id, authInfo.token, fhirReferral).then(
      (response) => {
        setLoading(false);
        referralDataMutate();

        props.profileMutate && props.profileMutate();

        fhirProxyClient.send('post', '', fhirBundle).then(
          (response) => {},
          (error) => {
            // No operation needed on success/fail case
          }
        );
      },
      (error) => {
        setLoading(false);
        console.log('Failed to Update Status :' + error);
      }
    );
    if (status === 'COMPLETED' && payload) {
      await SendGridPoster(`/hooks/mail`, authInfo.token, payload).then(
        () => {
          console.log('End of Referral email sent');
        },
        () => {
          console.log('End of Referral email failed');
        }
      );
      if (referral.patientPhone?.[0]) {
        let textPayload = {
          patientId: referral.patientId,
          subject: 'End of Referral',
          sender: authInfo.practitionerName,
          phoneNumber: ('+1' + referral.patientPhone[0]).replaceAll('-', ''),
          message: REFERRAL_SUBJECT_LINES['HA END OF REFERRAL'],
          templateName: TEXT_MESSAGE_TEMPLATES_PINPOINT['HA END OF REFERRAL'],
          text: 'auto',
        };
        await SendGridPoster(`/hooks/text`, authInfo.token, textPayload).then(
          () => {
            console.log('End of Referral text sent');
          },
          () => {
            console.log('End of Referral text failed');
          }
        );
      }
    }
  } */

  /* function prepareProvenancePayload(referral, status) {
    let bundleEntries: any[] = [];
    let fhirBundle: FhirResource = FHIRSDOHUtils.buildEmptyBundle('transaction');
    let orgRef = {
      code: props.org && props.org.toUpperCase(),
      display: props.org && props.org.toLowerCase(),
    };

    //create Provenance
    let provenance: Provenance = ProvenanceBuilder.build(
      `Observation/${referral.id}`,
      {
        reference: 'Practitioner/' + authInfo.practitionerId,
        display: authInfo.practitionerName,
      }, //eg. {Practitioner/oa2,johnsmith},
      referral.patientId,
      'Update',
      `Referral status has been changed to ` +
        status +
        ' for Referral: ' +
        referral.referralId +
        '-' +
        referral.referralType,
      orgRef //eg. {Organization/HUMANA,humana}
    );

    bundleEntries.push(FHIRSDOHUtils.buildResourceEntry(provenance, false)); //Provenance
    //now post bundle request
    fhirBundle.entry = bundleEntries;

    return fhirBundle;
  } */

  const [state, dispatch] = React.useReducer(sortReducer, {
    column: null,
    data: tableData,
    direction: null,
  });
  const { column, data, direction } = state;

  const sliceIntoChunks = function (arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const options = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    onSuccess: (data, key, config) => {
      let patientIds = [];
      setTableData([]);
      let tempTableData = [];

      if (data && data.entry && data.entry.length > 0) {
        // let referralsList = data.entry.splice(0,1);
        let referralsList = data.entry;
        // if (statusvalue.length > 0) {
        //   referralsList = referralsList.filter((r) =>
        //     r.resource.identifier.find(
        //       (i) =>
        //         i.system === 'https://projectwell.io/fhir/identifiers/referral-status' &&
        //         i.value === statusvalue &&
        //         i.system === 'https://projectwell.io/fhir/orgid' &&
        //         i.value === props.org
        //     )
        //   );
        // }

        referralsList.forEach((entry) => {
          let patientRef = entry.resource.subject.reference;
          let patientId = patientRef.substring(patientRef.indexOf('/') + 1);
          patientIds.push(patientId);

          let flatReferral = FHIRUtils.parseFHIRreferral(entry.resource);
          setTableData((result) => {
            return [...result, flatReferral];
          });
          tempTableData.push(flatReferral);
        });
        setReferralTableData((prev) => tempTableData);

        let uniquePatients = _.uniq(patientIds);
        let endPoints = [];
        let patientChunks = sliceIntoChunks(uniquePatients, 99);

        patientChunks.forEach((chunk) => {
          endPoints.push(`/Patient/?organization=${props.org}&_id=${chunk.join(',')}`);
          endPoints.push(`/CareTeam/?subject=${chunk.join(',')}`);
        });

        setUrls((prev) => [...endPoints]);
        //careTeamMutate();
      }
    },
  };

  const careTeamOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: (dataset, key, config) => {
      if (dataset?.length) {
        // let chunkLength = dataset.length*2;
        let patient = true;
        let patientsDataset = { entry: [] };
        let careTeamDataset = { entry: [] };
        dataset.forEach((element) => {
          if (patient) {
            if (element.entry) {
              patientsDataset.entry = [...element.entry, ...patientsDataset.entry];
            }
          } else {
            if (element.entry) {
              careTeamDataset.entry = [...element.entry, ...careTeamDataset.entry];
            }
          }
          patient = !patient;
        });

        let patientsRef = {};
        patientsDataset?.entry?.forEach((item) => {
          let name = FHIRUtils.parseFHIRPatientName(item.resource);
          let email = FHIRUtils.parseFHIRPatientEmail(item.resource);
          //let phone = FHIRUtils.parseFHIRPatientPhone(item.resource);
          patientsRef[item.resource.id] = {
            OA: '',
            RD: '',
            patientName: name,
            resource: item.resource,
            patientEmail: email,
            //patientPhone: phone,
          };
        });

        careTeamDataset?.entry?.forEach((item) => {
          let careTeamReference = FHIRUtils.parseFHIRCareTeam(item.resource);
          if (patientsRef && patientsRef[careTeamReference.patientId]) {
            patientsRef[careTeamReference.patientId]['OA'] = careTeamReference.OA;
            patientsRef[careTeamReference.patientId]['RD'] = careTeamReference.RD;
          }
        });

        let referralObject = [];

        referralTableData.forEach((ref) => {
          referralObject.push({ ...ref, ...patientsRef[ref.patientId] });
        });

        setReferralTableData(referralObject);
      }
    },
  };

  /* const {
    error: careTeamError,
    mutate: careTeamMutate,
    isValidating: careTeamDatasetLoading,
  } = useSWR([authInfo.token, ...urls], MultiFetcher, careTeamOptions); */

  //fetch referral data
  const {
    data: referralData,
    error: referralError,
    mutate: referralDataMutate,
    isValidating: referralDatasetLoading,
  } = useSWR(
    // [`/referral`, authInfo.token],
    [
      `/Observation/?code=${REFERRAL_OBSERVATION_CODE}&identifier=${props.org}${
        statusvalue ? ':::' + statusvalue : ''
      }&_sort=-date&_count=500${
        props.patientId || props.searchString ? '&patient=' + (props.patientId || props.searchString) : ''
      }${searchId ? '&_content:exact=' + searchId : ''}${dateQuery ? dateQuery : ''}`,
      authInfo.token,
    ],
    FHIRFetcher,
    options
  );

  /* const getDisplay = (code: string) => {
    let arr = code.toLowerCase().split('-');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    let str = arr.join(' ');
    return str;
  }; */

  /* const generateNewReferralId = (referralId: any) => {
    if (referralId === null || referralId === undefined || referralId.length === 0) {
      referralId = props.org.toString() + moment().format('YY').toString() + '0';
    }
    let seqNumber = referralId.slice(props.org.toString().length + 2).toString(); // Slice the referral id and get only the last 5 digit part
    seqNumber = (parseInt(seqNumber) + 1).toString(); // Increment by 1
    while (seqNumber.length < 5) seqNumber = '0' + seqNumber;
    return props.org.toString() + moment().format('YY') + seqNumber;
  }; */

  /* const fhirReferralOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  }; */

  /* const { data: fhirReferralData, mutate: fhirReferralDataMutate } = useSWR(
    [`/food/referral-id?organization=${props.org.toUpperCase()}`, authInfo.token],
    FoodFetcher,
    fhirReferralOptions
  ); */

  /* const AddReferral = async (e: any) => {
    let newReferralId: any = null;
    let updatedProfileData = await fhirReferralDataMutate();
    let lastReferralId = updatedProfileData[0].lastReferralId;
    // let filteredData = FHIRUtils.parseFHIRreferral(updatedProfileData.entry[0].resource);

    if (lastReferralId !== undefined && lastReferralId?.length > 0) {
      newReferralId = generateNewReferralId(lastReferralId);
    } else {
      newReferralId = generateNewReferralId(newReferralId);
    }

    //setLoading("Submitting Profile Label...");
    setLoading(true);
    let body: fhir4.Observation = ObservationBuilder.buildReferralObservation(
      props.patientId,
      new Date().toJSON(),
      { code: 'REFERRAL-TYPE', display: getDisplay('REFERRAL-TYPE') },
      referralType,
      newReferralId.trim(),
      authInfo.practitionerName,
      props.org.toString()
    );

    let payload = {
      lastReferralId: newReferralId.trim(),
      organization: props.org,
      lastUpdated: moment().utc().format(),
      lastUpdatedBy: authInfo.practitionerName,
    };

    FoodUpdater(`/referral/${props.org}`, authInfo.token, payload).then(
      () => {
        FHIRPoster(`/Observation/`, authInfo.token, body).then(
          () => {
            referralDataMutate().then(() => {
              setLoading(false);
              profileDataMutate();
              props.profileMutate();
              setReferralType('');
            });
          },
          (error) => {
            setLoading(false);
            setReferralType('');
          }
        );
      },
      (error) => {
        console.log('Error updating latest referral id');
      }
    );
  }; */

  /* const profileOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  }; */

  /* const { data: profileData, mutate: profileDataMutate } = useSWR(
    [`/Observation/?identifier=${props.org}&code=REFERRAL-TYPE&_sort=-_lastUpdated&_count=1`, authInfo.token],
    FHIRFetcher,
    profileOptions
  ); */

  let openPatient = (resource) => {
    if (!props.patientId && resource) props.onPatientSelect(resource);
  };

  const handleSearchById = (e, searchProps) => {
    setSearchId(searchProps.value);
  };

  const resetFilters = () => {
    setSearchId('');
    setStatusValue('');
    setDateValue('');
    setDateQuery('');
  };

  const handleDateChange = (e, selectprops) => {
    let numDays = daysDict[selectprops.value];
    let query;
    if (numDays === 0) {
      query = `&date=eq` + moment().format('YYYY-MM-DD');
    } else {
      let sa = moment().subtract(numDays, 'day').format('YYYY-MM-DD');
      query = `&date=ge` + sa;
    }
    setDateQuery(query);
    setDateValue(selectprops.value);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form autoComplete="off">
          <Form.Group>
            <Form.Input
              placeholder="Search by ID"
              icon={<Icon name="search" onClick={handleSearchById} />}
              value={searchId}
              onChange={(e, searchProps) => setSearchId(searchProps.value)}
              name="searchId"
            />
            <Form.Field
              control={Select}
              selectOnBlur={false}
              name="period"
              placeholder="Filter by Date"
              value={dateValue}
              options={['TODAY', 'PAST 7 DAYS', 'PAST 14 DAYS', 'PAST 30 DAYS', 'PAST 60 DAYS'].map((k) => {
                return { key: k, value: k, text: k };
              })}
              onChange={handleDateChange}
            />
            <Form.Field
              control={Select}
              selectOnBlur={false}
              name="category"
              placeholder="Filter by Status"
              value={statusvalue}
              options={Object.keys(referralStatusColors).map((x) => {
                return { key: x, text: x.toString().toUpperCase(), value: x };
              })}
              onChange={(e, statusprops) => setStatusValue(statusprops.value)}
            />
            <Form.Button content="Clear" color="blue" onClick={resetFilters} />
          </Form.Group>
        </Form>
        {/* {props.patientId && (props.org === 'UHC' || props.org === 'HA') && (
          <Form onSubmit={AddReferral}>
            <Form.Group>
              <Form.Field
                id="form-select-control-referral-type"
                name="referralType"
                control={Select}
                options={referralTypeOption}
                placeholder="Select Referral Type"
                onChange={(e, refType) => setReferralType(refType.value)}
                value={referralType}
              />
              <Form.Button
                disabled={referralType === 'Select Referral Type' || referralType === ''}
                primary
                content="Add"
              />
            </Form.Group>
          </Form>
        )} */}
      </div>
      {referralDatasetLoading && <Loader active inline="centered" />}
      {referralData && !referralData.entry && <Message warning header="No Referrals" />}
      {referralError && (
        <Message negative>
          <Message.Header>Oops!. Something went wrong.. </Message.Header>
          <p>{referralError.message}</p>
        </Message>
      )}
      <div className="table-div">
        {!referralDatasetLoading && !referralError /* && !careTeamError */ && (
          <Table sortable celled padded singleLine>
            <Dimmer inverted active={loading}>
              <Loader />
            </Dimmer>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  width={1}
                  sorted={column === 'referralId' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'referralId' })}
                >
                  Referral Id (Total: {(referralData && referralData.entry && data) ? data.length : '0'})
                </Table.HeaderCell>
                {!props.patientId && (
                  <Table.HeaderCell
                    width={1}
                    sorted={column === 'patientName' ? direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'patientName' })}
                  >
                    Member Name
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell
                  width={1}
                  sorted={column === 'referralType' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'referralType' })}
                >
                  Referral Type
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === 'referralDate' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'referralDate' })}
                >
                  Referral Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === 'referralAge' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'referralAge' })}
                >
                  Referral Age
                </Table.HeaderCell>

                <Table.HeaderCell
                  width={1}
                  sorted={column === 'interventions' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'interventions' })}
                >
                  Interventions
                </Table.HeaderCell>
                {/* <Table.HeaderCell
                  width={1}
                  sorted={column === 'OA' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'OA' })}
                >
                  WA
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === 'RD' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'RD' })}
                >
                  RD
                </Table.HeaderCell> */}
                <Table.HeaderCell
                  width={2}
                  sorted={column === 'referralStatus' ? direction : null}
                  onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'referralStatus' })}
                >
                  Referral Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {referralData &&
                referralData.entry &&
                data.map((referral) => (
                  <Table.Row key={referral.id} singleLine>
                    <Table.Cell>
                      <Label color="brown">{referral.referralId}</Label>
                    </Table.Cell>
                    {/* !props.patientId && (
                      <Table.Cell>
                        {careTeamDatasetLoading ? (
                          <Loader inline active size="small" />
                        ) : (
                          <a
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => openPatient(referral?.resource)}
                          >
                            {referral?.patientName}
                          </a>
                        )}
                      </Table.Cell>
                    ) */}
                    <Table.Cell>{referral.referralType}</Table.Cell>
                    <Table.Cell>{referral.referralDate}</Table.Cell>
                    <Table.Cell>{referral.referralAge} days</Table.Cell>
                    <Table.Cell>{referral?.interventions}</Table.Cell>
                    {/* <Table.Cell>
                      {careTeamDatasetLoading ? <Loader inline active size="tiny" /> : referral?.OA}
                    </Table.Cell>
                    <Table.Cell>
                      {careTeamDatasetLoading ? <Loader inline active size="tiny" /> : referral?.RD}
                    </Table.Cell> */}
                    <Table.Cell>
                      <Button.Group size="small" color={referralStatusColors[referral.referralStatus]}>
                        <Popup
                          trigger={<Button>{referral.referralStatus[0]}</Button>}
                          content={
                            <>
                              <i>{referral.lastUpdatedAge}</i> by <b>{referral.lastUpdatedBy}</b>
                            </>
                          }
                        />
                        {/* <Dropdown
                          className="button icon"
                          floating
                          selectOnBlur={false}
                          onChange={(e, selectProps) => onStatusChange(referral, selectProps.value)}
                          options={Object.keys(referralStatusColors).map((x) => {
                            return { key: x, text: x, value: x };
                          })}
                          trigger={<></>}
                        /> */}
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
}

/* function buildFHIRReferral(patientId, referral, status, modifiedBy, orgId) {
  return {
    code: {
      coding: [
        {
          code: 'REFERRAL-TYPE',
          display: 'Referral Type',
          system: 'https://projectwell.io/fhir/preference-codes',
        },
      ],
    },
    effectivePeriod: {
      start: moment
        .tz(referral.referralDate, 'YYYY-MM-DD hh:mm a', moment.tz.guess())
        .tz('America/New_York')
        .format('YYYY-MM-DDTHH:mm:ss+00:00'),
    },
    id: referral.id,
    identifier: [
      {
        system: 'https://projectwell.io/fhir/identifiers/last-modified-by',
        value: modifiedBy,
      },
      {
        system: 'https://projectwell.io/fhir/identifiers/referral-id',
        value: referral.referralId.toString(),
      },
      {
        system: 'https://projectwell.io/fhir/identifiers/referral-status',
        value: status,
      },
      {
        system: 'https://projectwell.io/fhir/identifiers/interventions',
        value: referral?.interventions.toString(),
      },
      {
        system: 'https://projectwell.io/fhir/orgid',
        value: orgId,
      },
    ],
    resourceType: 'Observation',
    status: 'final',
    subject: {
      reference: 'Patient/' + patientId,
    },
    valueString: referral.referralType,
  };
} */

export default Referrals;
