import { Patient } from 'fhir/r4';
import * as FHIRUtils from "../../../utils/FHIRUtils"
import { List } from "semantic-ui-react";
import { formatPhoneNumber } from '../../../utils/utils';

function MemberQuickInfo(props: any) {

    let fhirPatient: Patient = props.patient
    let flatPatient = FHIRUtils.parseFHIRPatient(fhirPatient)

    return (
        <div>
            <List>
                <List.Item>
                    <List.Icon name="marker" color="orange" />
                    <List.Content>
                        {flatPatient.address}
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name="mail" color="blue" />
                    <List.Content>
                        {flatPatient.email}
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Content>
                    {((flatPatient.mobilePhone[0] && flatPatient.mobilePhone[0]!=="") || (flatPatient.homePhone[0] && flatPatient.homePhone[0]!==""))&&
                    <List.Icon name="phone" color="purple" />
                }
                {flatPatient.mobilePhone[0] && flatPatient.mobilePhone[0]!=="" &&
                <>    
                    {formatPhoneNumber(flatPatient.mobilePhone)}  
                    {flatPatient.homePhone[0] && flatPatient.homePhone[0]!=="" &&
                        <> &nbsp; | &nbsp; </>
                        } 
                </>
                }
                       
                {flatPatient.homePhone[0] && flatPatient.homePhone[0]!=="" &&
                <>
                {formatPhoneNumber(flatPatient.homePhone)}</>} 
                    </List.Content>
                </List.Item>
                {/* <List.Item>
                    <List.Icon name="user" color="teal" />
                    <List.Content>
                        {`PW MemberId: ${flatPatient.id}`}
                    </List.Content>
                </List.Item> */}
                {/* <List.Item>
                        <List.Icon name="user" color="teal" />
                        <List.Content>
                            Last Upadted:{moment(flatPatient.lastUpdated).fromNow()}
                        </List.Content>
                    </List.Item> */}
            </List>
        </div>
    )
};

export default MemberQuickInfo;