import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css'
import 'semantic-ui-css/semantic.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PatientTab from "./components/patient/patientTab";

ReactDOM.render(
  < BrowserRouter >
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/:org" element={<App />} />
      <Route path="/patients/:patientId" element={<PatientTab />} />
    </Routes>
  </BrowserRouter >,
  document.querySelector('#root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();