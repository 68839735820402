import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import _ from "lodash";
import React, { useState } from 'react';
import { Header, Loader, Tab } from "semantic-ui-react";

function Dashboard(props: any) {

  const [isLoading, setIsLoading] = useState(true);
  const [rerender, setRerender] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0)

  let activeDashboard = process.env.REACT_APP_PAYER_DASHBOARDS
      ? JSON.parse(process.env.REACT_APP_PAYER_DASHBOARDS)?.[props.org]
        ? typeof JSON.parse(process.env.REACT_APP_PAYER_DASHBOARDS)[props.org] === "string"
          ? JSON.parse(process.env.REACT_APP_PAYER_DASHBOARDS)[props.org]
          : Object.values(JSON.parse(process.env.REACT_APP_PAYER_DASHBOARDS)[props.org])[activeIndex]
        : null
      : null

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleTabChange = (event, data) => {
    setActiveIndex(data.activeIndex)
    setRerender(!rerender)
  }

  return (
    <div>
      {activeDashboard
        ? typeof JSON.parse(process.env.REACT_APP_PAYER_DASHBOARDS)[props.org] === "string"
          ? <iframe
            title='Dashboard'
            src={activeDashboard}
            width='100%'
            height={isLoading ? 'auto' : '950px'}
            onLoad={handleIframeLoad}
          ></iframe>
          : <div>
            <Tab
              className="cb_pushable_tabs"
              defaultActiveIndex={0}
              menu={{ key: "pusher", secondary: true, pointing: true }}
              panes={Object.keys(JSON.parse(process.env.REACT_APP_PAYER_DASHBOARDS)[props.org]).map(tabName => (
                {
                  menuItem: {
                    key: tabName,
                    content: tabName,
                    name: tabName,
                  }
                }
              ))}
              renderActiveOnly={false}
              onTabChange={handleTabChange}
            />
            <iframe
              title='Dashboard'
              src={activeDashboard}
              width='100%'
              height={isLoading ? 'auto' : '950px'}
              onLoad={handleIframeLoad}
            ></iframe>
          </div>
        : <Header as='h4' textAlign='center' icon='chart line' content='Program Data Coming Soon!' />
      }
      {activeDashboard && isLoading && <Loader active inline="centered" />}
    </div>
  );
}

export default Dashboard;

// This will work too. 
//     <Embed
//     id='dashboard'
//     defaultActive
//     placeholder='https://www.example.com/thumbnail.jpg'
//     url='https://lookerstudio.google.com/embed/reporting/5669d5ea-445c-4fd8-bf33-6f40483455be/page/p_vzwylq5w8c'
//   />
