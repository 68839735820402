import Amplify, { API } from "aws-amplify";
import {buildAWSConfig}  from "../aws-config";
const DEFAULT = "default"
Amplify.configure(buildAWSConfig(DEFAULT)); //this part of code can just use default orrg for API end point names

const FHIRPoster = async (endPoint: string, token: string, data: any) => {

    let dataEndPoint = endPoint.includes("auth-org") ? endPoint : "/fhir" + endPoint

    const result = await API.post(process.env.REACT_APP_API_NAME, dataEndPoint, {
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: data
    })
    return result
}

export default FHIRPoster
