import { Loader, Message, Table, Icon, SemanticICONS, SemanticCOLORS, Tab } from "semantic-ui-react";
import useSWR from "swr";
import React from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useContext } from "react";
import FHIRFetcher from "../../../data/FHIRFetcher";
import moment from "moment";
import Zendesk from "./zendesk";
import Carebetty from "./carebetty";

function Communications(props: any) {

    let panes = [
      {
        menuItem: {
          key: "carebetty",
          color: "blue",
          content: "EMAIL & SMS",
          name: "CAREBETTY",
        },
        pane: {
          key: "carebetty",
          content: (
            <Carebetty patient = {props.patient}/>
          ),
        },
      },
      {
        menuItem: {
          key: "zendesk",
          color: "blue",
          content: "CALLS",
          name: "ZENDESK",
        },
        pane: {
          key: "zendesk",
          content: (
              <Zendesk patient = {props.patient}/>
          ),
        },
      }]
    return (
        // <Tab 
        // menu={{ secondary: true, pointing: true }}
        // renderActiveOnly={false}
        // panes = {panes}/>
        // // <>
        <Carebetty patient = {props.patient}/>
        //   {/* <Zendesk patient = {props.patient}/> */}
        //</>
    )
}

export default Communications;



