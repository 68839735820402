import { useState } from "react";
import { Popup, Segment, Menu, Dropdown } from "semantic-ui-react";
import PatientOrders from "../../common/food/orders";

function Food(props: any) {
    
    return (
        <div>
                <PatientOrders patient={props.patient} patientId={props.patientId}  />   
        </div >
    )
};

export default Food;



