import moment from "moment";

export function weekDayCode (weekDay) {
    switch(weekDay){
        case "SUNDAY":
            return 0;
        case "MONDAY":
            return 1;
        case "TUESDAY":
            return 2;
        case "WEDNESDAY":
            return 3;
        case "THURSDAY":
            return 4;
        case "FRIDAY":
            return 5;
        case "SATURDAY":
            return 6;
    }
   
}
export function nextWeekDay (day, dayINeed) {
    if(dayINeed!==""){
        const weekDay = moment(day).isoWeekday();

        if (weekDay === dayINeed) { 
            return moment(day).isoWeekday(dayINeed);
        }else if (weekDay < dayINeed) { 
            return moment(day).add(dayINeed-weekDay).isoWeekday(dayINeed);
        }
         else {
            return moment(day).add(1, 'weeks').isoWeekday(dayINeed);
        }
    }
}

export function formatPhoneNumber (phoneNumberString) {
    try{
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  }catch(e){
    return phoneNumberString;
  }
}
