import axios, { AxiosResponse } from "axios";
import { FhirResource } from "fhir/r4";

class FHIRProxyClient {
  accessToken: string;
  apiURL: string;

  constructor(accessToken: string, apiURL: string) {
    this.accessToken = accessToken;
    this.apiURL = apiURL;
  }

  async send(requestType: string, endPoint: string, data?: FhirResource) {
    let requestHeaders = {
      Authorization: `Bearer ${this.accessToken}`,
      "Content-Type": "application/json",
    };

    let requestURL = this.apiURL + endPoint;
    let response: AxiosResponse = <AxiosResponse>{};
    switch (requestType) {
      case "get":
        response = await axios.get(requestURL, { headers: requestHeaders });
        break;
      case "delete":
        response = await axios.delete(requestURL, { headers: requestHeaders });
        break;
      case "post":
        response = await axios.post(requestURL, data, {
          headers: requestHeaders,
        });
        break;
      case "put":
        response = await axios.put(requestURL, data, {
          headers: requestHeaders,
        });
        break;
      case "patch":
        response = await axios.patch(requestURL, data, {
          headers: requestHeaders,
        });
        break;
      default:
        break;
    }

    return response;
  }
}

export default FHIRProxyClient;
