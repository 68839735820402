import { Form, Icon, Label, Loader, Popup } from "semantic-ui-react";
import * as FHIRUtils from "../../../utils/FHIRUtils";
import moment from "moment";
import React, { useContext } from "react";
//import FHIRPoster from "data/FHIRPoster";
import { ObservationBuilder } from "fhir-sdoh";
import AuthContext from "contexts/AuthContext";
//import FHIRDelete from "data/FHIRDelete";

function Vitals(props: any) {
    let authInfo: any = useContext(AuthContext);

    const [loading, setLoading] = React.useState(!props.vitalsData ? "Loading Vitals Data..." : "")

    const [popOpen, setpopOpen] = React.useState(false);
    const handlePopOpen = () => {
        setpopOpen(true);
    };
    const handleClose = () => {
        setpopOpen(false);
    };

    /* const handleDelete = (observationId) => {
        setLoading("Deleting Vital Label...");
        setpopOpen(false);
        FHIRDelete(
            `/Observation/${observationId}`,
            authInfo.token
        ).then(() => {
            setLoading("Deletion Success. Updating Vital UI...");
            props.mutate().then(() => {
                setLoading("");
            });
        },
            (error) => {
                setLoading(`Error - Unable to delete Vital label: ${error.message}`);
            });
    } */

    /* const handleSubmit = (e) => {
        e.preventDefault();
        setpopOpen(false);

        var value = e.target.elements.newobs.value;
        if (!value || value === "") {
            return false;
        }
        setLoading("Submitting Vital Label...");

        let body: fhir4.Observation = ObservationBuilder.buildVitalsObservation(
            props.patientId,
            new Date().toJSON(),
            "55284-4",
            "Blood pressure systolic and diastolic",
            value,
            authInfo.practitionerName
        );

        FHIRPoster(`/Observation/`, authInfo.token, body).then(
            () => {
                setLoading("Submission Success. Updating Vital UI...");
                props.mutate().then(() => {
                    setLoading("");
                });
            },
            (error) => {
                setLoading(`Error - Unable to submit Vital label: ${error.message}`);
            }
        );
    } */

    let fhirVitals = props.vitalsData;
    let flatVitalsUnsorted = [];

    if (fhirVitals) {
        flatVitalsUnsorted = fhirVitals.map((fhirVital) => {
            return FHIRUtils.parseFHIRVital(fhirVital.resource);
        });
        let flatVitals = []
        if (flatVitalsUnsorted) {
            flatVitals = flatVitalsUnsorted.sort((c1, c2) => {
                return moment(c2.effectiveDate, "YYYY-MM-DD").valueOf() - moment(c1.effectiveDate, "YYYY-MM-DD").valueOf()
            })
        }

        return (
            <>
                {loading.length > 0 && <Loader active inline="centered" content={loading} />}
                {flatVitals.map((flatVital, i) => {
                    let effectiveDate = moment(flatVital.effectiveDate, "YYYY-MM-DD").format("MMMM Do, YYYY");
                    return (
                        <Label
                            key={flatVital.id}
                            style={{ margin: "0.15em" }}
                        //color={"teal"}
                        >
                            <div style={{ display: "flex" }}>
                                <div>
                                    <a
                                        href={`https://loinc.org/${flatVital.code}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {flatVital.display}
                                    </a>
                                    {": "}
                                    {flatVital.valueString?.length > 0 &&
                                        <b>
                                            {flatVital.valueString}
                                        </b>
                                    }
                                    {flatVital.quantity &&
                                        <b>
                                            {flatVital.quantity}{' '} {flatVital.unit}
                                        </b>
                                    }
                                    {flatVital.valueCode &&
                                        <a href={`https://icdlist.com/?t=icd10&s=${flatVital.valueCode}`} target='_blank' rel="noopener noreferrer">
                                            {flatVital.valueCode}
                                        </a>
                                    }

                                    <div>
                                        {effectiveDate}
                                    </div>
                                </div>

                                {/* {!props.sidebar &&
                                    <>
                                        <div style={{ width: 10 }} />
                                        <Icon
                                            style={{ float: "right" }}
                                            size="large"
                                            name="delete"
                                            onClick={() => handleDelete(flatVital.id)}
                                        />
                                    </>
                                } */}

                            </div>
                        </Label>
                    );
                })}

                {/* {!props.sidebar && (
                    <Popup
                        trigger={
                            <Popup
                                trigger={<Label as="a">+</Label>}
                                content={
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group>
                                            <Form.Input
                                                label="Create Blood Pressure Vital"
                                                placeholder="Enter Value"
                                                name="newobs"
                                            />
                                            <Form.Button primary content="Add" />
                                        </Form.Group>
                                    </Form>
                                }
                                on="click"
                                position="top right"
                                open={popOpen}
                                onOpen={handlePopOpen}
                                onClose={handleClose}
                            />
                        }
                        content={
                            <Label as="a">
                                Create New Blood Pressure Vital
                            </Label>
                        }
                        on="hover"
                        position="bottom right"
                    />
                )} */}
            </>
        );
    }
    else {
        return <></>
    }
}

export default Vitals;
