import String from './types/string';
import Integer from './types/integer';
import Choice from './types/choice';
import OpenChoice from './types/openchoice';
import Decimal from './types/decimal';
import DateItem from './types/date';

export default function QuestionnaireItem(props) {
  // props: question, addAnswer, i, j, currentAnswer, enable
  switch (props.question.type) {
    case 'display':
      return <></>;
    case 'string':
      return (
        <String
          addAnswer={props.addAnswer}
          i={props.i}
          j={props.j}
          currentAnswer={props.currentAnswer}
          enable={props.enable}
        />
      );
    case 'text':
      return (
        <String
          addAnswer={props.addAnswer}
          i={props.i}
          j={props.j}
          currentAnswer={props.currentAnswer}
          enable={props.enable}
          question={props.question}
        />
      );
    case 'integer':
      return (
        <Integer
          addAnswer={props.addAnswer}
          i={props.i}
          j={props.j}
          currentAnswer={props.currentAnswer}
          enable={props.enable}
        />
      );
    case 'decimal':
      return (
        <Decimal
          addAnswer={props.addAnswer}
          i={props.i}
          j={props.j}
          currentAnswer={props.currentAnswer}
          enable={props.enable}
        />
      );
    case 'date':
      return (
        <DateItem
          addAnswer={props.addAnswer}
          i={props.i}
          j={props.j}
          currentAnswer={props.currentAnswer}
          enable={props.enable}
        />
      );
    case 'choice':
      return (
        <Choice
          question={props.question}
          addAnswer={props.addAnswer}
          i={props.i}
          j={props.j}
          currentAnswer={props.currentAnswer}
          enable={props.enable}
          language={props.language}
        />
      );
    case 'open-choice':
      return (
        <OpenChoice
          question={props.question}
          addAnswer={props.addAnswer}
          i={props.i}
          j={props.j}
          currentAnswer={props.currentAnswer}
          enable={props.enable}
          language={props.language}
        />
      );
    default:
      return <></>;
  }
}
