import { Loader, Message, Tab, Grid, Segment,Icon } from "semantic-ui-react";
import React, { useState } from "react";
import Members from "./members";
import Patient360 from "components/patient";
import Dashboard from "components/patient/dashboard";

function Home(props: any) {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState([]);
    const [selectedPatient, setSelectedPatient] = React.useState(null);
    const [showDashBoard, setShowDashBoard] = React.useState(true);

    //Opens Slected Patient in a new Chrome Tab
    // const showNewPatient = (selectedPatient) => {
    //     window.open(`/patients/${selectedPatient.id}`, selectedPatient.id);
    // }

    React.useEffect(() => {
        handleShowDashboard()
    }, [props.org])

    const handleShowNewPatient = (selectedPatient) => {
        setSelectedPatient(selectedPatient)
        setShowDashBoard(false)
    }
    const handleShowDashboard = () => {
        setSelectedPatient(null)
        setShowDashBoard(true)
    }

    return (
        <>
            {loading && (
                <Loader active inline="centered" content={"Fetching Member Data..."} />
            )}
            {error && error.length > 0 && (
                <Message error header="Error" list={error} />
            )}
            {(  
                <Grid cols={2} padded="horizontally" centered>
                    <Grid.Row >
                        <Grid.Column width="4">
                            <Members onPatientSelected={handleShowNewPatient} onShowDashboard={handleShowDashboard} personId={props.personId} />
                        </Grid.Column>
                        <Grid.Column width="12" >
                            <Segment>
                                {showDashBoard && (
                                    <Dashboard patient={selectedPatient} org={props.org}/>
                                )}
                                {selectedPatient && (
                                    <Patient360 patient={selectedPatient} personId={props.personId} />
                                )}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            )}
        </>
    )
};

export default Home;
