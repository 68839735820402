import { Provenance, Bundle, Resource } from 'fhir/r4';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import * as FHIRUtils from '../fhirutils';

export function build(
  targetRef: string,
  actorRef: { reference: string; display: string },
  patientId: string,
  activity: string,
  reasonDesc: string,
  orgRef: { code: string; display: string } = { code: 'HUMANA', display: 'humana' },
  piiSurveyQRID: string | undefined = undefined
) {
  let date = new Date();
  let occured = moment(date).format('YYYY-MM-DD');
  let recorded = date.toISOString();
  let provenance: Provenance = buildProvenance(
    targetRef,
    actorRef,
    orgRef,
    `Patient/${patientId}`,
    occured,
    recorded,
    activity,
    activity.toLowerCase(),
    'LG41764-8',
    reasonDesc
  );
  if (piiSurveyQRID) {
    provenance.extension = [
      {
        url: 'https://projectwell.io/fhir/questionnaire-response',
        valueString: piiSurveyQRID,
      },
    ];
  }
  return provenance;
}

export function buildProvenanceBundle(fhirResources: Array<Resource>, provenancce: Provenance) {
  let fhirBundle: Bundle = FHIRUtils.buildEmptyBundle('transaction');
  let bundleEntries: any = [];
  fhirResources.forEach((fhirResource: Resource) => {
    bundleEntries.push(FHIRUtils.buildResourceEntry(fhirResource, false));
  });

  bundleEntries.push(FHIRUtils.buildResourceEntry(provenancce, false)); //Provenance

  fhirBundle.entry = bundleEntries;

  return fhirBundle;
}

export function buildProvenance(
  targetRef: string, //eg:  Observation/dffdgdg-2343-35554-34r5435
  who: { reference: string; display: string }, //eg. {Practitioner/oa2,johnsmith}
  org: { code: string; display: string }, //eg. {Organization/HUMANA,humana}
  subjectRef: string, //eg. Patient/1212
  occuredDate: string,
  recordedDate: string,
  activityCode: string, //CREATE, UPDATE, DELETE
  activityDesc: string,
  reasonCode: string, //LG41764-8: Food Insecurity
  reasonDesc: string //Screening
): Provenance {
  let provenanceId = uuid();
  let provenancePayload: Provenance = {
    resourceType: 'Provenance',
    id: provenanceId,
    target: [
      {
        reference: targetRef,
      },
    ],
    agent: [
      {
        // Updated By(Practitioner Id)
        who: {
          reference: who.reference,
          display: who.display,
        },
      },
      {
        // For Organization Id
        who: {
          reference: 'Organization/' + org.code,
          display: org.display,
        },
      },
    ],
    occurredDateTime: occuredDate,
    recorded: recordedDate,
    activity: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-DataOperation',
          code: activityCode,
          display: activityDesc,
        },
      ],
    },
    reason: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: reasonCode,
            display: reasonDesc,
          },
        ],
      },
    ],
  };
  if (targetRef !== subjectRef) {
    provenancePayload.target.push({
      reference: subjectRef,
    });
  }
  return provenancePayload;
}
