import Amplify from "aws-amplify";
import { Auth, Hub } from "aws-amplify";
import { Button, Popup } from "semantic-ui-react";
import React from "react";
import { useParams } from 'react-router-dom';
import {buildAWSConfig}  from "../../aws-config";
const DEFAULT = "default"

function Login(props: any) {

  let { org } = useParams();
  //console.log(org)
  if(!org || org == null || org == undefined || org == "undefined" || org == "null") {
    org = DEFAULT
  }
  Amplify.configure(buildAWSConfig(org));

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState<string | null>(null);
  const [personId, setPersonId] = React.useState<string | null>(null);

  // login if logged out, or log out if logged in
  const handleClick = async () => {
    loggedIn ? Auth.signOut() : Auth.federatedSignIn();
  };

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        let accessToken = data.signInUserSession.getAccessToken().getJwtToken();
        let authPaylaod = data.signInUserSession.getIdToken().payload
        let userEmail = authPaylaod.email; //you can also get userName and role
        let personId = authPaylaod["custom:personId"];
        let organizationId = authPaylaod["custom:organizationId"];
        let role = authPaylaod["custom:role"];
        setLoggedIn(true);
        setUserEmail(userEmail)
        setPersonId(personId);
        props.onLoggedIn(accessToken, userEmail, personId,organizationId,role);
        break;
      case "signOut":
        setLoggedIn(false);
        break;
      case "signIn_failure":
        setLoggedIn(false);
        break;
      default:
        break;
    }
  });

  //let buttonColor: string = loggedIn ? 'red' : 'blue'
  let label = loggedIn ? "Logout" : "Login";
  return (
    <Popup flowing
      trigger={
        <div>
          {loggedIn &&
            <Button color="red" onClick={handleClick}>
              {" "}
              {label}
            </Button>
          }
          {loggedIn && !personId &&
            <h5>No Person Id is associated to the Logged in User</h5>
          }
          {!loggedIn && (
            <Button color="blue" onClick={handleClick}>
              {" "}
              {label}
            </Button>
          )}
        </div>
      }
    >
      <Popup.Content>
        {userEmail ? userEmail : "Click here to Login"}
      </Popup.Content>
    </ Popup>
  );
}

export default Login;