import usaStates from "../data/States";
import jsonXML from "jsontoxml";
import { parse } from "fast-xml-parser";
import { API } from "aws-amplify";
import * as FHIRUtils from "../utils/FHIRUtils"
import moment from "moment";

//const usps_API_Url = process.env.USPS_API; // Somwhow this is not working. To Do
const usps_API_Url = "https://production.shippingapis.com/ShippingApi.dll?API=Verify&XML="
const maleImagesCount = 11
const femaleImagesCopunt = 12
const imageNames = {
    "male": ["chris.jpg", "christian.jpg", "daniel.jpg", "elliot.jpg", "joe.jpg", "justen.jpg", "matt.jpg", "steve.jpg", "mark.png", "matthew.png", "patrick.png"],
    "female": ["helen.jpg", "jenny.jpg", "laura.jpg", "nan.jpg", "stevie.jpg", "veronika.jpg", "elyse.png", "kristy.png", "lena.png", "lindsay.png", "molly.png", "rachel.png"]
}
export function getImage(
    male: boolean
): string {

    if (male) {
        let index = Math.floor(Math.random() * maleImagesCount);
        return imageNames.male[index]
    }
    else {
        let index = Math.floor(Math.random() * femaleImagesCopunt);
        return imageNames.female[index]
    }
}

export function validateUSPSAddress(addressLine1: string, addressLine2: string, city: string, state: string, zip: string) {
    return new Promise((resolve, reject) => {
        let addressJson = {
            "AddressValidateRequest": [
                { "name": "Revision", "text": "1" },
                {
                    "name": "Address", "children": [
                        { "name": "Address1", "text": addressLine1 },
                        { "name": "Address2", "text": addressLine2 },
                        { "name": "City", "text": city },
                        { "name": "State", "text": state },
                        { "name": "Zip5", "text": zip },
                        { "name": "Zip4", "text": "" }
                    ], "attrs": { "ID": 0 }
                }
            ]
        }

        let validationErrors = [];
        let addressXML = jsonXML(addressJson);

        addressXML = addressXML.replace('<AddressValidateRequest>', '<AddressValidateRequest USERID="845PROJE3269">');

        //fetch selected key for the State selected on the form
        let selectedState = { key: "" };
        usaStates.list.forEach(stateObj => {
            if (stateObj.value === state || stateObj.key === state) {
                selectedState.key = stateObj.key;
            }
        })


        fetch(usps_API_Url + addressXML)
            .then((response) => response.text())
            .then((textResponse) => {
                let jsonObject = parse(textResponse);
                let addressResponse = jsonObject.AddressValidateResponse.Address;
                if (addressResponse && (addressResponse.Error !== undefined)) {
                    validationErrors.push(addressResponse.Error.Description);
                }
                else if (addressResponse.Zip5.toString() !== zip && zip.indexOf("0")!==0) {
                    validationErrors.push("Invalid Zip Code, Suggested Zip Code is: " + addressResponse.Zip5);
                }
                else if (!zip.includes(addressResponse.Zip5) && zip.indexOf("0")===0) {
                    validationErrors.push("Invalid Zip Code, Suggested Zip Code is: " + addressResponse.Zip5);
                }
                else if (addressResponse.City.toUpperCase() !== city.toUpperCase()) {
                    validationErrors.push("Invalid City, Suggested City name is: " + addressResponse.City);
                }
                else {
                    if (selectedState.key !== "" && selectedState.key !== addressResponse.State) {
                        let suggestedState = "";
                        usaStates.list.forEach(stateObj => {
                            if (stateObj.key === addressResponse.State) {
                                suggestedState = stateObj.value;
                            }
                        })
                        validationErrors.push("Invalid State, Suggested State is: " + suggestedState);
                    } else if (addressResponse.ReturnText !== undefined && addressResponse.ReturnText !== "") {
                        validationErrors.push(addressResponse.ReturnText);
                    }
                    else {
                        resolve(true);
                    }
                }
                if (validationErrors.length) {
                    reject(validationErrors);
                }
            })
            .catch((error) => {
                console.log(error);
                validationErrors.push("Unable to validate address")
                reject(validationErrors);
            })
    })
}

export function validatePatientAddress(patientId: string, org: string, authInfoToken: string) {
    return new Promise((resolve, reject) => {
        FHIRFetcherOnDemand(
            `/Patient/?organization=${org}&_id=${patientId}`,
            authInfoToken
        ).then(
            (data) => {
                if (data && data.entry) {
                    
                    let patient = FHIRUtils.parseFHIRPatient(data.entry[0].resource);
                    validateUSPSAddress(patient.delivery_addressLine1[0], patient.delivery_addressLine2[0], patient.delivery_city[0], patient.delivery_state[0], patient.delivery_zip[0]).then(success => {
                        resolve(true);
                    }, errors => {
                        reject(false);
                    })
                } else{
                    reject(false);
                } 
            },
            (error) => {
                reject(false);
            }
        );
    })
}

//Helper function designated to assist navigating Questionnaire Response fields - JT
export class QuestionnaireResponseHelper {
    response: fhir4.QuestionnaireResponse | undefined;
    constructor(jsonResponse: fhir4.QuestionnaireResponse | undefined) {
        this.response = jsonResponse;
    }
    isEmpty(): boolean {
        return (this.response?.item?.length ?? 0) <= 0;
    }
    getQResponse(): fhir4.QuestionnaireResponse | undefined {
        return this.response;
    }
    getAuthoredDate(): string | undefined {
        return this.response?.authored;
    }
    getId(): string | undefined {
        return this.response?.id;
    }
    getItemList(): any[] | undefined {
        return this.response?.item; //this.response.item
    }
    getItemListLength(): number | undefined {
        return this.getItemList().length;
    }
    getGroup(index: number): any | undefined {
        return this.getItemList()[index]; //this.response.item[index]
    }
    getGroupItem(index: number): any[] | undefined {
        return this.getGroup(index)?.item; //this.response.item[index].item
    }
    getGroupLength(index: number): number | undefined {
        return this.getGroupItem(index).length; //this.response.item[index].item.length
    }
    getGroupLinkId(index: number): string | undefined {
        return this.getGroup(index)?.linkId;
    }
    getAnswerItem(indexGroup: number, indexAnswer: number): any | undefined {
        return this.getGroupItem(indexGroup)[indexAnswer]; //this.response.item[indexGroup].item[indexAnswer]
    }
    getAnswerResponse(indexGroup: number, indexAnswer: number): any | undefined {
        return this.getAnswerItem(indexGroup, indexAnswer)?.answer;
    }
    getAnswerLinkId(indexGroup: number, indexAnswer): string | undefined {
        return this.getAnswerItem(indexGroup, indexAnswer)?.linkId;
    }
    getExtension(): any[] | undefined {
        return this.response?.extension;
    }
    getQuestionnaire(): string | undefined {
        return this.response?.questionnaire;
    }
    getQuestionnaireID(): string | undefined {
        return this.getQuestionnaire().split("/")[1];
    }
    getStatus(): string | undefined {
        return this.response?.status;
    }
}
export class QuestionnaireHelper {
    survey: fhir4.Questionnaire | undefined
    constructor(jsonQuestionnaire: fhir4.Questionnaire | undefined) {
        this.survey = jsonQuestionnaire;
    }
    isEmpty(): boolean {
        return (this.survey?.item?.length ?? 0) <= 0;
    }
    getTranslation(lang: string, ext: []) {

    }
    getQuestionnaire(): fhir4.Questionnaire | undefined {
        return this.survey;
    }
    getId(): string | undefined {
        return this.survey?.id;
    }
    getItemList(): any[] | undefined {
        return this.survey?.item; //this.survey.item
    }
    getItemListLength(): number | undefined {
        return this.getItemList().length;
    }
    getGroup(index: number): any | undefined {
        return this.getItemList()[index]; //this.survey.item[index]
    }
    getGroupItem(index: number): any[] | undefined {
        return this.getGroup(index)?.item; //this.survey.item[index].item
    }
    getGroupLength(index: number): number | undefined {
        return this.getGroupItem(index).length; //this.survey.item[index].item.length
    }
    getGroupLinkId(index: number): string | undefined {
        return this.getGroup(index)?.linkId;
    }
    getQuestionItem(indexGroup: number, indexAnswer: number): any | undefined {
        return this.getGroupItem(indexGroup)[indexAnswer]; //this.survey.item[indexGroup].item[indexAnswer]
    }
    getQuestionLinkId(indexGroup: number, indexAnswer:number): string | undefined {
        return this.getQuestionItem(indexGroup, indexAnswer)?.linkId;
    }
    getSurveyExtension(): any[] | undefined {
        return this.survey?.extension;
    }
    getOneSurveyExtension(url: string): object | undefined {
        let match;
        let extensions = this.survey?.extension;
        extensions?.forEach((e) => {
          if (e.url == url) {
            match = e;
          }
        });
        return match;
      }
    getQuestionExtension(indexGroup: number, indexAnswer:number): any[] | undefined {
        return this.getQuestionItem(indexGroup, indexAnswer)?.extension;
    }
    getTitle(): string | undefined {
        return this.survey?.title;
    }
};
//Helper function designated to assist navigating Questionnaire Extension fields - written by JB
export class QuestionnaireExtensionHelper {
    ext: any[] | undefined;
    constructor(jsonExtensionArray: any[] | undefined) {
        this.ext = jsonExtensionArray;
    }

    isEmpty(): boolean {
        return (this.ext?.length ?? 0) <= 0;
    }
    getExtension(): any[] | undefined {
        return this.ext;
    }
    isResourceCreation(): boolean {
        return this.getResourceCreationType() != undefined;
    }
    isRadioButton(): boolean {
        return this.getItemControlCode() === "radio-button";
    }
    isSlider(): boolean {
        return this.getItemControlCode() === 'slider';
      }
    isCheckbox():boolean{
        return this.getItemControlCode() === "check-box";
    }
    getItemControlCode():string | undefined {
        return this.ext?.find((e) => e.url === "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl")?.valueCodeableConcept?.coding[0]?.code;
    }
    getResourceCreationType(): string | undefined {
        return this.ext?.find((e) => e.url === "nrx://resource-creation")?.valueString;
    }

    getCompletedDate(): string | undefined {
        return this.ext?.find((e) => e.url === "https://projectwell.io/fhir/extensions/completed-date")?.valueDateTime;
    }
    getUpdateDate(): string | undefined {
        return this.ext?.find((e) => e.url === 'https://projectwell.io/fhir/extensions/updated-date')?.valueDateTime;
      }
    /** 
     * This prefix can optionally be used to add a static prefix to a questionnaire response
     * For example for the answer "Wheat" we may want to prefix it like this "Life Threatening Allergy : Wheat"    
    */
    getObservationCreationPrefix() {
        return this.ext?.find((e) => e.url === "nrx://observation-creation")?.valueString;
    }

    /**
     * If the answer is a coding pass in the code, otherwise pass in the value.
     * @param resourceValueOrCode 
     * @returns 
     */
    areConditionsMet(resourceValueOrCode: any): boolean {
        let maxRangeValue = this.getConditionRangeMaxValue();
        let minRangeValue = this.getConditionRangeMinValue();
        let equalsValue = this.getConditionEqualsValue();
        return (
            // If the extension object is not empty and there are no conditions
            // we'll consider that the conditions have been met.
            !this.isEmpty() && !maxRangeValue && !minRangeValue && !equalsValue) ||
            // Max value inclusive
            (maxRangeValue != undefined && parseInt(resourceValueOrCode) <= parseInt(maxRangeValue)) ||
            // Min value inclusive
            (minRangeValue != undefined && parseInt(resourceValueOrCode) >= parseInt(minRangeValue)) ||
            // equals value
            (equalsValue != undefined && equalsValue?.toLowerCase() === resourceValueOrCode?.toLowerCase());
    }

    getConditionEqualsValue(): string | undefined {
        return this.ext?.find((e) => e.url === "nrx://value-equals")?.valueString;
    }

    getConditionRangeMaxValue(): string | undefined {
        return this.ext?.find((e) => e.url === "nrx://range-max")?.valueString;
    }

    getConditionRangeMinValue(): string | undefined {
        return this.ext?.find((e) => e.url === "nrx://range-min")?.valueString;
    }

    getUnits(): string | undefined {
        return this.ext?.find((e) => e.url === "nrx://units-of-measure")?.valueString;
    }

    getPolicyUris(): string[] | undefined {
        let policyUris: string[] = [];

        this.ext?.forEach(element => {
            if (element.url.toString() === "nrx://consent-policy-uri") {
                let value = element.valueString;
                if (value) {
                    policyUris.push(value);
                }
            }
        });

        return policyUris.length > 0 ? policyUris : undefined;
    }

    getTaskCategory(): string | undefined {
        return this.ext?.find((e) => e.url === "nrx://task-category")?.valueString;
    }
    //might need future refactoring for multiple translations. only returns first one found
    getTranslations(): any[] | undefined {
        return this.ext?.find((e) => e.url === "http://hl7.org/fhir/StructureDefinition/translation")?.extension
    }
    hasTranslation(): boolean {
        return this.getTranslations() != undefined;
    }
    hasTargetTranslation(langCode: string): boolean {
        return this.getTargetTranslation(langCode) !== undefined;
      }
    getTargetTranslation(langCode:string): string | undefined {
        let e = this.ext;
    let value: string | undefined = undefined;
    if (e) {
      for (let i: number = 0; i < e?.length; i++) {
        if (e[i].url === 'http://hl7.org/fhir/StructureDefinition/translation') {
          let translation = e[i];
          if (translation.extension[0].valueCode === langCode) {
            value = translation.extension[1].valueString;
          }
        }
      }
    }

    return value;
    }
    getInternalTitle(): string | undefined {
        return this.ext?.find((e) => e.url === 'https://projectwell.io/fhir/internal-title')?.valueString;
      }
};

const FHIRFetcherOnDemand = async (endPoint: string, token: string) => {
    let dataEndPoint = "/fhir" + endPoint;

    const data = await API.get("fhir-proxy", dataEndPoint, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  };

