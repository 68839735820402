
import React, { useState, useEffect, useContext } from "react";
import useSWR from "swr";
import FHIRFetcher from "../../../data/FHIRFetcher";
import { Patient } from "fhir/r4";
import { Segment, Input, Icon, Popup, Button, Modal, List, Item, Label } from "semantic-ui-react";
import MembersList from "./list/allmembers"
import AuthContext from "contexts/AuthContext";
import EditPatient from "../../patient/edit";
import PatientCDO from "../../../models/patient";
import Amplify, { API } from "aws-amplify";
import {buildAWSConfig} from "../../../aws-config";
import { useParams } from 'react-router-dom';
const DEFAULT = "default"

function Members(props: any) {

    let { org } = useParams();
    if(!org || org == null || org == undefined || org == "undefined" || org == "null") {
        org = DEFAULT
    }
    Amplify.configure(buildAWSConfig(org));

    const authInfo: any = useContext(AuthContext);
    

    const [openCreate, setOpenCreate] = useState(false)
    const [searchString, setSearchString] = React.useState<string | null>('');
    const [notification, setNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState("")
    const [refreshToggle, setRefreshToggle] = useState(false)
    const [downloadMsg, setDownloadMsg] = useState<string|null>(null)

    const onSearchInputChange = (e) => {
        setSearchString(e.target.value)
    };

    const handlePatientSelect = (selectedPatient: Patient) => {
        props.onPatientSelected(selectedPatient)
    };

    const handleDownload = async () => {
        setDownloadMsg("Downloading member data...")
        try {
                let endPoint = "/download/report-data/program-data"   //TODO: externalize this when we have more types
                const response = await API.get(process.env.REACT_APP_API_NAME, endPoint, {
                    headers: {
                        Authorization: "Bearer " + authInfo.token
                    }
                });
                // Decode base64
                const byteCharacters = atob(response.body);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: response.headers["Content-Type"]});
                const contentDisposition = response.headers['Content-Disposition'];
                // Extract filename from Content-Disposition
                const filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
                // Download logic
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                setDownloadMsg("Download Success! Please check downloads folder for file: " + filename)
        } catch (error) {
                console.error('Download error:', error);
                setDownloadMsg("Error - could not download file: " + error)
        }
    };

    // For Update Patient Details modal window
    let toggleModal = (flag, fhirPatient) => {
        setOpenCreate(flag);
        setNotificationMsg("New profile has been added successfully...")

        if (fhirPatient !== undefined && fhirPatient) {
            setNotification(true)
        }
    }

    const handlNewPatient = () => {
        setRefreshToggle(!refreshToggle)
    };

    return (
        <>
            <Segment /* style={{height: "950px", overflow: 'auto'}} */>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <h4>
                        <Icon name='users' size="large" color="orange" />
                        <b>&nbsp;MEMBERS</b>
                    </h4>
                    <div>
                        <Button content='Dashboard' icon='chart line' color='blue' size='mini' style={{ marginBottom: '20px' }} onClick={props.onShowDashboard} />
                        <Button icon='download' color='green' size='mini' style={{ marginBottom: '20px' }} onClick={handleDownload} />
                    </div>
                </div>
                <Input action={{ icon: 'search' }} fluid value={searchString} icon={<Icon size="large" name='search' color="orange" link />}
                    onChange={onSearchInputChange} placeholder='Search by Name or Member Id '>
                    <input />&nbsp; &nbsp; &nbsp;
                    <Popup content='Add New Member' trigger={<Button color='green' onClick={() => { setOpenCreate(true); }}>Add New</Button>} />
                </Input>
                <MembersList payer={authInfo.organizationId} searchString={searchString.trim()} onPatientSelect={handlePatientSelect} refresh={refreshToggle} />
            </Segment>
            {openCreate && <EditPatient patientId="" patient={PatientCDO} organization={authInfo.organizationId} operation={"Add"} open={openCreate}
                userEmail={authInfo.userEmail} toggleModal={toggleModal} careteamStatus="UN-ASSIGNED" onNewPatient={handlNewPatient} personId={props.personId} />
            }
            {
                notification &&
                <Modal
                    open={notification}
                    size='mini'
                    content={notificationMsg}
                    onActionClick={() => (setNotification(false))}
                    actions={[{ key: 'done', icon: 'check', content: 'OK', positive: true }]}
                >
                </Modal>
            }
            {
                downloadMsg &&
                <Modal
                    open={downloadMsg!==null}
                    size='mini'
                    content={downloadMsg}
                    onClose={()=>setDownloadMsg(null)}
                />
            }
        </>
    )
};

export default Members;



