import { Input } from 'semantic-ui-react';

export default function Integer(props) {
  //props: addAnswer, i, j, currentAnswer, enable

  const handleInput = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      props.addAnswer(null, props.i, props.j);
    } else {
      props.addAnswer([{ valueInteger: parseInt(e.target.value) }], props.i, props.j);
    }
  };

  return !props.enable ? (
    <Input value="" disabled fluid placeholder="Enter Answer Here" />
  ) : props.currentAnswer ? (
    <Input
      defaultValue={props.currentAnswer[0].valueInteger}
      fluid
      placeholder="Enter Answer Here"
      onChange={handleInput}
    />
  ) : (
    <Input fluid placeholder="Enter Answer Here" onChange={handleInput} />
  );
}
