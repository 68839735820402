import React from "react";
import FHIRFetcher from "../../../data/FHIRFetcher";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";
import Patient360 from "..";
import { AuthContextProvider } from "../../../contexts/AuthContext";
import {
    Image,
    Menu,
} from "semantic-ui-react";

function PatientTab(props: any) {
    let hostName = window.location.hostname
    const [loading, setLoading] = React.useState(true);
    const authToken = localStorage.getItem(`${hostName}-authToken`)
    const userEmail = localStorage.getItem(`${hostName}-userEmail`)
    const practitionerId = localStorage.getItem(`${hostName}-practitionerId`)
    const practitionerName = localStorage.getItem(`${hostName}-practitionerName`)

    //Open CareBetty Home
    const homeClicked = () => {
        console.log("Home: " + window.location.host)
        window.open(`${window.location.host}/`, '_blank');
    }

    const options = {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnReconnect: false,
        onSuccess: (data, key, config) => {
            setLoading(false);
        },
        onError: (err, key, config) => {
            console.log(err)
            setLoading(false);
        }
    };

    let params = useParams();
    let patientId = params.patientId
    //console.log("PATIENT ID: " + patientId)

    const { data: patientData, error: patientError } = useSWR(
        [`/Patient/${patientId}`, authToken],
        FHIRFetcher,
        options
    );

    return (
        <div id="root" className='topbar'>
            <Menu size="small" inverted>
                <Menu.Item key='image'>
                    <Image src="/images/nrx.jpg" />
                </Menu.Item>

                <Menu.Item link={true} key='title' name="CARE BETTY" onClick={homeClicked} />

            </Menu>

            {loading && <Loader active inline="centered" />}
            <>
                {patientError && (
                    <Message negative>
                        <Message.Header>Oops!. Something went wrong.. </Message.Header>
                        <p>{patientError.message}</p>
                    </Message>
                )}
                {patientData &&
                    <AuthContextProvider
                        value={{
                            token: authToken,
                            userEmail: userEmail,
                            practitionerId: practitionerId,
                            practitionerName: practitionerName,
                        }}
                    >
                        <Patient360 patient={patientData} />
                    </AuthContextProvider>
                }
            </>
        </div >
    );
}

export default PatientTab;
