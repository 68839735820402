import { Form, Icon, Label, Loader, Popup, Select } from 'semantic-ui-react';
import * as FHIRUtils from '../../../utils/FHIRUtils';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
//import FHIRPoster from 'data/FHIRPoster';
import { ObservationBuilder } from 'fhir-sdoh';
import AuthContext from 'contexts/AuthContext';
//import FHIRDelete from 'data/FHIRDelete';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
//props maternityData patientId sidebar mutate
function MaternityHealth(props: any) {
  let authInfo: any = useContext(AuthContext);

  const [loading, setLoading] = React.useState(!props.maternityData ? 'Loading Meternity Data...' : '');
  const [observationOption, setObservationOption]: any = React.useState();
  const [observationDate, setObservationDate] = React.useState('');
  const [freeText, setFreeText] = React.useState('');
  const [popOpen, setpopOpen] = React.useState(false);
  const [highlightID, setHighlightID] = React.useState<any[]>([]);

  const MATERNAL_CONDITION_CODES = [
    {
      id: 'expected-due-date',
      text: 'EXPECTED_DUE_DATE',
    },
    {
      id: 'actual-due-date',
      text: 'ACTUAL_BIRTH_DATE',
    },
    {
      id: 'c-section-date',
      text: 'PLANNED_C-SECTION_DATE',
    },
    {
      id: 'pregnancy-loss',
      text: 'PREGNANCY_LOSS', // | || || |_
    },
    {
      id: 'prenatal-issue-mother',
      text: 'PRE-NATAL_ISSUE_MOTHER',
    },
    {
      id: 'prenatal-issue-baby',
      text: 'PRE-NATAL_ISSUE_BABY',
    },
    {
      id: 'postpartum-issue-mother',
      text: 'POSTPARTUM_ISSUE_MOTHER',
    },
    {
      id: 'postpartum-issue-baby',
      text: 'POSTPARTUM_ISSUE_BABY',
    },
    {
      id: 'other',
      text: 'OTHER_REASON',
    },
  ];
  const DROPDOWN_OPTIONS = MATERNAL_CONDITION_CODES.map((option) => ({
    key: option.id,
    text: option.text,
    value: option.text,
  }));

  const DATE_FORMAT: string = 'YYYY-MM-DD';

  useEffect(() => {
    console.log(props.maternityData[0]);
    if (props.maternityData[0]) {
      let lastDataInfo = '';
      let indexOfQuestionnaire = '';
      let currentDataInfo = '';
      for (let i = 0; i < props.maternityData.length; i++) {
        //if the data does have surveyResponse id, we will use the surveyResponse id as lastDataInfo
        if (props?.maternityData[i]?.resource?.derivedFrom?.length > 0) {
          indexOfQuestionnaire = '';
          currentDataInfo = '';
          indexOfQuestionnaire = props?.maternityData[i]?.resource?.['derivedFrom'].findIndex(
            (obj) => (obj.display = 'Questionnaire')
          );
          currentDataInfo =
            props?.maternityData[i]?.resource?.['derivedFrom']?.[indexOfQuestionnaire]?.['reference']?.split('/')?.[1];
        } else {
          //if the data does not have surveyResponse id, we will use the effectivePeriod as lastDataInfo
          currentDataInfo = props?.maternityData[i]?.resource?.effectivePeriod?.start;
        }
        if (!Boolean(lastDataInfo)) {
          lastDataInfo = currentDataInfo;
        }
        if (currentDataInfo === lastDataInfo || moment(lastDataInfo).diff(currentDataInfo, 'minutes') < 1) {
          if (!highlightID.includes(props?.maternityData[i]?.resource?.id)) {
            setHighlightID((highlightID) => [...highlightID, props?.maternityData[i]?.resource?.id]);
          }
        } else {
          break;
        }
      }
    }
  }, [highlightID, props]);

  const changeOption = (value) => {
    setObservationOption(value);
  };

  const handleDateChange = (e, data) => {
    if (data.value === null) {
      return false;
    }
    setObservationDate(moment(data.value).format(DATE_FORMAT));
  };

  const handleTextInput = (e) => {
    let value = e.target && e.target.value;
    if (!value || value === '') {
      return false;
    }
    setFreeText(value);
  };

  const handlePopOpen = () => {
    setpopOpen(true);
  };
  const handleClose = () => {
    setpopOpen(false);
  };

  /* const handleDelete = (observationId) => {
    setLoading('Deleting Maternity Label...');
    setpopOpen(false);
    FHIRDelete(`/Observation/${observationId}`, authInfo.token).then(
      () => {
        setLoading('Deletion Success. Updating Maternity UI...');
        props.mutate().then(() => {
          setLoading('');
        });
      },
      (error) => {
        setLoading(`Error - Unable to delete Maternity label: ${error.message}`);
      }
    );
  }; */

  /* const handleSubmit = async (val, text) => {
    val && val.preventDefault();
    setpopOpen(false);
    setLoading('Submitting Maternity Label...');

    let body: fhir4.Observation = ObservationBuilder.buildMaternityHealthObservation(
      props.patientId,
      new Date().toJSON(),
      observationOption,
      '82810-3', //Pregnancy LOINC
      observationOption !== 'Other Reason' ? observationOption : text,
      `${observationDate}`,
      authInfo.practitionerName
    );

    await FHIRPoster(`/Observation/`, authInfo.token, body).then(
      () => {
        setLoading('Submission Success. Updating Maternity UI...');
        props.mutate().then(() => {
          setLoading('');
        });
        setObservationOption('');
        setObservationDate('');
        setFreeText('');
      },
      (error) => {
        setLoading(`Error - Unable to submit Maternity label: ${error.message}`);
      }
    );
  }; */

  let fhirMaternityHealthData = props.maternityData;
  let flatMaternityHealthUnsorted = [];

  if (fhirMaternityHealthData) {
    flatMaternityHealthUnsorted = fhirMaternityHealthData.map((fhirMaternity) => {
      return FHIRUtils.parseFHIRVital(fhirMaternity.resource); //can be parsed as FHIRVital instead of making a new parser. Not sure which is more ideal
    });
    let flatMaternityObservations = [];
    if (flatMaternityHealthUnsorted) {
      flatMaternityObservations = flatMaternityHealthUnsorted.sort((c1, c2) => {
        return moment(c2.effectiveDate, 'YYYY-MM-DD').valueOf() - moment(c1.effectiveDate, 'YYYY-MM-DD').valueOf();
      });
    }

    return (
      <>
        {loading.length > 0 && <Loader active inline="centered" content={loading} />}
        {flatMaternityObservations.map((flatMaternity, i) => {
          let effectiveDate = moment(flatMaternity.effectiveDate, 'YYYY-MM-DD').format('MMMM Do, YYYY');
          return (
            <Label
              key={flatMaternity.id}
              style={{ margin: '0.15em' }}
              basic={flatMaternity.display.toLowerCase().indexOf('date') === -1 ? false : true}
              color={
                flatMaternity.display.toLowerCase().indexOf('date') === -1
                  ? 'red'
                  : highlightID?.includes(flatMaternity?.id)
                    ? 'teal'
                    : 'grey'
              }
            >
              <div style={{ display: 'flex' }}>
                <div>
                  <a target="_blank" rel="noopener noreferrer">
                    {flatMaternity.display}
                  </a>
                  {': '}
                  {flatMaternity.valueString?.length > 0 && <b>{flatMaternity.valueString}</b>}

                  {flatMaternity.valueCode && (
                    <a
                      href={`https://icdlist.com/?t=icd10&s=${flatMaternity.valueCode}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {flatMaternity.valueCode}
                    </a>
                  )}

                  <div>{effectiveDate}</div>
                </div>

                {/* {!props.sidebar && flatMaternityObservations.length > 1 && (
                  <>
                    <div style={{ width: 10 }} />
                    <Icon
                      style={{ float: 'right' }}
                      size="large"
                      name="delete"
                      onClick={() => handleDelete(flatMaternity.id)}
                    />
                  </>
                )} */}
              </div>
            </Label>
          );
        })}

        {/* {!props.sidebar && (
          <Popup
            trigger={
              <Popup
                trigger={<Label as="a">+</Label>}
                content={
                  <Form onSubmit={(e, { value }) => handleSubmit(value, freeText)}>
                    <Form.Group>
                      <Form.Field
                        name="option"
                        label="OBSERVATION OPTION:"
                        selectOnBlur={false}
                        control={Select}
                        search
                        key="option"
                        placeholder="Select and option"
                        value={observationOption}
                        options={DROPDOWN_OPTIONS}
                        onChange={(e, { value }) => changeOption(value)}
                      />
                      {observationOption === 'Other Reason' && (
                        <Form.Input
                          label="Free Text Observation"
                          placeholder="Enter Value"
                          type="text"
                          name="newobs"
                          onChange={(e, { value }) => handleTextInput(e)}
                        />
                      )}
                      <Form.Field
                        style={{ color: 'black' }}
                        control={SemanticDatepicker}
                        default
                        placeholder="Enter Date Here"
                        //value={observationDate}
                        label="ESTIMATED DATE:"
                        onChange={(e, data) => handleDateChange(e, data)}
                        clearable={false}
                        size="small"
                        format={DATE_FORMAT}
                      />
                      <Form.Button primary content="Add" />
                    </Form.Group>
                  </Form>
                }
                on="click"
                position="top right"
                open={popOpen}
                onOpen={handlePopOpen}
                onClose={handleClose}
              />
            }
            content={<Label as="a">Create New Maternity Observation</Label>}
            on="hover"
            position="bottom right"
          />
        )} */}
      </>
    );
  } else {
    return <></>;
  }
}

export default MaternityHealth;
