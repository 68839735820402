import { Patient } from "fhir/r4";
import { Segment, Tab, Sidebar, Menu, Loader, Grid } from "semantic-ui-react";
import PatientBanner from "./banner";
import Profile from "./profile";
import Questionnaires from "./questionnaires";
import Communications from "./communication";
import * as FHIRUtils from "../../utils/FHIRUtils";
import { useContext, useEffect, useState } from "react";
import React from "react";
import ActivityLog from "./activityLog";
import Food from "../common/food";
import FHIRFetcher from "../../data/FHIRFetcher";
import useSWR from "swr";
import AuthContext from "../../contexts/AuthContext";
import ActionableInsights from "./ai";
import Referrals from '../common/referrals';
import { profileCodes, labCodes, vitalCodes, maternityHealthCodes } from './profile/index';

function Patient360(props: any) {

  let authInfo: any = useContext(AuthContext);
  let fhirPatient: Patient = props.patient;

  const [sidebarVisible, setSidebarVisible] = React.useState(props.showSideBar);

  const [activeTab, setActiveTab] = useState("");
  const [flatpatient, setFlatpatient] = useState(
    FHIRUtils.parseFHIRPatient(fhirPatient)
  );

  useEffect(()=>{
    setFlatpatient(FHIRUtils.parseFHIRPatient(fhirPatient));
  },[fhirPatient.id])

  const [profileData, setProfileData] = useState({});

  //window.document.title = `${flatpatient.firstName} ${flatpatient.lastName}`

  let handleTabChange = (e, { activeIndex, panes }) => {
    setActiveTab(panes[activeIndex].menuItem.name);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const [QRID, setQRID] = useState<{ id: string; isNewResponse: boolean }>();
  const updateQRID = (newID, isNewResponse) => {
    setQRID({ id: newID, isNewResponse });
  };

  const options = { shouldRetryOnError: false, revalidateOnFocus: false };

  const profileOptions = {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    onSuccess: (data, key, config) => {
      setProfileData(data);
      /* setActiveReferralData(
        data?.entry &&
          data?.entry
            ?.filter((observation) => {
              return (
                observation.resource.code.coding.find((code) => code.code === 'REFERRAL-TYPE') &&
                ['NEW', 'OUTREACH', 'IN-PROGRESS', 'PENDING'].includes(
                  FHIRUtils.parseFHIRPreference(observation.resource).referralStatus
                )
              );
            })
            .map((item) => {
              return {
                type: item.resource.valueString,
                status: FHIRUtils.parseFHIRPreference(item.resource).referralStatus,
              };
            })
      );
      setActiveAndCompletedReferralData(
        data?.entry &&
          data?.entry
            ?.filter((observation) => {
              return (
                observation.resource.code.coding.find((code) => code.code === 'REFERRAL-TYPE') &&
                ['NEW', 'OUTREACH', 'IN-PROGRESS', 'PENDING', 'COMPLETED'].includes(
                  FHIRUtils.parseFHIRPreference(observation.resource).referralStatus
                )
              );
            })
            .map((item) => {
              return {
                type: item.resource.valueString,
                status: FHIRUtils.parseFHIRPreference(item.resource).referralStatus,
              };
            })
      ); */
    },
  };
  const { isValidating: profileLoading, mutate: profileMutate } = useSWR(
    [
      `/Observation/` +
        `?patient=${fhirPatient.id}` +
        `&_sort=-date` +
        `&code=${
          profileCodes.map((p) => p.code).join(',') +
          ',' +
          labCodes.map((l) => l.code).join(',') +
          ',' +
          vitalCodes.map((v) => v.code).join(',') +
          ',' +
          maternityHealthCodes.map((m) => m.code).join(',')
        }`,
      authInfo.token,
    ],
    FHIRFetcher,
    profileOptions
  );
  const { data: consentData, mutate: consentDataMutate } = useSWR(
    [`/Observation/?patient=${fhirPatient.id}&_sort=-date&code=CONSENT`, authInfo.token],
    FHIRFetcher,
    options
  );
 
  //fetch medications
  const {
    data: medicationsData,
    error: medicationsError,
    mutate: medicationsMutate,
  } = useSWR(
    [`/MedicationRequest/?patient=${fhirPatient.id}`, authInfo.token],
    FHIRFetcher,
    options
  );

  const { data: communicationsData, error: communicationsError, mutate: communicationsMutate } = useSWR(
    ["/Communication/?patient=" + fhirPatient.id, authInfo.token],
    FHIRFetcher,
    options
  );

  const { data: carePlanData, error: carePlanError, mutate: carePlanMutate } = useSWR(
    ["/CarePlan/?patient=" + fhirPatient.id , authInfo.token],
    FHIRFetcher,
    options
  );

  const { data: tasksData, error: tasksError, mutate: tasksMutate } = useSWR(
    ["/Task/?patient=" + fhirPatient.id , authInfo.token],
    FHIRFetcher,
    options
  );

  const { data: questionnaireData } = useSWR(
    [`/Questionnaire/?context=${flatpatient.org}&context:not=FOOD_RATING&_sort=title`, authInfo.token],
    FHIRFetcher,
    options
  );
  const { data: foodSurveyData } = useSWR(
    [`/Questionnaire/?identifier=https://projectwell.io/fhir/identifiers/patient-id|${fhirPatient.id}`, authInfo.token],
    FHIRFetcher,
    options
  );

  /* const { data: personData } = useSWR(
    [`/Person/` + props.personId, authInfo.token],
    FHIRFetcher,
    options
  ); */
 
  const tabPanes = [
    {
      menuItem: {
        key: "profile",
        color: "orange",
        content: "PROFILE",
        name: "PROFILE",
      },
      pane: {
        key: "profiles",
        content: (
          <Tab.Pane style={{ overflow: "auto" }} color="orange">
            <Profile
              patientId={fhirPatient.id}
              org={flatpatient.org}
              profileData={profileData}
              profileMutate={profileMutate}
              profileLoading={profileLoading}
              activeTab={activeTab}
              sidebar={false}
              setActiveTab={setActiveTab}
              updateQRID={updateQRID}
              medicationsData={medicationsData}
              medicationsMutate={medicationsMutate}
              carePlanData={carePlanData}
              carePlanMutate={carePlanMutate}
              consentData={consentData}
              consentDataMutate={consentDataMutate}
            />
          </Tab.Pane>
        ),
      },
    },
    {
      menuItem: {
        key: 'survey',
        color: 'blue',
        content: 'SURVEYS',
        name: 'SURVEYS',
      },
      pane: {
        key: 'surveys',
        content: (
          <Questionnaires
            questionnaireData={questionnaireData}
            foodSurveyData={foodSurveyData}
            patientId={fhirPatient.id}
            patientEmail={flatpatient.email}
            patientMobilePhone={flatpatient.mobilePhone}
            patientFirstName={flatpatient.firstName}
            profileMutate={profileMutate}
            org={flatpatient.org}
            QRID={QRID}
            consentDataMutate={consentDataMutate}
            tasksMutate={tasksMutate}
            //doNotContact={doNotContact}
            //careTeamData={careTeamData}
            //personData={personData}
            //refreshTasks={refreshTasks}
            fhirPatient={fhirPatient}
            refreshPatientDetails={props.refreshPatientDetails}
          />
        ),
      },
    },
    {
      menuItem: {
        key: "communication",
        color: "orange",
        content: "COMMUNICATION",
        name: "ENGAGEMENT",
      },
      pane: {
        key: "communications",
        content: <Communications patient={flatpatient} />,
      },
    },
    {
      menuItem: {
        key: "orders",
        color: "orange",
        content: "FOOD ORDERS",
        name: "ENCOUNTERS",
      },
      pane: {
        key: "orderss",
        content: (
          <Food
            patientId={fhirPatient.id}
            patient={flatpatient}
            profileData={profileData}
            org={flatpatient.org}
          />
        ),
      },
    },
    {
      menuItem: {
        key: "ai",
        color: "orange",
        content: "INSIGHTS",
        name: "AI",
      },
      pane: {
        key: "ai",
        content: (
          <ActionableInsights
            patientId={fhirPatient.id}
            patient={flatpatient}
            profileData={profileData}
            org={flatpatient.org}
          />
        ),
      },
    },
    {
      menuItem: {
        key: 'referrals',
        color: 'blue',
        content: 'REFERRALS',
        name: 'REFERRALS',
      },
      pane: {
        key: 'referrals',
        content: (
          <Referrals
            patientId={fhirPatient.id}
            org={flatpatient.org}
            activeTab={activeTab}
            profileMutate={profileMutate}
          />
        ),
      },
    }
  ].filter(pane => (
    process.env.REACT_APP_TAB_CONFIG 
      && JSON.parse(process.env.REACT_APP_TAB_CONFIG)
      && !(JSON.parse(process.env.REACT_APP_TAB_CONFIG)?.[flatpatient.org]?.includes(pane.menuItem.key))
  ))

  return (
    <Grid cols={2} padded={"horizontally"} stretched>
      <Grid.Row divided>
        <Grid.Column width="4">
          <Segment>
           {!communicationsData  && !carePlanData && !tasksData && <Loader active inline="centered" content="Loading Member..."/>}
            {communicationsData && carePlanData && tasksData &&
              <PatientBanner patient={fhirPatient} setSelectedPatientInfo={setFlatpatient} outreaches={communicationsData} careplan={carePlanData} tasks={tasksData} personId={props.personId} />
            }
          </Segment>
        </Grid.Column>
        <Grid.Column width="12">
          <Segment>
            <Tab
                className="cb_pushable_tabs"
                defaultActiveIndex={0}
                menu={{ key: "pusher", secondary: true, pointing: true }}
                panes={tabPanes}
                renderActiveOnly={false}
                onTabChange={handleTabChange}
            />
            </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Patient360;


