//!!! NOTE!!: ALL env variables for Amplify app needs to have the prefix REACT_APP_  !!!!

//awsConfig is dynamic. Based on the Org url path parm, the cognito userpool resoruces are determined 
//from the corrsponding env variables that are JSON mapped by org. 
// eg. /portal.nourishedrx.coom/bcnc will get JSON.parse(process.env.REACT_APP_USER_POOL)["bcnc"]
export function buildAWSConfig(org) {

  const DEFAULT = "default"

  if(!org || org == null || org == undefined || org == "undefined" || org == "null") {
    org = DEFAULT
  }
 
  let userpool =  JSON.parse(process.env.REACT_APP_USER_POOL)[org]
  let clientId =  JSON.parse(process.env.REACT_APP_CLIENTID)[org]
  let userPoolDomain =  JSON.parse(process.env.REACT_APP_COGNITO_DOMAIN)[org]

  return {
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: userpool,
      userPoolWebClientId: clientId,
      mandatorySignIn: process.env.REACT_APP_MANDATORY_SIGNIN,
      cookieStorage: {
        domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
      },
      oauth: {
        domain: userPoolDomain,
        scope: process.env.REACT_APP_SCOPES.split(','),
        redirectSignIn: `${process.env.REACT_APP_REDIRECT_SIGNIN_URL}${org == DEFAULT ? "" : "/" + org}`,
        redirectSignOut: `${process.env.REACT_APP_REDIRECT_SIGNOUT_URL}${org == DEFAULT ? "" : "/" + org}`,
        responseType: process.env.REACT_APP_OAUTH_RESPONSETYPE,
      },
    },
    API: {
      endpoints: [
        {
          name: process.env.REACT_APP_API_NAME,
          endpoint: process.env.REACT_APP_API_ENDPOINT,
        },
        {
          name: process.env.REACT_APP_FOODCOURT_API_NAME,
          endpoint: process.env.REACT_APP_FOODCOURT_API_ENDPOINT,
        }
      ],
    },
  };
}
