import {
  Header,
  Image,
  Grid,
  Icon,
  Menu,
  Button,
  Dropdown,
  Loader,
} from "semantic-ui-react";
import Login from "../components/auth/Login";
import React from "react";
import { Auth } from "aws-amplify";
import { AuthContextProvider } from "../contexts/AuthContext";
import Home from "../components/home";
import FHIRFetcher from "data/FHIRFetcher";
import * as fhirpath from "fhirpath";
import FHIRPoster from "data/FHIRPoster";

function App(props: any) {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [token, setToken] = React.useState<string | null>(null);
  const [userEmail, setUserEmail] = React.useState<string | null>(null);
  const [personId, setPersonId] = React.useState<string | null>(null);
  const [organizationId, setOrganizationId] = React.useState<string | null>(null);
  const [role, setRole] = React.useState<string | null>(null);
  const [personName, setPersonName] = React.useState<string | null>(null);
  const [allOrgs, setAllOrgs] = React.useState<Array<string> | null>(null);
  const [loader, setLoader] = React.useState(false)

  const handleLogin = (
    accessToken: string,
    userEmail: string,
    personId: string,
    organizationId: string,
    role: string
  ) => {
    setToken(accessToken);
    setUserEmail(userEmail);
    setPersonId(personId)
    setOrganizationId(organizationId)
    setRole(role)

    if (userEmail && userEmail !== undefined) {
      setLoggedIn(true);
      FHIRFetcher("/Practitioner/" + encodeURIComponent(personId), accessToken).then(
        (person) => {
          let persName = fhirpath.evaluate(person, "name.given.first()")
            + " "
            + fhirpath.evaluate(person, "name.family")
          setPersonName(persName);

          // see if CC has access to multiple orgs, if so set dropdown to accessible orgs
          let persOrg = fhirpath.evaluate(person, "identifier.where(system='https://projectwell.io/fhir/organization').value")
          if (persOrg?.[0]?.includes(",")) {
            let orgArr = persOrg[0].split(",")
            setAllOrgs(orgArr)
          }
          
          //add the authContext to local storage to support deeplinking 
          let hostName = window.location.hostname
          localStorage.setItem(`${hostName}-authToken`, accessToken);
          localStorage.setItem(`${hostName}-userEmail`, userEmail)
          localStorage.setItem(`${hostName}-personId`, personId)
          localStorage.setItem(`${hostName}-organizationId`, organizationId)
          localStorage.setItem(`${hostName}-role`, role)
          localStorage.setItem(`${hostName}-personName`, persName)
        }, reason => {
          console.error("Error fetching Practitioner with identifier: ", reason); // Error!
          //Auth.signOut();
        });
      
    } else {
      console.log(
        "Unable to Login: No Associated Email found for the LoggedIn User"
      );
      Auth.signOut();
    }
  };

  const onLogout = async () => {
    Auth.signOut();
  };

  const onDropdownChange = (e) => {
    setLoader(true)
    FHIRPoster('/auth-org/' + e.target.textContent, token, null).then(
      (response) => {
        console.log("set-auth-org success")
        
        let hostName = window.location.hostname
        localStorage.setItem(`${hostName}-organizationId`, e.target.textContent)

        setOrganizationId(e.target.textContent)

        setLoader(false)
      },
      (error) => {
        console.log("set-auth-org error")

        setLoader(false)
      }
    )
  }

  return (
    <div id="root">

    <div className='topbar'>
      {!loggedIn && (
        <>
          <br />
          <Header as="h5" floated="left">
            <Image src={"/images/doc.png"} />
            <Header.Content>
              <h2>{"Welcome to NourishedRx"}</h2>
            </Header.Content>
          </Header>
          <Header floated="right">
            <Login onLoggedIn={handleLogin} />
          </Header>
          <img src="/images/food.png" alt="background" />
        </>
      )}
      {loggedIn && (
        <>
          <Menu size="large" color="orange" inverted >
            <Menu.Item key='image'>
              <Image src="/images/nrx.jpg" /> &nbsp;&nbsp;
              <b>NourishedRx</b> &nbsp;&nbsp;
            </Menu.Item>
            <Menu.Item>
              {!loader && <Dropdown
                options={allOrgs ? allOrgs.map(o => ({key: o, text: o, value: o})) : [{key:organizationId, text:organizationId, value:organizationId}]}
                value={organizationId}
                onChange={(e) => onDropdownChange(e)}
              />}
              {loader &&
                <Loader active inline="centered" />
              }
            </Menu.Item>
            <Menu.Menu key='rightbar' position="right">
              <Menu.Item key='user name'> <Icon size="large" name='user' /><b>{personName}</b></Menu.Item>
              <Menu.Item key='logout'>
                <Button color="red" onClick={onLogout} content='Logout' />
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          <AuthContextProvider
            value={{
              token: token,
              userEmail: userEmail,
              organizationId: organizationId,
              role: role,
              personId: personId,
              validateUSPSAddress: true,
              personName: personName
            }}
          >

            <Grid cols={1} padded={"horizontally"}>
              <Grid.Row>
                <Home tab={tab} setTab={setTab} org={organizationId} personId={personId} />
              </Grid.Row>
            </Grid>
          </AuthContextProvider>
        </>
      )}
    </div>

    <Menu size="large" color="orange" inverted fixed="bottom" borderless>
      <Menu.Item>
        <b style={{position: 'fixed', left: 0, width: '100%', textAlign: "center"}}>
          For  help,  contact  NourishedRx  HelpDesk  at  helpdesk@nourishedrx.com  or  855-659-FOOD (3663)
        </b>
      </Menu.Item>
    </Menu>

    </div>
  );
}

export default App;