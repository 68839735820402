import Amplify, { API } from "aws-amplify";
import { useParams } from 'react-router-dom';
import {buildAWSConfig}  from "../aws-config";
const DEFAULT = "default"
Amplify.configure(buildAWSConfig(DEFAULT)); //this part of code can just use default config for API end popitn names

const FHIRFetcher = async (endPoint: string, token: string) => {
  if (!endPoint || !endPoint.length) {
    return;
  }

  let dataEndPoint = "/fhir" + endPoint;

  const data = await API.get(process.env.REACT_APP_API_NAME, dataEndPoint, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

export function MultiFetcher(token: string, ...urls: any[]) {
  if(urls.length){
    return Promise.all(urls.map(url => FHIRFetcher(url,token)))
  }
  else{
    return Promise.resolve();
  }
}
export default FHIRFetcher;
