import { useState, useEffect, useContext } from "react";
import { Patient } from "fhir/r4";
import {
    Image,
    List,
    Icon,
    Feed,
    Card,
    Loader,
    Popup
} from "semantic-ui-react";

import * as FHIRUtils from "../../../utils/FHIRUtils";
import PatientCDO from "../../../models/patient";
import * as Helpers from '../../../utils/Helpers';
import { ValidatePatient } from '../edit/utils';
import EditPatient from '../edit';
import useSWR from "swr";
import FHIRFetcher from "../../../data/FHIRFetcher";
import AuthContext from "../../../contexts/AuthContext";
import moment from "moment";

function PatientBanner(props: any) {
    const [flatPatient, setFlatPatient] = useState(PatientCDO);
    const [activityData, setActivityData] = useState([]);
    const [careTeam, setCareTeam] = useState({});
    const [isUserPropsValidated, setIsUserPropsValidated] = useState(true);
    const [validationErrors, setValidationErrors] = useState([]);
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [notification, setNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [operation, setOperation] = useState('');

    let fhirPatient: Patient = props.patient;
    let authInfo: any = useContext(AuthContext);
    const options = { shouldRetryOnError: false, revalidateOnFocus: false };

    useEffect(() => {
        let parsedPatient = FHIRUtils.parseFHIRPatient(fhirPatient);
        setFlatPatient(parsedPatient);
    }, [fhirPatient]);

    useEffect(() => {
        let careTeamResource = props.careTeamData?.entry?.length && props.careTeamData?.entry[0]?.resource;
        setCareTeam(FHIRUtils.parseFHIRCareTeam(careTeamResource));
    }, [props.careTeamData]);

    //fetch provenance data
    const { data: provenanceData, error: provenanceError, mutate } = useSWR(
        [`/Provenance/?patient=${props.patient.id}&_sort=-_lastUpdated&_count=1000`, authInfo.token],
        FHIRFetcher,
        options
    );

    useEffect(() => {
        if (provenanceData && provenanceData.entry && provenanceData.entry.length) {
            let flatProvenanceData = provenanceData.entry.map(provenance => {
                let flatProvenance = FHIRUtils.parseFHIRProvenance(provenance.resource);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                flatProvenance.lastUpdated = flatProvenance.lastUpdated && moment(flatProvenance.lastUpdated, "YYYY-MM-DDTHH:mm:ss").format(
                    "MMMM Do, YYYY HH:mm:ss"
                );
                return flatProvenance
            })

            setActivityData(flatProvenanceData.filter(flatProvenance => {
                return (
                    flatProvenance.reason.includes("Screen") ||
                    flatProvenance.reason.includes("Care Plan") ||
                    // flatProvenance.reason.includes("Intake") ||
                    flatProvenance.reason.includes("Assessment") ||
                    flatProvenance.reason.includes("Intervention")
                ) //include only these
            }))
        }
    }, [provenanceData])

    // For Update Patient Details modal window
    let toggleModal = (flag, fhirPatient) => {
        if (operation === 'Update') {
            setOpenEdit(flag);
            setNotificationMsg('Patient details have been updated successfully...');
        } else {
            setOpenCreate(flag);
            setNotificationMsg('New profile has been added successfully...');
        }

        if (fhirPatient !== undefined && fhirPatient) {
            setNotification(true);

            let parsedPatient = FHIRUtils.parseFHIRPatient(fhirPatient);
            setFlatPatient(parsedPatient);
            props.setSelectedPatientInfo(parsedPatient); // Updating the patient of Patient360
            validateDeliveryAddress(parsedPatient);
        }
    };

    const validateUserProps = (patient) => {
        let validations = ValidatePatient(patient, 'Update', authInfo.personName, true, patient.id);
        if (!validations.valid) {
            setValidationErrors((prevState) => [...prevState, ...validations.patientBannerErrors]);
            setIsUserPropsValidated(false);
        }
    };

    const validateDeliveryAddress = (patient) => {
        setIsUserPropsValidated(true);
        setValidationErrors([]);
        validateUserProps(patient);

        // Validate USPS address only if the flag is true
        if (authInfo.validateUSPSAddress) {
            Helpers.validateUSPSAddress(
                patient.delivery_addressLine1[0],
                patient.delivery_addressLine2[0],
                patient.delivery_city[0],
                patient.delivery_state[0],
                patient.delivery_zip[0]
            ).then(
                (success) => { },
                (errors) => {
                    setIsUserPropsValidated(false);
                    setValidationErrors((prevState) => [...prevState, 'Address']);
                }
            );
        }
    };

    let imageName = "molly.png"
    if (flatPatient.gender) {
        if (flatPatient.gender == "male") {
            imageName = "elliot.jpg"
        }
    }

    return (
        <>
            <List fluid stretched>
                <List.Item>
                    <Card fluid stretched>
                        <Image src={"https://react.semantic-ui.com/images/avatar/large/" + imageName} wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>
                                {flatPatient.firstName} {flatPatient.lastName}
                                <Popup
                                    content="Edit Member Details"
                                    trigger={
                                        <Icon
                                            data-content="Edit member"
                                            color="blue"
                                            link
                                            onClick={() => {
                                                setOpenEdit(true);
                                                setOperation('Update');
                                            }}
                                            name="edit"
                                            style={{ marginLeft: 5, fontSize: '1.2rem' }}
                                        />
                                    }
                                />
                            </Card.Header>
                            {/* <Card.Meta>
                                <span className='date'> {flatPatient.id}</span>
                            </Card.Meta> */}
                            <Card.Description>
                                {props.careplan?.entry?.[0]?.resource?.identifier?.find(i => i.system === "https://projectwell.io/fhir/identifiers/selected-intervention")?.value 
                                        ? ('enrolled ' + moment.utc(props.careplan.entry[0].resource.period.start).local().fromNow()) 
                                        : 'not yet enrolled'}
                            </Card.Description>
                        </Card.Content>
                        {/* <Card.Content extra>
                            <>
                                <Icon name='treatment' color="blue" />
                                {(props.tasks && props.tasks.entry) ? (props.tasks.entry.filter((resourceEnntry) => { return (resourceEnntry.resource.partOf && resourceEnntry.resource.partOf.length > 0) })).length : 0} <b>interventions</b>
                            </>
                            &nbsp;&nbsp;
                            <>
                                <Icon name='tty' color="orange" />
                                {(props.outreaches && props.outreaches.entry) ? props.outreaches.entry.length : 0} <b>ahoys</b>
                            </>
                        </Card.Content> */}
                    </Card>
                </List.Item>
                <List.Item>
                    <Card>
                        <Card.Content>
                            <Card.Header>Program Activity</Card.Header>
                        </Card.Content>
                        <Card.Content>
                            {!provenanceData && <Loader active inline="centered" content="Loading Member..." />}
                            {provenanceData && !provenanceError &&
                                <Feed size='small'>
                                    {activityData.map(({ id, target, author, lastUpdated, activityCode, reason }) => (
                                        <Feed.Event>
                                            <Feed.Label><Icon size="tiny" name='clock outline' color="purple" /></Feed.Label>
                                            <Feed.Content style={{marginLeft: '0px', position: 'relative'}}>
                                                <Feed.Date content={moment.utc(lastUpdated, "MMMM Do, YYYY hh:mm:ss").local().fromNow()} />
                                                <Feed.Extra text style={{maxWidth: '8vw'}}>
                                                    {reason}
                                                </Feed.Extra>
                                            </Feed.Content>
                                        </Feed.Event>
                                    ))}
                                </Feed>
                            }
                        </Card.Content>
                    </Card>

                    {openEdit && (
                        <EditPatient
                            patientId={fhirPatient.id}
                            refreshPatientDetails={props.refreshPatientDetails}
                            careteamStatus={careTeam['status']}
                            patient={flatPatient}
                            operation={'Update'}
                            open={openEdit}
                            userEmail={props.userEmail}
                            toggleModal={toggleModal}
                            organization={flatPatient.org}
                            fhirPatient={fhirPatient}
                            personId={props.personId}
                        />
                    )}

                </List.Item>
            </List>
        </>
    );
}

export default PatientBanner;
