import { useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';

export default function DateItem(props) {
  const [dateState, setDateState] = useState(null);
  const handleInput = (event, { name, value }) => {
    if (value) {
      let formatedDate = moment(value).format('YYYY-MM-DD');
      setDateState(value);
      props.addAnswer([{ valueDate: formatedDate }], props.i, props.j);
    } else {
      props.addAnswer(null, props.i, props.j);
    }
  };

  return !props.enable ? (
    <SemanticDatepicker
      disabled
      required
      autoComplete="false"
      datePickerOnly
      name="date"
      value={null}
      clearable={true}
      format="YYYY-MM-DD"
      placeholder="Enter Answer Here"
      onChange={handleInput}
    />
  ) : props.currentAnswer ? (
    <SemanticDatepicker
      required
      autoComplete="false"
      datePickerOnly
      name="date"
      value={
        props.currentAnswer
          ? moment(props.currentAnswer[0].valueDate).toDate()
          : dateState
          ? moment(dateState).toDate()
          : null
      }
      clearable={true}
      format="YYYY-MM-DD"
      placeholder="Enter Answer Here"
      onChange={handleInput}
    />
  ) : (
    <SemanticDatepicker
      required
      autoComplete="false"
      datePickerOnly
      name="date"
      value={null}
      clearable={true}
      format="YYYY-MM-DD"
      placeholder="Enter Answer Here"
      onChange={handleInput}
    />
  );
}
