import { Bundle, CarePlan, Period, PlanDefinition, Task, Timing } from 'fhir/r4';
import moment from 'moment';
import * as TaskBuilder from './task';
import * as FHIRUtils from '../fhirutils';
import { v4 as uuid } from 'uuid';
import * as Constants from '../constants';

//build careplan
export function buildBundle( //with CarePlan and Tasks
  patientId: string,
  carePlanId: string,
  serviceRequestId: string,
  goalId: string,
  conditionId: string,
  planDef: PlanDefinition,
  careTeamId: string,
  startDate: string, // CarePlan Startdate: yyyy-mm-dd
  title: string,
  description: string,
  updatedBy: string,
  org: string | undefined
): Bundle {
  if (!carePlanId) {
    carePlanId = uuid();
  }

  let [activities, tasks] = buildActivities(
    patientId,
    serviceRequestId,
    planDef,
    carePlanId,
    careTeamId,
    startDate,
    updatedBy,
    org
  );

  let carePlan: CarePlan = {
    resourceType: 'CarePlan',
    id: carePlanId,
    status: 'active',
    intent: 'plan',
    title: title,
    description: description,
    identifier: [
      {
        system: 'https://projectwell.io/fhir/identifiers/plan-def',
        value: planDef.id,
      },
    ],
    instantiatesCanonical: ['PlanDefinition/' + planDef.id],
    subject: {
      reference: 'Patient/' + patientId,
    },
    period: {
      start: startDate,
    },
    activity: activities,
  };

  if (careTeamId) {
    carePlan.careTeam = [
      {
        reference: 'CareTeam/' + careTeamId,
      },
    ];
  }

  if (conditionId) {
    carePlan.addresses = [
      {
        reference: 'Condition/' + conditionId,
      },
    ];
  }

  if (goalId) {
    carePlan.goal = [
      {
        reference: 'Goal/' + goalId,
      },
    ];
  }

  carePlan = FHIRUtils.addUpdatedBy(carePlan, updatedBy);

  let bundleEntries: any[] = [];
  bundleEntries.push(FHIRUtils.buildResourceEntry(carePlan, false));
  bundleEntries = bundleEntries.concat(FHIRUtils.buildResourceEntries(tasks, false));

  return FHIRUtils.buildBundle('transaction', bundleEntries); //rerurn a bundle of Tasks and a CarePlan that has references to those tasks
}

//builds CarePlan Activities as Tasks for all high level PlanDefinition Actions and return both
export function buildActivities(
  patientId: string,
  serviceRequestId: string,
  planDef: PlanDefinition,
  carePlanId: string,
  careTeamId: string,
  startDate: string,
  updatedBy: string,
  orgId: string
): [any, any] {
  let tasks: Task[] = [];
  let activities = planDef.action?.map((planDefAction) => {
    let title = planDefAction.title;
    let reasonCode = planDefAction.id;
    let description = planDefAction.description!;
    let forRef = 'Patient/' + patientId;
    let basedOnRef = ['CarePlan/' + carePlanId, 'ServiceRequest/' + serviceRequestId, 'Organization/' + orgId];
    let ownerRef = {
      reference: 'CareTeam/' + careTeamId,
      display: 'Care Team',
    };
    startDate = new Date().toISOString().split('T')[0];
    let endDate = startDate;
    let partOf; //not defined in this context
    let focusRef = planDefAction.definitionCanonical;
    let note = description;
    let identifier; //not defined in this context
    let task: Task = TaskBuilder.build(
      { code: 'careplan', display: 'CarePlan Action' },
      identifier,
      description,
      forRef,
      basedOnRef,
      focusRef!,
      reasonCode!,
      note,
      partOf,
      startDate,
      endDate,
      ownerRef,
      updatedBy
    );

    tasks.push(task);

    let activity = {
      progress: [
        {
          text: Constants.ACTIVITY_PLANNED,
          time: moment().toISOString(),
          authorString: updatedBy,
        },
      ],
      reference: {
        reference: 'Task/' + task.id,
        display: title,
      },
    };

    return activity;
  });

  return [activities, tasks];
}

//Build SubTasks for a Task of a PlanDefinition Action that has subactions under it
export function buildSubTasks(
  patientId: string,
  orgId: string,
  carePlanId: string,
  careTeamId: string,
  parentTaskId: string,
  planDefAction: any, //this is the action node for eg. the High-Acuity action udner intervention
  startDate: string,
  updatedBy: string,
  referralId?: string
): Bundle {
  let tasks: Task[] = [];
  //console.log(JSON.stringify(planDefAction))
  let actions: [any] = planDefAction.action;
  let actionStartDate = new Date().toISOString().substring(0, 10);
  let actionEndDate = startDate;
  actions?.forEach((action) => {
    let timing: Timing, repeatCount, periodUnit;
    timing = action.timingTiming;
    if (timing && timing.repeat) {
      repeatCount = timing.repeat?.count;
      periodUnit = timing.repeat?.periodUnit;
    }
    let reasonCode = planDefAction.id + ' | ' + action.id;
    let description = action.description;
    let forRef = 'Patient/' + patientId;
    let basedOnRef = ['CarePlan/' + carePlanId, 'Organization/' + orgId];
    let focusRef = action.definitionCanonical;
    let partOfRef = 'Task/' + parentTaskId;
    let note = action.textEquivalent;
    let taskStart = actionStartDate;
    let ownerRef = {
      reference: 'CareTeam/' + careTeamId,
      display: 'Care Team',
    };
    let identifier, endDate; //not defined in this context
    if (repeatCount) {
      //for subtasks that repeat. create multiple instances of subtasks with different start dates
      let taskExecutionPeriods: Period[] = buildTaskExecutionPeriods(actionStartDate, timing);

      for (let i = 0; i < repeatCount!; i++) {
        description = action.description!;
        if (repeatCount > 1) {
          description = action.description! + ': ' + getPeriod(periodUnit) + '-' + i;
        }
        taskStart = taskExecutionPeriods[i].start!;
        let task = TaskBuilder.build(
          { code: action.code[0].text, display: action.title },
          identifier,
          description,
          forRef,
          basedOnRef,
          focusRef!,
          reasonCode!,
          note,
          partOfRef,
          taskStart,
          endDate,
          ownerRef,
          updatedBy,
          null,
          referralId
        );
        tasks.push(task);
      }
    } else {
      let task = TaskBuilder.build(
        { code: action.code[0].text, display: action.title },
        identifier,
        description,
        forRef,
        basedOnRef,
        focusRef!,
        reasonCode!,
        note,
        partOfRef,
        taskStart,
        endDate,
        ownerRef,
        updatedBy,
        null,
        referralId
      );
      tasks.push(task);
    }
  });

  let bundleEntries = FHIRUtils.buildResourceEntries(tasks, false);
  return FHIRUtils.buildBundle('transaction', bundleEntries);
}

//builds execution periods from a startDate for the specified FHIR Timing
function buildTaskExecutionPeriods(startDate: string, timing: Timing): Period[] {
  //assumption: frequency is always 1: happens only once every period and happens for "repeatCount" number of periods.
  let repeatCount = timing.repeat?.count;
  let repeatPeriod = timing.repeat?.period;
  let repeatPeriodUnit = timing.repeat?.periodUnit;
  let offSet = timing.repeat?.offset; //0 or 1:  1-means skip first period
  let startMoment = moment(startDate, 'YYYY-MM-DD');

  let periods: Period[] = [];
  let momentKey;

  switch (repeatPeriodUnit) {
    case 'wk':
      momentKey = 'weeks';
      break;

    case 'mo':
      momentKey = 'months';
      break;

    case 'd':
      momentKey = 'days';
      break;

    default:
      break;
  }

  //Hari : Changes to skip offset period properly.
  if (momentKey) {
    if (offSet) {
      //skip offset period
      startMoment = startMoment.add(offSet, momentKey); //for tasks that occur after the first repeat period. for eg. suvery starting after 26 weeks.
    }
    for (let i = 0; i <= repeatCount!; i++) {
      //go one extra period, so we can use it for next action
      let executionPeriod = {
        start: startMoment.format('YYYY-MM-DD'),
      };
      periods.push(executionPeriod);
      startMoment = startMoment.add(repeatPeriod!, momentKey);
    }
  }

  return periods;

  //     Once Every one week occurs for 52 times
  //     "count": 52,
  //     "frequency": 1,
  //     "period": 1,
  //     "periodUnit": "wk"
  //     "offSet":0

  // Once Every 2 Weeks --> occurs 7 times  . After 5 weeks (after the period elapses)
  //     "count": 7,
  //     "frequency": 1,
  //     "period": 2,
  //     "periodUnit": "wk"
  //     "offSet":5  //Start after 5 weeks and repeat evey 2 weeks Seven times
}

function getPeriod(periodKey) {
  let period = '';
  switch (periodKey) {
    case 'wk':
      period = 'Week';
      break;

    case 'mo':
      period = 'Month';
      break;

    case 'd':
      period = 'Day';
      break;

    default:
      break;
  }

  return period;
}
