import { Form, Icon, Label, Loader, Popup, Select } from 'semantic-ui-react';
//import FHIRDelete from '../../../data/FHIRDelete';
//import FHIRPoster from '../../../data/FHIRPoster';
import { AuthContext } from '../../../contexts/AuthContext';
import { useContext, useEffect } from 'react';
import React from 'react';
import moment from 'moment';
import { ObservationBuilder } from '../../../fhir-sdoh';
import { parseFHIRPreference } from '../../../utils/FHIRUtils';
import { isCompositeComponent } from 'react-dom/test-utils';

export default function Observations(props: any) {
  const [loading, setLoading] = React.useState(!props.data ? 'Loading Profile Data...' : '');
  const [popOpen, setpopOpen] = React.useState(false);
  const [resourceOption, setResourceOption]: any = React.useState('');
  const [communicationOption, setCommunicationOption]: any = React.useState('');

  const handlePopOpen = () => {
    setpopOpen(true);
  };
  const handleClose = () => {
    setpopOpen(false);
  };

  const [highlightID, setHighlightID] = React.useState<any[]>([]);

  useEffect(() => {
    if (props.data[0]) {
      let lastDataInfo = '';
      let indexOfQuestionnaire = '';
      let currentDataInfo = '';
      let lastUpdatedDate = '';
      let tempHighlightId = highlightID;
      for (let i = 0; i < props.data.length; i++) {
        //if the data does have surveyResponse id, we will use the surveyResponse id as lastDataInfo
        if (props?.data[i]?.resource?.derivedFrom?.length > 0) {
          if (i === 0) {
            lastUpdatedDate = props?.data[i]?.resource?.meta?.lastUpdated;
          }
          indexOfQuestionnaire = '';
          currentDataInfo = '';
          indexOfQuestionnaire = props?.data[i]?.resource?.['derivedFrom'].findIndex(
            (obj) => (obj.display = 'Questionnaire')
          );
          currentDataInfo =
            props?.data[i]?.resource?.['derivedFrom']?.[indexOfQuestionnaire]?.['reference']?.split('/')?.[1];
        } else {
          //if the data does not have surveyResponse id, we will use the effectivePeriod as lastDataInfo
          currentDataInfo = props?.data[i]?.resource?.effectivePeriod?.start;
        }

        if (!lastDataInfo) {
          lastDataInfo = currentDataInfo;
        }
        if (
          (currentDataInfo === lastDataInfo &&
            moment(lastUpdatedDate).diff(props?.data[i]?.resource?.meta?.lastUpdated, 'seconds') < 15) ||
          moment(lastDataInfo).diff(currentDataInfo, 'seconds') < 15
        ) {
          if (!highlightID.includes(props?.data[i]?.resource?.id)) {
            tempHighlightId.push(props?.data[i]?.resource?.id);
            setHighlightID(tempHighlightId);
          }
        } else {
          if (highlightID.includes(props?.data[i]?.resource?.id)) {
            tempHighlightId = tempHighlightId.filter((obs) => {
              return obs !== props?.data[i]?.resource?.id;
            });
            setHighlightID(tempHighlightId);
          }
        }
      }
    }
  }, [props?.data]);

  let authInfo: any = useContext(AuthContext);

  const getDisplay = (code: string) => {
    let arr = code.toLowerCase().split('-');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    let str = arr.join(' ');
    return str;
  };

  const resourcesSelectionOption = [
    {
      id: 'PW-RESOURCE-SENT_BY_EMAIL_TEXT',
      text: 'Resources sent by email/text',
    },
    {
      id: 'PW-RESOURCE-PRINTED_RESOURCES_ENGLISH',
      text: 'Printed Resources (English)',
    },
    {
      id: 'PW-RESOURCE-PRINTED_RESOURCES_SPANISH',
      text: 'Printed Resources (Spanish)',
    },
    {
      id: 'PW-RESOURCE-NO_RESOURCES_INFORMATION_REQUESTED',
      text: 'No resources information requested',
    },
  ];

  const communicationSelectionOption = [
    {
      id: 'PW-DO_NOT_CONTACT',
      text: 'Do Not Contact',
    },
    {
      id: 'PW-PHONE_ONLY',
      text: 'Phone Only',
    },
    {
      id: 'PW-EMAIL',
      text: 'Email',
    },
    {
      id: 'PW-TEXT',
      text: 'Text',
    },
    {
      id: 'PW-PHONE',
      text: 'Phone',
    },
  ];

  const DROPDOWN_OPTIONS = (option) => {
    return option.map((option) => ({
      key: option.id,
      text: option.text,
      value: option.text,
    }));
  };

  const observationsList = (observation: any, i: number, counter: number) => {
    let parsedObservation = parseFHIRPreference(observation.resource);
    if (!props.sidebar) {
      return (
        <Popup
          key={i}
          flowing
          trigger={
            <Label
              basic={!highlightID?.includes(parsedObservation?.id)}
              style={{ margin: '0.15em' }}
              color={highlightID?.includes(parsedObservation?.id) ? 'teal' : 'grey'}
            >
              {parsedObservation.valueString}{' '}
              {/* {props.code !== 'REFERRAL-TYPE' && props.code !== 'QUALIFICATION-STATUS' && (
                <Icon name="delete" onClick={() => handleDelete(i)} />
              )} */}
            </Label>
          }
          content={
            <span key={i}>
              <Icon name="clock" />
              Last Updated By: {parsedObservation.author}.{' '}
              {moment.utc(parsedObservation.lastUpdated).local().format('MMMM Do YYYY, h:mm:ss a')}.{' '}
              {observation.resource.category
                ? `From ${observation.resource.category[0].coding[0].display}`
                : 'Manually added'}
              .
            </span>
          }
          on="hover"
        />
      );
    } else {
      return (
        <Label
          key={i}
          basic
          style={{ margin: '0.15em' }}
          color={highlightID?.includes(parsedObservation?.id) ? 'teal' : 'grey'}
        >
          {parsedObservation.valueString}
          {parsedObservation.code === 'REFERRAL-TYPE' && parsedObservation.referralStatus
            ? ': ' + parsedObservation.referralStatus
            : ''}
        </Label>
      );
    }
  };

  /* const handleDelete = (i: number) => {
    setLoading('Deleting Profile Label...');
    setpopOpen(false);
    FHIRDelete(`/Observation/${parseFHIRPreference(props.data[i].resource).id}`, authInfo.token).then(
      () => {
        setLoading('Deletion Success. Updating Profile UI...');
        props.mutate().then(() => {
          setLoading('');
        });
      },
      (error) => {
        setLoading(`Error - Unable to delete profile label: ${error.message}`);
      }
    );
  }; */

  /* const handleSubmit = (e: any) => {
    e.preventDefault();
    setpopOpen(false);
    var value;
    if (!resourceOption && !communicationOption) {
      value = e.target.elements.newobs.value;
    } else {
      value = resourceOption ? resourceOption : communicationOption;
    }
    if (!value || value === '') {
      return false;
    }
    setLoading('Submitting Profile Label...');

    let body: fhir4.Observation = ObservationBuilder.buildPreferenceObservation(
      props.patientId,
      new Date().toJSON(),
      { code: props.code, display: getDisplay(props.code) },
      value.trim(),
      authInfo.practitionerName
    );

    FHIRPoster(`/Observation/`, authInfo.token, body).then(
      () => {
        setLoading('Submission Success. Updating Profile UI...');
        props.mutate().then(() => {
          setLoading('');
          setResourceOption('');
          setCommunicationOption('');
        });
      },
      (error) => {
        setLoading(`Error - Unable to submit profile label: ${error.message}`);
      }
    );
  }; */

  return (
    <>
      {loading.length > 0 && <Loader active inline="centered" content={loading} />}
      {props.data && (
        <>
          {props.data.map((observation: any, i: number) => observationsList(observation, i, props.data.length))}

          {/* {!props.sidebar &&
            props.code !== 'REFERRAL-TYPE' &&
            props.code !== 'QUALIFICATION-STATUS' &&
            props.code !== 'RESOURCES' &&
            props.code !== 'COMMUNICATION-PREFERENCES' && (
              <Popup
                trigger={<Label as="a">+</Label>}
                content={
                  <Popup
                    trigger={
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Input placeholder="Enter New Value" name="newobs" />
                          <Form.Button primary content="Add" />
                        </Form.Group>
                      </Form>
                    }
                    content={<Label as="a">Please add multiple preferences one at a time</Label>}
                    on="hover"
                    position="bottom right"
                  />
                }
                on="click"
                position="bottom right"
                open={popOpen}
                onOpen={handlePopOpen}
                onClose={handleClose}
              />
            )} */}
          {/* {!props.sidebar && props.code === 'COMMUNICATION-PREFERENCES' && (
            <Popup
              trigger={<Label as="a">+</Label>}
              content={
                <Form onSubmit={(e, { value }) => handleSubmit(e)}>
                  <Form.Group>
                    <Form.Field
                      name="option"
                      label="COMMUNICATION OPTION:"
                      selectOnBlur={false}
                      control={Select}
                      search
                      key="option"
                      placeholder="Select and option"
                      value={communicationOption}
                      options={DROPDOWN_OPTIONS(communicationSelectionOption)}
                      onChange={(e, { value }) => setCommunicationOption(value)}
                    />
                    <Form.Button primary content="Add" />
                  </Form.Group>
                </Form>
              }
              on="click"
              position="bottom right"
              open={popOpen}
              onOpen={handlePopOpen}
              onClose={handleClose}
            />
          )} */}
          {/* {!props.sidebar && props.code === 'RESOURCES' && (
            <Popup
              trigger={<Label as="a">+</Label>}
              content={
                <Form onSubmit={(e, { value }) => handleSubmit(e)}>
                  <Form.Group>
                    <Form.Field
                      name="option"
                      label="RESOURCE OPTION:"
                      selectOnBlur={false}
                      control={Select}
                      search
                      key="option"
                      placeholder="Select and option"
                      value={resourceOption}
                      options={DROPDOWN_OPTIONS(resourcesSelectionOption)}
                      onChange={(e, { value }) => setResourceOption(value)}
                    />
                    <Form.Button primary content="Add" />
                  </Form.Group>
                </Form>
              }
              on="click"
              position="bottom right"
              open={popOpen}
              onOpen={handlePopOpen}
              onClose={handleClose}
            />
          )} */}
        </>
      )}
    </>
  );
}
