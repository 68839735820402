import * as fhirpath from 'fhirpath';
import {
  Resource,
  Patient,
  Condition,
  Medication,
  Observation,
  AllergyIntolerance,
  PlanDefinition,
  Task,
  DocumentReference,
  ServiceRequest,
  CarePlan,
  CareTeam,
  Practitioner,
  Flag,
  Communication,
  Provenance,
} from 'fhir/r4';
import moment from 'moment';

export function parseFHIRPractitioner(fhirPractitioner: Practitioner): any {
  let flatPractitioner: any = {};

  flatPractitioner.id = fhirpath.evaluate(fhirPractitioner, 'Practitioner.id').toString();

  flatPractitioner.gender = fhirpath.evaluate(fhirPractitioner, 'Practitioner.gender').toString();

  flatPractitioner.lastName = fhirpath.evaluate(fhirPractitioner, 'Practitioner.name.family').toString();

  flatPractitioner.firstName = fhirpath.evaluate(fhirPractitioner, 'Practitioner.name.given').toString();

  flatPractitioner.fullName = flatPractitioner.firstName + ' ' + flatPractitioner.lastName;

  flatPractitioner.email = fhirpath.evaluate(fhirPractitioner, "Practitioner.telecom.where(system='email').value")[0];

  flatPractitioner.type = fhirpath
    .evaluate(fhirPractitioner, "identifier.where(system='https://projectwell.io/fhir/practitioner-types').value")
    .toString();

  return flatPractitioner;
}

export function parseFHIRTask(fhirTask: Task): any {
  let flatTask: any = {};

  flatTask.id = fhirpath.evaluate(fhirTask, 'Task.id').toString();
  flatTask.description = fhirpath.evaluate(fhirTask, 'Task.description').toString();
  flatTask.priority = fhirpath.evaluate(fhirTask, 'Task.priority').toString();
  flatTask.reasonCode = fhirpath.evaluate(fhirTask, 'Task.reasonCode.text').toString();
  flatTask.startDate = fhirpath.evaluate(fhirTask, 'Task.executionPeriod.start').toString();
  flatTask.status = fhirpath.evaluate(fhirTask, 'Task.status').toString();

  flatTask.note = fhirpath.evaluate(fhirTask, 'Task.note');

  flatTask.code = fhirpath.evaluate(fhirTask, 'Task.code.coding.first().code').toString();

  flatTask.lastModifiedBy = fhirpath
    .evaluate(fhirTask, "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value")
    .toString();

  flatTask.lastUpdated = fhirpath.evaluate(fhirTask, 'meta.lastUpdated').toString();

  return flatTask;
}

export function parseFHIRTaskReasonCode(fhirTask: Task): any {
  return fhirpath.evaluate(fhirTask, 'Task.reasonCode.text').toString();
}

// Added this function for PlanDefinition Card - Aditya
export function parseFHIRPlan(fhirPlan: PlanDefinition): any {
  let flatPlan: any = {};

  flatPlan.def = fhirpath.evaluate(fhirPlan, 'PlanDefinition.name').toString();

  return flatPlan;
}

// Added this function for Allergy Card - Aditya
export function parseFHIRAllergy(fhirAllergy: AllergyIntolerance): any {
  let flatAllergy: any = {};

  flatAllergy.category = fhirpath
    .evaluate(fhirAllergy, 'AllergyIntolerance.category.first().first().toString()')
    .toString();
  flatAllergy.recordedDate = fhirpath.evaluate(fhirAllergy, 'AllergyIntolerance.recordedDate').toString();
  flatAllergy.note = fhirpath.evaluate(fhirAllergy, 'AllergyIntolerance.note.first().text').toString();
  flatAllergy.substance = fhirpath
    .evaluate(fhirAllergy, 'AllergyIntolerance.reaction.substance.coding.first().display')
    .toString();

  flatAllergy.element = fhirpath.evaluate(fhirAllergy, 'AllergyIntolerance.code.coding.display').toString();

  flatAllergy.description = fhirpath.evaluate(fhirAllergy, 'AllergyIntolerance.reaction.description').toString();

  flatAllergy.criticality = fhirpath.evaluate(fhirAllergy, 'AllergyIntolerance.criticality').toString();

  return flatAllergy;
}

export function parseFHIRPatient(fhirPatient: Patient): any {
  let flatPatient: any = {};
  flatPatient.id = fhirPatient.id;
  let org: string = fhirpath.evaluate(fhirPatient, 'managingOrganization.reference').toString();

  flatPatient.lastUpdated = fhirpath.evaluate(fhirPatient, 'meta.lastUpdated').toString();
  flatPatient.org = org.substring(org.indexOf('/') + 1);
  flatPatient.firstName = fhirpath.evaluate(fhirPatient, 'name.given.first()');
  flatPatient.middleName = fhirpath.evaluate(fhirPatient, 'name.given.last()');
  flatPatient.lastName = fhirpath.evaluate(fhirPatient, 'name.family');
  flatPatient.fullName = flatPatient.firstName + ' ' + flatPatient.lastName;
  flatPatient.dob = moment(fhirPatient.birthDate).format('MM/DD/YYYY');
  flatPatient.age = moment().diff(flatPatient.dob, 'years', false);
  flatPatient.gender = fhirPatient.gender;
  flatPatient.genderPrefix =
    fhirPatient.gender === 'male'
      ? 'M'
      : fhirPatient.gender === 'female'
        ? 'F'
        : fhirPatient.gender === 'unknown'
          ? '?'
          : '';
  flatPatient.homePhone = fhirpath.evaluate(fhirPatient, "telecom.where(system='phone').where(use='home').value");
  flatPatient.mobilePhone = fhirpath.evaluate(fhirPatient, "telecom.where(system='phone').where(use='mobile').value");
  flatPatient.email = fhirpath.evaluate(fhirPatient, "telecom.where(system='email').value");
  flatPatient.memberId = fhirpath.evaluate(
    fhirPatient,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/member-id').value"
  );
  flatPatient.otherId = fhirpath.evaluate(
    fhirPatient,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/other-id').value"
  );
  flatPatient.mrnNumber = fhirpath.evaluate(
    fhirPatient,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/mrn-number').value"
  );
  flatPatient.language = fhirpath.evaluate(fhirPatient, 'communication.language.text');

  fhirPatient.address.forEach((addressItem) => {
    if (addressItem.type === 'physical') {
      flatPatient.delivery_addressLine1 = fhirpath.evaluate(addressItem, 'line.first()');
      fhirpath.evaluate(addressItem, "line").length > 1
        ? flatPatient.delivery_addressLine2 = fhirpath.evaluate(addressItem, "line.last()")
        : flatPatient.delivery_addressLine2 = [""];
      flatPatient.delivery_city = fhirpath.evaluate(addressItem, 'city');
      flatPatient.delivery_state = fhirpath.evaluate(addressItem, 'state');
      flatPatient.delivery_zip = fhirpath.evaluate(addressItem, 'postalCode');
    } else {
      flatPatient.addressLine1 = fhirpath.evaluate(addressItem, 'line.first()');
      fhirpath.evaluate(addressItem, "line").length > 1
        ? flatPatient.addressLine2 = fhirpath.evaluate(addressItem, "line.last()")
        : flatPatient.addressLine2 = [""];
      flatPatient.city = fhirpath.evaluate(addressItem, 'city');
      flatPatient.state = fhirpath.evaluate(addressItem, 'state');
      flatPatient.zip = fhirpath.evaluate(addressItem, 'postalCode');
      flatPatient.address = `${flatPatient.addressLine1} ${flatPatient.addressLine2}. ${flatPatient.city}, ${flatPatient.state}. ${flatPatient.zip} `;
    }
  });
  flatPatient.provider = fhirPatient?.extension?.find(
    (e) => e?.valueCoding?.code === 'PCP_ORGANIZATION'
  )?.valueCoding?.display;

  if (!flatPatient.delivery_addressLine1) {
    flatPatient.delivery_addressLine1 = [''];
    flatPatient.delivery_addressLine2 = [''];
    flatPatient.delivery_city = [''];
    flatPatient.delivery_state = [''];
    flatPatient.delivery_zip = [''];
  }

  return flatPatient;
}

export function parseFHIRCondition(fhirCondition: Condition): any {
  let flatCondition: any = {};

  flatCondition.category = fhirpath.evaluate(fhirCondition, 'Condition.category.coding.display').toString();
  flatCondition.code = fhirpath
    .evaluate(fhirCondition, "Condition.code.coding.where((system = 'http://snomed.info/sct')).code")
    .toString();
  flatCondition.display = fhirpath
    .evaluate(fhirCondition, "Condition.code.coding.where((system = 'http://snomed.info/sct')).display")
    .toString();

  flatCondition.onSetDate = fhirpath.evaluate(fhirCondition, 'Condition.onsetPeriod.start').toString();

  flatCondition.verificationStatusCode = fhirpath
    .evaluate(fhirCondition, 'Condition.verificationStatus.coding.code')
    .toString();

  flatCondition.verificationStatus = fhirpath
    .evaluate(fhirCondition, 'Condition.verificationStatus.coding.display')
    .toString();

  flatCondition.clinicalStatus = fhirpath.evaluate(fhirCondition, 'Condition.clinicalStatus.coding.code').toString();

  flatCondition.lastModifiedBy = fhirpath
    .evaluate(
      fhirCondition,
      "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value"
    )
    .toString();

  flatCondition.lastUpdated = fhirpath.evaluate(fhirCondition, 'meta.lastUpdated').toString();

  return flatCondition;
}

export function parseFHIRMedication(fhirMedication: Medication): any {
  let flatMedication: any = {};

  flatMedication.validStart = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dispenseRequest.validityPeriod.start')
    .toString();
  flatMedication.validEnd = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dispenseRequest.validityPeriod.end')
    .toString();

  flatMedication.code = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.medicationCodeableConcept.coding.first().code')
    .toString();

  flatMedication.display = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.medicationCodeableConcept.coding.first().display')
    .toString();

  flatMedication.dosageQuantityUnit = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dosageInstruction.first().doseAndRate.first().doseQuantity.unit')
    .toString();

  flatMedication.dosageQuantity = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dosageInstruction.first().doseAndRate.first().doseQuantity.value')
    .toString();

  flatMedication.supplyDurationUnit = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dispenseRequest.expectedSupplyDuration.unit')
    .toString();

  flatMedication.supplyDuration = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dispenseRequest.expectedSupplyDuration.value')
    .toString();

  flatMedication.supplyQuantityUnit = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dispenseRequest.quantity.unit')
    .toString();

  flatMedication.supplyQuantity = fhirpath
    .evaluate(fhirMedication, 'MedicationRequest.dispenseRequest.quantity.value')
    .toString();

  flatMedication.authoredOn = fhirpath.evaluate(fhirMedication, 'MedicationRequest.authoredOn').toString();

  flatMedication.id = fhirpath.evaluate(fhirMedication, 'MedicationRequest.id').toString();

  return flatMedication;
}

export function parseFHIRLab(fhirLab: Observation): any {
  let flatLab: any = {};

  flatLab.category = fhirpath.evaluate(fhirLab, 'Observation.category.first().coding.first().code').toString();
  flatLab.code = fhirpath.evaluate(fhirLab, 'Observation.code.coding.first().code').toString();
  flatLab.display = fhirpath.evaluate(fhirLab, 'Observation.code.coding.first().display').toString();
  flatLab.quantity = fhirpath.evaluate(fhirLab, 'Observation.valueQuantity.value').toString();
  flatLab.unit = fhirpath.evaluate(fhirLab, 'Observation.valueQuantity.unit').toString();
  flatLab.id = fhirpath.evaluate(fhirLab, 'Observation.id').toString();
  flatLab.author = fhirpath.evaluate(
    fhirLab,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value"
  );
  flatLab.lastUpdated = fhirpath.evaluate(fhirLab, 'Observation.meta.lastUpdated').toString();

  flatLab.effectiveDate = fhirpath.evaluate(fhirLab, 'Observation.effectivePeriod.start').toString();

  flatLab.derivedFrom = fhirpath.evaluate(fhirLab, 'Observation.derivedFrom.first().reference').toString();

  return flatLab;
}

export function parseFHIRreferral(fhirReferral: Observation): any {
  let flatReferral: any = {};

  flatReferral.id = fhirpath.evaluate(fhirReferral, 'id').toString();

  let patient = fhirpath.evaluate(fhirReferral, 'subject.reference').toString();
  flatReferral.patientId = patient.indexOf('/') !== -1 ? patient.split('/')[1] : patient;

  flatReferral.referralType = fhirpath.evaluate(fhirReferral, 'Observation.valueString').toString();

  flatReferral.referralId = fhirpath.evaluate(
    fhirReferral,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/referral-id').value"
  );

  flatReferral.referralStatus = fhirpath.evaluate(
    fhirReferral,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/referral-status').value"
  );

  flatReferral.interventions = fhirpath.evaluate(
    fhirReferral,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/interventions').value"
  );

  flatReferral.lastUpdatedBy = fhirpath.evaluate(
    fhirReferral,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value"
  );

  //referal date
  flatReferral.referralDate = fhirpath.evaluate(fhirReferral, 'Observation.effectivePeriod.start');

  flatReferral.referralAge = moment.utc(flatReferral.referralDate, 'YYYY-MM-DD hh:mm a').local().diff(new Date(), 'days');
  if (flatReferral.referralAge < 0) {
    flatReferral.referralAge = flatReferral.referralAge * -1;
  }

  /* flatReferral.referralDate =
    flatReferral.referralDate &&
    moment(flatReferral.referralDate)
      //.tz(flatReferral.referralDate, 'YYYY-MM-DDTHH:mm:ss', 'America/New_York')
      //.local()
      .format('YYYY-MM-DD hh:mm a'); */
  flatReferral.referralDate =
    flatReferral.referralDate &&
    moment.utc(flatReferral.referralDate, 'YYYY-MM-DDTHH:mm:ss').local().format('YYYY-MM-DD hh:mm a');

  //lastUpdated
  flatReferral.lastUpdated = fhirpath.evaluate(fhirReferral, 'Observation.meta.lastUpdated');

  flatReferral.lastUpdatedAge = moment.utc(flatReferral.lastUpdated, 'YYYY-MM-DDTHH:mm:ss').fromNow();

  flatReferral.lastUpdated =
    flatReferral.lastUpdated &&
    moment.utc(flatReferral.lastUpdated, 'YYYY-MM-DDTHH:mm:ss').local().format('MMMM Do, YYYY HH:mm:ss');

  return flatReferral;
}

export function parseFHIRVital(fhirVital: Observation): any {
  let flatVital: any = {};

  flatVital.id = fhirpath.evaluate(fhirVital, 'Observation.id').toString();
  flatVital.code = fhirpath.evaluate(fhirVital, 'Observation.code.coding.first().code').toString();
  flatVital.display = fhirpath.evaluate(fhirVital, 'Observation.code.coding.first().display').toString();
  flatVital.quantity = fhirpath.evaluate(fhirVital, 'Observation.valueQuantity.value').toString();
  flatVital.unit = fhirpath.evaluate(fhirVital, 'Observation.valueQuantity.unit').toString();
  flatVital.author = fhirpath
    .evaluate(fhirVital, "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value")
    .toString();

  flatVital.effectiveDate = fhirpath.evaluate(fhirVital, 'Observation.effectivePeriod.start').toString();

  flatVital.valueCode = fhirpath.evaluate(fhirVital, 'Observation.valueCodeableConcept.coding.first().code').toString();

  flatVital.valueSystem = fhirpath
    .evaluate(fhirVital, 'Observation.valueCodeableConcept.coding.first().system')
    .toString();

  flatVital.valueString = fhirpath.evaluate(fhirVital, 'Observation.valueString').toString();

  return flatVital;
}

export function parseFHIRPreference(fhirPreference: Observation): any {
  let flatPreference: any = {};

  flatPreference.code = fhirpath.evaluate(fhirPreference, 'Observation.code.coding.first().code').toString();

  flatPreference.id = fhirpath.evaluate(fhirPreference, 'Observation.id').toString();

  flatPreference.valueString = fhirpath.evaluate(fhirPreference, 'Observation.valueString').toString();

  flatPreference.author = fhirpath.evaluate(
    fhirPreference,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value"
  );

  flatPreference.referralStatus = fhirpath
    .evaluate(
      fhirPreference,
      "identifier.where(system='https://projectwell.io/fhir/identifiers/referral-status').value"
    )
    .toString();

  flatPreference.lastUpdated = fhirpath.evaluate(fhirPreference, 'Observation.meta.lastUpdated').toString();

  return flatPreference;
}

export function parseFHIRNotes(fhirNote: DocumentReference): any {
  let flatNote: any = {};

  flatNote.author = fhirpath.evaluate(
    fhirNote,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value"
  );

  flatNote.parentId = fhirpath.evaluate(fhirNote, 'relatesTo.first().target.reference').toString();

  flatNote.categoryCode = fhirpath.evaluate(fhirNote, 'category.first().coding.first().code').toString();

  flatNote.code = fhirpath.evaluate(fhirNote, 'type.coding.first().code').toString();
  flatNote.type = fhirpath.evaluate(fhirNote, 'type.coding.first().display').toString();

  flatNote.date = fhirpath.evaluate(fhirNote, 'meta.lastUpdated').toString();

  flatNote.description = fhirpath.evaluate(fhirNote, 'description').toString();

  flatNote.id = fhirpath.evaluate(fhirNote, 'id').toString();

  flatNote.source = fhirpath
    .evaluate(
      fhirNote,
      "identifier.where(system='https://projectwell.io/fhir/identifiers/questionnaireResponse').value"
    )
    .toString();

  return flatNote;
}

export function parseFHIRProvenance(fhirProvenance: Provenance): any {
  let flatProvenance: any = {};

  flatProvenance.id = fhirpath.evaluate(fhirProvenance, 'id').toString();

  flatProvenance.activityCode = fhirpath.evaluate(fhirProvenance, 'activity.coding.first().display').toString();

  flatProvenance.author = fhirpath.evaluate(fhirProvenance, 'agent.first().who.display').toString();

  flatProvenance.lastUpdated = fhirpath.evaluate(fhirProvenance, 'meta.lastUpdated').toString();

  flatProvenance.reason = fhirpath.evaluate(fhirProvenance, 'reason.first().coding.first().display').toString();

  flatProvenance.target = fhirpath.evaluate(fhirProvenance, 'target.first().reference').toString();

  return flatProvenance;
}

export function parseFHIRlocation(fhirLocation: Location): any {
  let flatLocation: any = {};

  flatLocation.id = fhirpath.evaluate(fhirLocation, 'id').toString();

  flatLocation.vendorName = fhirpath.evaluate(fhirLocation, 'managingOrganization.display').toString();

  flatLocation.county = fhirpath.evaluate(fhirLocation, 'address.district').toString();
  flatLocation.state = fhirpath.evaluate(fhirLocation, 'address.state').toString();
  flatLocation.postalCode = fhirpath.evaluate(fhirLocation, 'address.postalCode').toString();

  return flatLocation;
}

export function parseFHIRPatientName(fhirPatient: Patient): any {
  let flatPatient: any = {};

  flatPatient.firstName = fhirpath.evaluate(fhirPatient, 'name.given.first()');
  flatPatient.middleName = fhirpath.evaluate(fhirPatient, 'name.given.last()');
  flatPatient.lastName = fhirpath.evaluate(fhirPatient, 'name.family');

  return flatPatient.firstName + ' ' + flatPatient.middleName + ' ' + flatPatient.lastName;
}

export function parseFHIRPatientEmail(fhirPatient: Patient): any {
  let flatPatient: any = {};
  flatPatient.email = fhirpath.evaluate(fhirPatient, "telecom.where(system='email').value");

  return flatPatient.email;
}

export function parseFHIRPatientPhone(fhirPatient: Patient): any {
  let flatPatient: any = {};
  flatPatient.phone = fhirpath.evaluate(fhirPatient, "telecom.where(use='home').value");
  if (flatPatient.phone.length === 0 || !flatPatient.phone?.[0]) {
    flatPatient.phone = fhirpath.evaluate(fhirPatient, "telecom.where(use='mobile').value");
  }

  return flatPatient.phone;
}

export function parseAlertsPatients(flag: Flag): any {
  // filtering patient ids
  let subject = fhirpath.evaluate(flag, 'subject.reference');

  let flatFlagRequest: any = {};

  flatFlagRequest.org = fhirpath.evaluate(
    flag,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/organization').value"
  )[0];

  flatFlagRequest.name = fhirpath.evaluate(flag, 'author.display').toString();

  if (subject[0] && subject[0].indexOf('/') > 0) {
    flatFlagRequest.id = subject[0].split('/')[1];
  } else flatFlagRequest.id = '';

  return flatFlagRequest;
}

export function parseFHIRProvenancePatients(fhirProvenance: Provenance): any {
  // filtering patient ids
  let target = fhirpath.evaluate(fhirProvenance, "target.where(reference.substring(0,7)='Patient').reference");

  if (target[0] && target[0].indexOf('/') > 0) {
    return target[0].split('/')[1];
  } else return '';
}

export function parseFHIRServiceRequest(fhirServiceRequest: ServiceRequest): any {
  let flatServiceReuqest: any = {};

  flatServiceReuqest.createdBy = fhirpath
    .evaluate(
      fhirServiceRequest,
      "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value"
    )
    .toString();
  flatServiceReuqest.createdDate = fhirpath.evaluate(fhirServiceRequest, 'meta.lastUpdated').toString();
  flatServiceReuqest.type = fhirpath.evaluate(fhirServiceRequest, 'type.coding.first().display').toString();

  flatServiceReuqest.status = fhirpath.evaluate(fhirServiceRequest, 'status').toString();

  flatServiceReuqest.id = fhirpath.evaluate(fhirServiceRequest, 'id').toString();

  return flatServiceReuqest;
}

export function parseFHIRCarePlan(fhirCarePlan: CarePlan): any {
  let flatCarePlan: any = {};

  flatCarePlan.startDate = fhirpath.evaluate(fhirCarePlan, 'period.start').toString();

  flatCarePlan.lastModifiedBy = fhirpath
    .evaluate(fhirCarePlan, "identifier.where(system='https://projectwell.io/fhir/identifiers/last-modified-by').value")
    .toString();
  flatCarePlan.lastUpdated = fhirpath.evaluate(fhirCarePlan, 'meta.lastUpdated').toString();

  flatCarePlan.status = fhirpath.evaluate(fhirCarePlan, 'status').toString();
  let careTeamRef = fhirpath.evaluate(fhirCarePlan, 'careTeam.first().reference').toString();
  flatCarePlan.careTeamId = careTeamRef.substring(careTeamRef.indexOf('/') + 1);
  flatCarePlan.id = fhirpath.evaluate(fhirCarePlan, 'id').toString();

  return flatCarePlan;
}

export function parseFHIRCareTeam(fhirCareTeam: CareTeam): any {
  let flatCareTeam: any = {};

  flatCareTeam.OA = fhirpath
    .evaluate(fhirCareTeam, "participant.where((role.coding.code = '309396002')).member.display")
    .toString();

  flatCareTeam.OAID = fhirpath
    .evaluate(fhirCareTeam, "participant.where((role.coding.code = '309396002')).member.reference")
    .toString();

  flatCareTeam.OA = flatCareTeam.OA.indexOf('@') !== -1 ? flatCareTeam.OAID.split('@')[0] : flatCareTeam.OA;
  flatCareTeam.OAID = flatCareTeam.OAID.indexOf('/') !== -1 ? flatCareTeam.OAID.split('/')[1] : flatCareTeam.OAID;

  flatCareTeam.RD = fhirpath
    .evaluate(fhirCareTeam, "participant.where((role.coding.code = '159033005')).member.display")
    .toString();

  flatCareTeam.RDID = fhirpath
    .evaluate(fhirCareTeam, "participant.where((role.coding.code = '159033005')).member.reference")
    .toString();

  flatCareTeam.RD = flatCareTeam.RD.indexOf('@') !== -1 ? flatCareTeam.RD.split('@')[0] : flatCareTeam.RD;
  flatCareTeam.RDID = flatCareTeam.RDID.indexOf('/') !== -1 ? flatCareTeam.RDID.split('/')[1] : flatCareTeam.RDID;

  if (
    (flatCareTeam.RDID === undefined ||
      flatCareTeam.RDID === '' ||
      flatCareTeam.RDID === process.env.REACT_APP_DEFAULT_PRACTITIONER_RD_ID) &&
    (flatCareTeam.OAID === undefined ||
      flatCareTeam.OAID === '' ||
      flatCareTeam.OAID === process.env.REACT_APP_DEFAULT_PRACTITIONER_WA_ID)
  ) {
    flatCareTeam.status = 'UN-ASSIGNED';
  } else {
    flatCareTeam.status = 'ASSIGNED';
  }

  let patient = fhirpath.evaluate(fhirCareTeam, 'subject.reference').toString();
  flatCareTeam.patientId = patient.indexOf('/') !== -1 ? patient.split('/')[1] : patient;

  return flatCareTeam;
}

export function parseFHIRFlags(fhirFlag: Flag): any {
  let flatFlag: any = {};

  flatFlag.code = fhirpath.evaluate(fhirFlag, 'code.coding.first().code').toString();

  flatFlag.text = fhirpath.evaluate(fhirFlag, 'code.text').toString();

  flatFlag.id = fhirpath.evaluate(fhirFlag, 'id').toString();

  flatFlag.lastUpdated = fhirpath.evaluate(fhirFlag, 'meta.lastUpdated').toString();

  flatFlag.status = fhirpath.evaluate(fhirFlag, 'status').toString();

  // assigned to is only applicable to Alert flags
  flatFlag.assignedTo = fhirpath.evaluate(
    fhirFlag,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/assigned-to').value"
  )[0];

  flatFlag.author = fhirpath.evaluate(fhirFlag, 'author.display').toString();

  return flatFlag;
}

// For Alerts
export function parseFHIRAlerts(fhirFlag: Flag): any {
  let flatFlag: any = {};

  flatFlag.code = fhirpath.evaluate(fhirFlag, 'code.coding.first().code').toString();

  flatFlag.text = fhirpath.evaluate(fhirFlag, 'code.text').toString();

  flatFlag.id = fhirpath.evaluate(fhirFlag, 'id').toString();

  flatFlag.lastUpdated = fhirpath.evaluate(fhirFlag, 'meta.lastUpdated').toString();

  flatFlag.period = fhirpath.evaluate(fhirFlag, 'period.start').toString();

  flatFlag.status = fhirpath.evaluate(fhirFlag, 'status').toString();

  let patient = fhirpath.evaluate(fhirFlag, 'subject.reference').toString();

  flatFlag.patient = patient.indexOf('/') !== -1 ? patient.split('/')[1] : patient;

  // assigned to is only applicable to Alert flags
  flatFlag.assignedTo = fhirpath.evaluate(
    fhirFlag,
    "identifier.where(system='https://projectwell.io/fhir/identifiers/assigned-to').value"
  )[0];

  flatFlag.author = fhirpath.evaluate(fhirFlag, 'author.display').toString();

  return flatFlag;
}

export function parseCommunication(fhirCommunication: Communication): any {
  let flatCommunication: any = {};

  flatCommunication.sender = fhirpath.evaluate(fhirCommunication, 'sender.display').toString();
  flatCommunication.id = fhirpath.evaluate(fhirCommunication, 'id').toString();
  flatCommunication.sent = fhirCommunication.sent;
  flatCommunication.received = fhirCommunication.received;
  flatCommunication.category = fhirpath.evaluate(fhirCommunication, 'category.first().text').toString();
  flatCommunication.type = fhirpath.evaluate(fhirCommunication, 'identifier.first().value').toString();

  flatCommunication.subject = fhirpath
    .evaluate(fhirCommunication, 'paylaod.first().contentReference.display')
    .toString();
  flatCommunication.contentBody = fhirpath.evaluate(fhirCommunication, 'paylaod.first().contentString').toString();
  flatCommunication.attachmentURL = fhirpath
    .evaluate(fhirCommunication, 'paylaod.first().contentAttachment.url')
    .toString();

  return flatCommunication;
}

export function evalPath(fhirResource: Resource, path: String): any {
  return fhirpath.evaluate(fhirResource, path);
}
