import AuthContext from 'contexts/AuthContext';
//import FHIRDelete from 'data/FHIRDelete';
import moment from 'moment';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Grid, Icon, Label, Loader, Popup } from 'semantic-ui-react';
import { parseFHIRLab } from 'utils/FHIRUtils';
import { HorizontalGridLines, XYPlot, XAxis, YAxis, LineMarkSeries, LabelSeries } from 'react-vis';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { ObservationBuilder } from 'fhir-sdoh';
//import FHIRPoster from 'data/FHIRPoster';

function Labs(props: any) {
  const [loading, setLoading] = React.useState(!props.data);
  //for lab options, whether enter a1c value or blood sugar survey
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [readingDate, setReadingDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [a1cvalue, setA1CValue] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const [graphView, setGraphView] = useState(false);
  const [filterGlucose, setFilterGlucose] = useState(false);

  let authInfo: any = useContext(AuthContext);

  const [highlightID, setHighlightID] = React.useState<any[]>([]);

  useEffect(() => {
    if (props.data[0]) {
      let lastDataInfo = '';
      let indexOfQuestionnaire = '';
      let currentDataInfo = '';
      let lastUpdatedDate = '';
      let tempHighlightId = highlightID;

      for (let i = 0; i < props.data.length; i++) {
        //if the data does have surveyResponse id, we will use the surveyResponse id as lastDataInfo
        if (props?.data[i]?.resource?.derivedFrom?.length > 0) {
          if (i === 0) {
            lastUpdatedDate = props?.data[i]?.resource?.effectivePeriod?.start;
          }
          indexOfQuestionnaire = '';
          currentDataInfo = '';
          indexOfQuestionnaire = props?.data[i]?.resource?.['derivedFrom'].findIndex(
            (obj) => (obj.display = 'Questionnaire')
          );
          currentDataInfo =
            props?.data[i]?.resource?.['derivedFrom']?.[indexOfQuestionnaire]?.['reference']?.split('/')?.[1];
        } else {
          //if the data does not have surveyResponse id, we will use the effectivePeriod as lastDataInfo
          currentDataInfo = props?.data[i]?.resource?.effectivePeriod?.start;
        }
        if (!lastDataInfo) {
          lastDataInfo = currentDataInfo;
        }
        if (
          (currentDataInfo === lastDataInfo &&
            moment(lastUpdatedDate).diff(props?.data[i]?.resource?.effectivePeriod?.start, 'seconds') < 15) ||
          moment(lastDataInfo).diff(currentDataInfo, 'seconds') < 15
        ) {
          if (!highlightID.includes(props?.data[i]?.resource?.id)) {
            tempHighlightId.push(props?.data[i]?.resource?.id);
            setHighlightID(tempHighlightId);
          }
        } else {
          if (highlightID.includes(props?.data[i]?.resource?.id)) {
            tempHighlightId = tempHighlightId.filter((obs) => {
              return obs !== props?.data[i]?.resource?.id;
            });
            setHighlightID(tempHighlightId);
          }
        }
      }
    }
  }, [props?.data]);

  const glucoseOnly = props.data.filter((obs) => {
    return obs.resource.code.coding[0].display.toLowerCase().includes('glucose');
  });

  const LAB_RANGES = {
    Glucose: {
      min: 70,
      max: 99,
      'borderline-high': 125,
      high: 126,
    },
    'Systolic blood pressure': {
      max: 119,
      'Pre-Hypertension': 139,
      Hypertension: 179,
      'Hypertensive Crisis': 180,
    },
    'Diastolic blood pressure': {
      max: 79,
      'Pre-Hypertension': 89,
      Hypertension: 119,
      'Hypertensive Crisis': 120,
    },
    'Hemoglobin A1c/Hemoglobin. total in Blood': {
      max: 5.6,
      'borderline-high': 6.4,
      high: 6.5,
    },
    'CO2 SerPl-sCnc': {
      min: 23,
      max: 29,
    },
    'Cholesterol in HDL': {
      min: 60,
      'borderline-low': 41,
      low: 40,
    },
    'Creatinine SerPl-mCnc': {
      min: 0.6,
      max: 1.3,
    },
    'Potassium SerPl-sCnc': {
      min: 3.5,
      max: 5,
    },
    'Triglyceride SerPl-mCnc': {
      max: 149,
      'borderline-high': 199,
      high: 200,
    },
    'Sodium-Ser/Plas': {
      min: 136,
      max: 146,
    },
    LDL: {
      max: 99,
      'borderline-high': 159,
      high: 160,
    },
    'BUN SerPl-mCnc': {
      min: 6,
      max: 21,
    },
    'Chloride SerPl-sCnc': {
      min: 95,
      max: 105,
    },
    'Albumin SerPl-mCnc': {
      min: 3.5,
      max: 5.5,
    },
    'Calcium SerPl-mCnc': {
      min: 8.5,
      max: 10.5,
    },
    'ALT SerPl-cCnc': {
      min: 7,
      max: 56,
    },
    'Total Cholesterol': {
      max: 199,
      'borderline-high': 239,
      high: 240,
    },
    eGFR: {
      min: 90,
    },
    uACR: {
      max: 30,
    },
  };

  const LAB_ALERT = (labName, labResult) => {
    if (LAB_RANGES[labName]) {
      let single_lab_range = LAB_RANGES[labName];
      if ('min' in single_lab_range && labResult < single_lab_range['min']) {
        if (single_lab_range['borderline-low'] && labResult > single_lab_range['borderline-low']) {
          return 'Borderline-L';
        } else {
          return 'L';
        }
      } else if ('max' in single_lab_range && labResult > single_lab_range['max']) {
        if (single_lab_range['borderline-high'] && labResult < single_lab_range['borderline-high']) {
          return 'Borderline-H';
        } else if (single_lab_range['Pre-Hypertension'] && labResult < single_lab_range['Pre-Hypertension']) {
          return 'Pre-Hypertension';
        } else if (single_lab_range['Hypertension'] && labResult < single_lab_range['Hypertension']) {
          return 'Hypertension';
        } else if (single_lab_range['Hypertensive Crisis'] && labResult < single_lab_range['Hypertensive Crisis']) {
          return 'Hypertensive Crisis';
        } else {
          return 'H';
        }
      }
      return '';
    } else {
      return 'No reference';
    }
  };

  const handleOpen = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
    setReadingDate(null);
    setErrorMessage(null);
    setA1CValue(null);
    setReadingDate(null);
  };

  const observationsList = (observation: any, i: number, counter: number) => {
    let parsedObservation = parseFHIRLab(observation.resource);
    let result = LAB_ALERT(parsedObservation.display, parsedObservation.quantity);
    if (!props.sidebar) {
      return (
        <Popup
          key={i}
          flowing
          trigger={
            <Label
              key={'display' + i}
              style={{ margin: '0.15em' }}
              color={highlightID?.includes(observation?.resource?.id) ? 'teal' : 'grey'}
            >
              <div style={{ display: 'flex' }}>
                <div>
                  <b>
                    {parsedObservation.display}
                    {': '} {parsedObservation.quantity} {parsedObservation.unit}
                    {result ? (
                      <span className="bold" style={{ color: '#B03060' }}>
                        {' '}
                        ({result})
                      </span>
                    ) : (
                      ''
                    )}
                  </b>

                  <div>{moment(parsedObservation.effectiveDate).format('MMMM Do YYYY, h:mm:ss a')}</div>
                </div>

                <div style={{ width: 10 }} />

                {parsedObservation.derivedFrom && (
                  <Popup
                    flowing
                    trigger={
                      <Icon
                        size="large"
                        name={'external square'}
                        onClick={() => {
                          props.setActiveTab('SURVEYS');
                          props.updateQRID(parsedObservation.derivedFrom.substring(22), false);
                        }}
                      />
                    }
                    content={<span>Go to Survey Response</span>}
                    position="bottom left"
                    on="hover"
                  />
                )}
                {/* <Icon style={{ float: 'right' }} size="large" name="delete" onClick={() => handleDelete(i)} /> */}
              </div>
            </Label>
          }
          content={
            <span key={i}>
              <Icon name="clock" />
              Last Updated By: {parsedObservation.author}.{' '}
              {moment.utc(parsedObservation.lastUpdated).local().format('MMMM Do YYYY, h:mm:ss a')}.
            </span>
          }
          on="hover"
        />
      );
    } else {
      return (
        <Label key={'sidebar' + i} basic color={highlightID?.includes(observation?.resource?.id) ? 'teal' : 'grey'}>
          <div style={{ display: 'flex' }}>
            <div>
              <div>
                <b>
                  {parsedObservation.display}
                  {': '} {parsedObservation.quantity} {parsedObservation.unit}
                  {result ? (
                    <span className="bold" style={{ color: '#B03060' }}>
                      {' '}
                      ({result})
                    </span>
                  ) : (
                    ''
                  )}
                </b>
              </div>
              <div>{moment(parsedObservation.effectiveDate).format('MMMM Do YYYY, h:mm:ss a')}</div>
            </div>
          </div>
        </Label>
      );
    }
  };

  /* const handleDelete = (i: number) => {
    setLoading(true);

    FHIRDelete(
      `/Observation/${parseFHIRLab(!filterGlucose ? props.data[i].resource : glucoseOnly[i].resource).id}`,
      authInfo.token
    ).then(() => {
      setLoading(true);
      props.mutate().then(() => {
        setLoading(false);
      });
    });
  }; */

  const graphData = glucoseOnly.map((observation) => {
    let date = new Date(observation.resource.effectivePeriod.start).toLocaleDateString();

    return {
      x: date.substring(0, date.length - 5),
      y: observation.resource.valueQuantity.value,
    };
  });

  /* const handleSubmit = (e) => {
    e.preventDefault();
    setPopupOpen(false);

    if (!a1cvalue || isNaN(Number(a1cvalue))) {
      return false;
    }
    setLoading(true);
    setLoadingMessage('Submitting lab Label...');

    let body: fhir4.Observation = ObservationBuilder.buildLabObservation(
      props.patientId,
      readingDate ? moment(readingDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      '59261-8',
      'Hemoglobin A1c/Hemoglobin. total in Blood',
      Number(a1cvalue),
      '%',
      authInfo.practitionerName
    );

    FHIRPoster(`/Observation/`, authInfo.token, body).then(
      () => {
        setLoadingMessage('Submission Success. Updating Lab UI...');
        props.mutate().then(() => {
          setLoadingMessage('');
          setLoading(false);
          setA1CValue(null);
          setReadingDate(null);
        });
      },
      (error) => {
        setLoadingMessage(`Error - Unable to submit Lab label: ${error.message}`);
        setLoading(false);
        setA1CValue(null);
        setReadingDate(null);
      }
    );
  }; */

  return (
    <>
      {loading && <Loader active inline="centered" content={loadingMessage ?? ''} />}
      {props.data && props.data.length > 0 && (
        <>
          {!props.sidebar && (
            <div style={{ display: 'flex' }}>
              <Button.Group size="mini">
                <Button
                  content={'All'}
                  color={!filterGlucose ? 'blue' : 'grey'}
                  onClick={() => {
                    setGraphView(false);
                    setFilterGlucose(false);
                  }}
                />
                <Button
                  content={'Glucose ONLY'}
                  color={filterGlucose ? 'blue' : 'grey'}
                  onClick={() => setFilterGlucose(true)}
                />
              </Button.Group>

              <div style={{ width: '20px' }} />
              {filterGlucose && (
                <Button.Group size="mini">
                  <Button content={'Labels'} color={!graphView ? 'blue' : 'grey'} onClick={() => setGraphView(false)} />
                  <Button content={'Graph'} color={graphView ? 'blue' : 'grey'} onClick={() => setGraphView(true)} />
                </Button.Group>
              )}
            </div>
          )}

          {graphView && (
            <XYPlot width={900} height={250} xType="ordinal">
              <HorizontalGridLines />
              <LineMarkSeries
                data={graphData.reverse()}
                style={{
                  strokeWidth: '15px',
                  fill: 'none',
                }}
                lineStyle={{ stroke: 'orange' }}
                markStyle={{ stroke: 'teal', fill: 'teal' }}
              />
              <LabelSeries
                key={'glucoselabel'}
                data={graphData}
                getLabel={(d) => `${d.y}`}
                style={{ fill: 'white' }}
                labelAnchorX="middle"
                labelAnchorY="middle"
              />
              <XAxis />
              <YAxis />
            </XYPlot>
          )}
          {!graphView &&
            (!filterGlucose ? props.data : glucoseOnly).map((observation: any, i: number) =>
              observationsList(observation, i, (!filterGlucose ? props.data : glucoseOnly).length)
            )}

          {/* {!props.sidebar && (
            <Popup
              flowing
              trigger={<Button content={'+'} size="mini" compact />}
              content={
                <Form onSubmit={handleSubmit} textAlign="center">
                  <Form.Field>
                    <div
                      className="inline fields ui center aligned"
                      style={{
                        display: 'flex',
                        alignContent: 'stretch',
                        justifyContent: 'center',
                        alignSelf: 'right',
                        width: '110%',
                      }}
                    >
                      <Form.Input
                        placeholder="Enter A1C Value"
                        value={a1cvalue}
                        onChange={(e, fieldprops) => {
                          if (!isNaN(Number(fieldprops.value))) {
                            setA1CValue(fieldprops.value);
                            setErrorMessage(null);
                          } else {
                            setA1CValue(fieldprops.value);
                            setErrorMessage('Please enter a number');
                          }
                        }}
                        className="field fluid"
                        textAlign="center"
                        icon="lab"
                      />
                      {errorMessage && (
                        <Label basic color="red" pointing>
                          {errorMessage}
                        </Label>
                      )}
                      <Form.Input
                        value={readingDate}
                        onChange={(e, fieldprops) => {
                          setReadingDate(fieldprops.value);
                        }}
                        name="readingDate"
                        control={SemanticDatepicker}
                        format="YYYY-MM-DD"
                        placeholder="Enter Reading Date"
                        textAlign="center"
                      />
                    </div>
                    <div className="center aligned">
                      <Button.Group
                        style={{
                          display: 'flex',
                          alignContent: 'stretch',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                        widths="2"
                      >
                        <Form.Button
                          style={{ textalign: 'center' }}
                          color="blue"
                          content="Create A1C Reading"
                          position="center"
                          className={isNaN(Number(a1cvalue)) ? 'disabled' : ''}
                          size="small"
                          textAlign="center"
                          fluid
                        />
                        {(props.org === 'IU' || props.org === 'ZING' || props.org === 'ANTHEM') && (
                          <>
                            <Button.Or />
                            <Form.Button
                              style={{ textalign: 'center' }}
                              fluid
                              textAlign="center"
                              size="small"
                              position="center"
                              color="blue"
                              content="Go to Blood Sugar Survey"
                              onClick={() => {
                                handleClose();
                                props.setActiveTab('SURVEYS');
                                props.updateQRID(
                                  props.org === 'IU' || props.org === 'ZING'
                                    ? `member-glucose-${props.org.toLowerCase()}`
                                    : 'member-glucose-default',
                                  true
                                );
                              }}
                            />
                          </>
                        )}
                      </Button.Group>
                    </div>
                  </Form.Field>
                </Form>
              }
              open={popupOpen}
              onClose={handleClose}
              onOpen={handleOpen}
              on="click"
              position="bottom right"
            />
          )} */}
        </>
      )}
    </>
  );
}

export default Labs;
