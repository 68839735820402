import { Period, Task } from "fhir/r4";
import { v4 as uuid } from "uuid";
import * as FHIRUtils from "../fhirutils";

export function build(
  taskCode: { code: string; display: string },
  identifiers: [{ system: string; value: string }],
  description: string,
  forRef: string, //eg: Patient/patient1
  basedOnRefs: string[], //eg: ServiceRequest/exampel1
  focusRef: string, //eg: Questionnaire/exampleQ
  reason: string,
  note: string,
  partofRef?: string, //parent taksId,
  startDate?: string,
  endDate?: string,
  ownerRef?: { reference: string; display: string },
  updatedBy?: string,
  recipientRef?: Array<{ reference: string; display: string }>,
  referralId?: string,
  org?: string
): Task {
  let taskId = uuid();

  let task: Task = {
    resourceType: "Task",
    id: taskId,
    description: description,
    status: "requested",
    intent: "order",
    reasonCode: {
      text: reason,
    },
  };

  if (taskCode) {
    task.code = {
      coding: [
        {
          system: "https://projectwell.io/fhir/codes/task-codes",
          code: taskCode.code,
          display: taskCode.display,
        },
      ],
    };
  }

  if (identifiers) {
    task.identifier = identifiers;
  }

  if (referralId) {
    task.groupIdentifier = {
      system: "https://projectwell.io/fhir/identifiers/referral-id",
      value: referralId,
    };
  }

  if (basedOnRefs && basedOnRefs.length > 0) {
    task.basedOn = [];
    basedOnRefs.forEach((basedOnRef) => {
      task.basedOn?.push({
        reference: basedOnRef,
      });
    });
  }

  if (focusRef) {
    task.focus = {
      reference: focusRef,
    };
  }

  if (forRef) {
    task.for = {
      reference: forRef,
    };
  }

  if (note) {
    task.note = [
      {
        text: note,
      },
    ];
  }

  if (partofRef) {
    task.partOf = [
      {
        reference: partofRef,
      },
    ];
  }

  if (ownerRef) {
    task.owner = ownerRef;
  }

  if (startDate) {
    let executionPeriod: Period = {
      start: startDate,
    };
    if (endDate) {
      executionPeriod.end = endDate;
    } else {
      executionPeriod.end = startDate;
    }
    task.executionPeriod = executionPeriod;
  }

  if (updatedBy) {
    task = FHIRUtils.addUpdatedBy(task, updatedBy);
  }

  if (recipientRef) {
    task.restriction = {
      recipient: recipientRef,
    };
    if (!task.identifier) {
      task.identifier = [
        {
          system: "https://projectwell.io/fhir/identifiers/vendor-codes",
          value: recipientRef[0].display,
        },
      ];
    } else {
      task.identifier = task.identifier.concat([
        {
          system: "https://projectwell.io/fhir/identifiers/vendor-codes",
          value: recipientRef[0].display,
        },
      ]);
    }
  }
  return task;
}

export function perform() {
  //complete task. (status update)
  //get next task from PlanDef/ActivityDef
  //create new Task for next step. so that the Task reader/Queue reader or UI will display tasks
}
